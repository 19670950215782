import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDatumItemList } from "../../api/formRequest";
import { getItemDetail } from "../../api/ItemRequest";
import { getDatum_One } from "../../api/DatumManipulation";
import DatumView from "./DatumView";
import DatumList from "./DatumListSheet";
import SimpleBar from "simplebar-react";
import { modalDatumView } from "../../actions/ModalAction";
import {
  actionUpdateFormCategory,
  actionUpdateFormMadeBy,
  actionUpdateFormSoldBy,
  actionUpdateFormSubstitute,
} from "../../actions/formAction";
import { submitDatum } from "../../api/DatumSubmitRequest";
import "simplebar-react/dist/simplebar.min.css";
import { RELATION } from "../../const";
import { TbCategory2, TbReplace } from "react-icons/tb";
import { IoHammerOutline } from "react-icons/io5";
import { MdOutlineSell } from "react-icons/md";
import { getDatumRelation } from "../../api/formRequest";
// import { set } from "lodash";
// import { actionUpdateFormProduct } from "../../actions/formAction";

const DatumWill = ({ type, rowInd, colInd }) => {
  // const dispatch = useDispatch();
  const formData = useSelector((state) => state.formReducer.formData);
  const [ref1, setRef1] = useState('')
  const [ref2, setRef2] = useState('')
  const productData = formData[rowInd].product;
  const rowData = formData[rowInd];
  
  const getImagePath = async (id, type_) => {
    // console.log('getImagepath',id);
    if (id !== "-1") {
      try {
        console.log("trying to fetch image path for ", id);
        const data = (await getItemDetail(id)).data
        console.log("Success for ", id);
        if (type_ === 1) {
          setRef1(process.env.REACT_APP_PUBLIC_FOLDER + data.image)
        } else {
          setRef2(process.env.REACT_APP_PUBLIC_FOLDER + data.image)
        }
      } catch (error) {
        console.log("Failure fetching image for ", id);
        console.log(error);
      }
    }else{      
      setRef1(process.env.REACT_APP_PUBLIC_FOLDER + productData.filename.cloudName)
    }
  };

  useEffect(() => {

    if (formData) {

      //set ref1 image
      getImagePath(productData.refId, 1)
      if(productData.refId === '-1'){
        
      }
  
      if (type == RELATION.CATEGORY) {
        console.log("Row Data in Cat: ", rowData);
        let ref = rowData.category.refId
        if (ref.includes("reference;")) {
          ref = "-1"
        }
        getImagePath(ref, 2)
        if (ref && rowData.category.filename.cloudName === "")
        {
          setRef1(process.env.REACT_APP_PUBLIC_FOLDER + productData.filename.cloudName)
          setRef2(process.env.REACT_APP_PUBLIC_FOLDER + rowData.category.filename.original)
        } else if(ref && rowData.category.cloudName !== ''){
          console.log('category:',rowData);
          setRef1(process.env.REACT_APP_PUBLIC_FOLDER + productData.filename.cloudName)
          setRef2(process.env.REACT_APP_PUBLIC_FOLDER + rowData.category.filename.cloudName)
        }else{
          setRef1(process.env.REACT_APP_PUBLIC_FOLDER + productData.filename.cloudName)
        }
      } else if (type == RELATION.MADEBY) {
        let ref = rowData.madeby[colInd].refId
        if (ref.includes("reference;")) {
          ref = "-1"
        }
        getImagePath(ref, 2)
        if (ref && rowData.madeby[colInd].filename.cloudName === "")
          {
            setRef1(process.env.REACT_APP_PUBLIC_FOLDER + productData.filename.cloudName)
            setRef2(process.env.REACT_APP_PUBLIC_FOLDER + rowData.madeby[colInd].filename.original)
          } else if(ref && rowData.madeby[colInd].cloudName !== ''){
          setRef1(process.env.REACT_APP_PUBLIC_FOLDER + productData.filename.cloudName)
          setRef2(process.env.REACT_APP_PUBLIC_FOLDER + rowData.madeby[colInd].filename.cloudName)
        }else{
          setRef1(process.env.REACT_APP_PUBLIC_FOLDER + productData.filename.cloudName)
        }
      } else if (type == RELATION.SOLDBY) {
        let ref = rowData.soldby[colInd].refId
        if (ref.includes("reference;")) {
          ref = "-1"
        }
        getImagePath(ref, 2)
        if (ref && rowData.soldby[colInd].filename.cloudName === "")
        {
          setRef1(process.env.REACT_APP_PUBLIC_FOLDER + productData.filename.cloudName)
          setRef2(process.env.REACT_APP_PUBLIC_FOLDER + rowData.soldby[colInd].filename.original)
        } else if(ref && rowData.soldby[colInd].cloudName !== ""){
          setRef1(process.env.REACT_APP_PUBLIC_FOLDER + productData.filename.cloudName)
          setRef2(process.env.REACT_APP_PUBLIC_FOLDER + rowData.soldby[colInd].filename.cloudName)
        } else {
          setRef1(process.env.REACT_APP_PUBLIC_FOLDER + productData.filename.cloudName)
        }
      } else if (type == RELATION.SUBSTITUTE) {
        let ref = rowData.soldby[colInd].refId
        if (ref.includes("reference;")) {
          ref = "-1"
        }
        getImagePath(ref, 2)
        if (ref && rowData.substitute[colInd].filename.cloudName === "")
          {
            setRef1(process.env.REACT_APP_PUBLIC_FOLDER + productData.filename.cloudName)
            setRef2(process.env.REACT_APP_PUBLIC_FOLDER + rowData.substitute[colInd].filename.original)
          } else if(ref && rowData.substitute[colInd].cloudName !== ''){
          setRef1(process.env.REACT_APP_PUBLIC_FOLDER + productData.filename.cloudName)
          setRef2(process.env.REACT_APP_PUBLIC_FOLDER + rowData.substitute[colInd].filename.cloudName)
        }else{
          setRef1(process.env.REACT_APP_PUBLIC_FOLDER + productData.filename.cloudName)
        }
      }
    }
  }, [formData])

  return (
    <>
      {type === RELATION.CATEGORY && (
        <div style={{ border: "1px solid #3498DB", borderRadius: "5px", padding: "2px" }}>
          <div style={{ textAlign: "center" }}>A datum will be created to show</div>
          <div className="flex" style={{ justifyContent: "center", gap: "1rem", alignItems: "center" }}>
            <img className="w-4 h-4" src={ref1} loading="lazy"/>
            <TbCategory2 />
            <img className="w-4 h-4" src={ref2} loading="lazy"/>
          </div>
        </div>
      )}
      {type === RELATION.MADEBY && (
        <div style={{ border: "1px solid #3498DB", borderRadius: "5px", padding: "2px" }}>
          <div style={{ textAlign: "center" }}>A datum will be created to show</div>
          <div className="flex" style={{ justifyContent: "center", gap: "1rem", alignItems: "center" }}>
            <img className="w-4 h-4" src={ref2} />
            <IoHammerOutline />
            <img className="w-4 h-4" src={ref1} />
          </div>
        </div>
      )}
      {type === RELATION.SOLDBY && (
        <div style={{ border: "1px solid #3498DB", borderRadius: "5px", padding: "2px" }}>
          <div style={{ textAlign: "center" }}>A datum will be created to show</div>
          <div className="flex" style={{ justifyContent: "center", gap: "1rem", alignItems: "center" }}>
            <img className="w-4 h-4" src={ref2} />
            <MdOutlineSell />
            <img className="w-4 h-4" src={ref1} />
          </div>
        </div>
      )}
      {type === RELATION.SUBSTITUTE && (
        <div style={{ border: "1px solid #3498DB", borderRadius: "5px", padding: "2px" }}>
          <div style={{ textAlign: "center" }}>A datum will be created to show</div>
          <div className="flex" style={{ justifyContent: "center", gap: "1rem", alignItems: "center" }}>
            <img className="w-4 h-4" src={ref1} />
            <TbReplace />
            <img className="w-4 h-4" src={ref2} />
          </div>
        </div>
      )}
    </>
  );
};

export default function ShowDatumList({
  userId,
  productId,
  refId,
  type,
  rowInd,
  colInd,
  reference,
  createFlag,
  onShowDetail,
  themes,
  sources,
}) {
  const formData = useSelector((state) => state.formReducer.formData);
  const divContainer = useRef(null);
  const [data, setData] = useState([]);
  const [disp, setDisp] = useState(true);
  const dispatch = useDispatch();
  console.log("Themes: ", themes);
  
  useEffect(() => {
    const getData = async () => {
      try {
        const quant = await getDatum_One(reference.datumId);
        if (quant.data === null)
        {
          setDisp(false);
        } else {
          setDisp(true);
        }
      } catch (error) {
        // console.error("Failed to fetch data:", error);
      }
    };
    getData();
    
  }, [reference]);

  // useEffect(() => {
  //   // if (productId == "" || secondId == "" || datumId == "") return;
  //   const getData = async () => {
  //     const tempD = await getDatumRelation(productId, refId, reference.datumId);
  //     setTemp(tempD.data);
  //   };

  //   // console.log(productId, secondId, datumId);

  //   if(productId != '-1' && refId != "-1")
  //   {
  //     getData();
  //   }
  // }, [productId, refId, reference.datumId]);
  // console.log("Temp: ", temp)

  useEffect(() => {
    if (userId === "" || productId === "" || refId === "" || type === "") {
      setData([]);
      return;
    }
    if (refId.includes("reference;") || refId === "-1" || productId === "-1") {
      setData([]);
      return;
    }
    getDatumItemList(userId, productId, refId, type)
      .then((data) => {
        setData(data.data);
      })
      .catch((err) => {
        setData([]);
        console.log(err);
      });
  }, [productId, refId, type]);

  useEffect(() => {
    if (createFlag === 0) return;
    if (reference.datumId !== "" && reference.datumId !== null) return;
    if (refId === "-1" || productId === "-1") return;
    const createDatum = async () => {
      let relation;
      if (type === RELATION.CATEGORY) {
        relation = {
          ref1: productId,
          relation: "belongs to (category)",
          ref2: refId,
        };
      } else if (type === RELATION.MADEBY) {
        relation = {
          ref1: refId,
          relation: "makes",
          ref2: productId,
        };
      } else if (type === RELATION.SOLDBY) {
        relation = {
          ref1: refId,
          relation: "sells",
          ref2: productId,
        };
      } else if (type === RELATION.SUBSTITUTE) {
        relation = {
          ref1: productId,
          relation: "replaces",
          ref2: refId,
        };
      }
      let createdItem = (
        await submitDatum({
          datumText: "",
          references: [productId, refId],
          relations: [relation],
          ...(sources ? { sources } : {}),
          themes: themes,
          userId: userId,
        })
      ).data;
      if (type === RELATION.CATEGORY) {
        dispatch(
          actionUpdateFormCategory({
            key: rowInd,
            category: {
              ...reference,
              datumId: createdItem._id,
            },
          })
        );
      } else if (type === RELATION.MADEBY) {
        dispatch(
          actionUpdateFormMadeBy({
            rkey: rowInd,
            ckey: colInd,
            madeby: {
              ...reference,
              datumId: createdItem._id,
            },
          })
        );
      } else if (type === RELATION.SOLDBY) {
        dispatch(
          actionUpdateFormSoldBy({
            rkey: rowInd,
            ckey: colInd,
            soldby: {
              ...reference,
              datumId: createdItem._id,
            },
          })
        );
      } else if (type === RELATION.SUBSTITUTE) {
        dispatch(
          actionUpdateFormSubstitute({
            rkey: rowInd,
            ckey: colInd,
            substitute: {
              ...reference,
              datumId: createdItem._id,
            },
          })
        );
      }
    };
    createDatum();
  }, [createFlag]);
  
  useEffect(() => {
    if (divContainer && divContainer.current) {
      divContainer.current.focus();
    }
  }, [data]);
  const onSelectOther = (e) => {
    if (e.relatedTarget === null) {
      setData([]);
    }
  };
  const onSelectDatum = (datum) => {
    if (datum === null) return;
    if (type === RELATION.CATEGORY) {
      dispatch(
        actionUpdateFormCategory({
          key: rowInd,
          category: {
            ...reference,
            datumId: datum._id,
          },
        })
      );
    } else if (type === RELATION.MADEBY) {
      dispatch(
        actionUpdateFormMadeBy({
          rkey: rowInd,
          ckey: colInd,
          madeby: {
            ...reference,
            datumId: datum._id,
          },
        })
      );
    } else if (type === RELATION.SOLDBY) {
      dispatch(
        actionUpdateFormSoldBy({
          rkey: rowInd,
          ckey: colInd,
          soldby: {
            ...reference,
            datumId: datum._id,
          },
        })
      );
    } else if (type === RELATION.SUBSTITUTE) {
      dispatch(
        actionUpdateFormSubstitute({
          rkey: rowInd,
          ckey: colInd,
          substitute: {
            ...reference,
            datumId: datum._id,
          },
        })
      );
    }
    setData([]);
  };
  const GetText = ({ type }) => {
    const rowData = formData[rowInd];
    if (productId === "-1" && formData[rowInd].product.filename.cloudName === "") {
      return "";
    }  
    if (type === RELATION.SUBSTITUTE) {
      const cellData = rowData.substitute[colInd];
      // if (cellData.refString == "" && cellData.refId == "-1") {
      // if (cellData.refId !== "-1" && productId !== "-1") {
      if (cellData.refId === "-1" && cellData.filename.cloudName === "") {
        return "";
      }
      // if (cellData.refString == "" && cellData.filename.cloudName == '') {
      //     return "";
      // } 
      else {
        return <DatumWill type={type} rowInd={rowInd} colInd={colInd} />;
      }
      // }
    } else if (type === RELATION.MADEBY) {
      const cellData = rowData.madeby[colInd];
      if (cellData.refId === "-1" && cellData.filename.cloudName === "") {
        return "";
      }
      // if ( cellData.refString == "" && cellData.refId == "-1") {
      // if (cellData.refId !== "-1" && productId !== "-1") {
      // if (cellData.refString == "" && cellData.filename.cloudName == '') {
      //   return "";
      // } 
      else {
        return <DatumWill type={type} rowInd={rowInd} colInd={colInd} />;
      }
      // }
    } else if (type === RELATION.SOLDBY) {
      const cellData = rowData.soldby[colInd];
      if (cellData.refId === "-1" && cellData.filename.cloudName === "") {
        return "";
      }
      // if (cellData.refString == "" && cellData.refId == "-1") {
      // if (cellData.refId !== "-1" && productId !== "-1") {
      // if (cellData.refString == "" && cellData.filename.cloudName == '') {
      //   return "";
      // } 
      else {
        return <DatumWill type={type} rowInd={rowInd} colInd={colInd} />;
      }
      // }
    } else if (type === RELATION.CATEGORY) {
      const cellData = rowData.category;
      if (cellData.refId === "-1" && cellData.filename.cloudName === "") {
        return "";
      }
      // if (cellData.refString == "" && cellData.refId == "-1") {
      // if (cellData.refString == "" && cellData.filename.cloudName == '') {
      //   return "";
      // } 
      else {
        return <DatumWill type={type} rowInd={rowInd} colInd={colInd} />;
      }
    }

  };
  const onRemove = () => {
    onSelectDatum({ _id: null });
  };
  const onDetail = () => {
    dispatch(modalDatumView(reference.datumId));
    onShowDetail(true);
  };

  return (
    <div className="mt-1">
      {reference.datumId === null || reference.datumId === "" || !disp ? (
        <>
          {data.length > 0 ? (
            <div ref={divContainer} tabIndex={"0"} className="shadowGrayLarge" onBlur={(e) => onSelectOther(e)}>
              <SimpleBar style={{ maxHeight: 120 }}>
                <div className="tool-simpleBar-inside-container">
                  {data.map((el, index) => (
                    <div
                      key={index}
                      onClick={() => onSelectDatum(el)}
                    >
                      <DatumList data={el} id={index} />
                    </div>
                  ))}
                </div>
              </SimpleBar>
            </div>
          ) : (
            <>
              <GetText type={type} />
            </>
          )}
        </>
      ) : (
        <>
          <DatumView
            productId={productId}
            secondId={refId}
            datumId={reference.datumId}
            type={type}
            onRemove={onRemove}
            onDetail={onDetail}
          />
        </>
      )}
    </div>
  );
}
