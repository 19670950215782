import * as DatumSubmitApi from '../api/DatumSubmitRequest'
import * as DatumApi from '../api/DatumManipulation'

export const submitDBupdate = () => async(dispatch) => {
    const result = await DatumApi.dbUpdate('1')
    dispatch({type: "DB_UPDATE", data:result})
    console.log('result', result)

    // try{
    //     dispatch({type: "UPDATE DB SUCCESS"})
    // }catch(error){
    //     dispatch({type: "UPDATE BD FAIL"})
    // }
}

export const submitDatum = (formData) => async(dispatch) => {
    // console.log('formdata:------------------',formData)
    dispatch({type: "DATUM_UPLOAD_START"})
    try {
        const {data, status} = await DatumSubmitApi.submitDatum(formData)

        dispatch({type: "DATUM_UPLOAD_SUCCESS", payload: { data: data, status: status}});
        dispatch({type: "USER_DATUM_INCREMENT", data: data})
    } catch (error) {
        dispatch({type: "DATUM_UPLOAD_FAIL", status: error })
    }
}

export const submitDatum_savedraft = (formData) => async(dispatch) => {
    dispatch({type: "DATUM_UPDATE_START"})
    try {
        const {data, status} = await DatumSubmitApi.submitDatum_savedraft(formData)

        dispatch({type: "DATUM_UPDATE_SUCCESS", payload: { data: data, status: status}});
        dispatch({type: "USER_DATUM_UPDATE", data: data})
    } catch (error) {
        dispatch({type: "DATUM_UPDATE_FAIL", status: error })
    }
}



export const submitDatum_update = (formData) => async(dispatch) => {
    dispatch({type: "DATUM_UPDATE_START"})
    try {
        const {data, status} = await DatumSubmitApi.submitDatum_update(formData)

        dispatch({type: "DATUM_UPDATE_SUCCESS", payload: { data: data, status: status}});
        dispatch({type: "USER_DATUM_UPDATE", data: data})
    } catch (error) {
        dispatch({type: "DATUM_UPDATE_FAIL", status: error })
    }
}

export const submitDatum_delete = (formData) => async(dispatch) => {
    dispatch({type: "DATUM_DELETE_START"})
    try {
        const {data, status} = await DatumSubmitApi.submitDatum_delete(formData)

        dispatch({type: "DATUM_DELETE_SUCCESS", payload: { data: data, status: status}});
        dispatch({type: "USER_DATUM_DELETE", data: data})
    } catch (error) {
        dispatch({type: "DATUM_DELETE_FAIL", status: error })
    }
}

export const clearDatumStatus = () => (dispatch) => {
    dispatch({type: "DATUM_CLEAR_STATUS"});
}

export const stampDatum = (datumId, userId) => async(dispatch) => {
    console.log("Top of block")
    console.log(datumId, userId);
    dispatch({type: "STAMP_DATUM", data: {datumId: datumId, userId: userId}})
    // I think I'll also need to run a dispatch to adjust authData to increment/decrement stamp
    // Not sure if this action belongs here or if I need to put it in AuthAction, just for organization
    console.log("Past dispatch")
    DatumApi.stampDatum(datumId, userId)
    console.log("End of block")
}

export const viewDatum = (datumId, userId) => async(dispatch) => {
    console.log("Inside viewDatum")
    dispatch({type: "VIEW_DATUM", data: {datumId: datumId, userId: userId}})
    // Same as stampDatum above re: AuthAction
    DatumApi.viewDatum(datumId, userId) 
}

export const getDatum = (userId, state) => async(dispatch) => {

    const result = await DatumApi.getDatum(userId, state)
    dispatch({type: "DATUM_VIEW", data:result})
    // console.log('result', result)
}

