import { LiaTimesSolid } from 'react-icons/lia';
import './Relation.css';

import React from 'react'
import { generateRelationIcon } from '../../../../../tools/generateRelationIcon';

const Relation = ( { relation, id, dataARM, dataARelM, setDataARelM, references, btn_flag} ) => {
// console.log('relation ---------------------------------',references[0].itemName,references[1].itemName);

  const imagePath = (i) => {
    // return dataARM.filter((ref) => ref._id === i)[0].image;
    if (dataARM) {
      const array = dataARM.filter((ref) => ref._id === i);
      if (array.length > 0) {
        return array[0].image;
      }
    } else {
      const index = references.findIndex((ref)=> ref !== null && ref._id === i);
      if(references[index]){
        return references[index].image;
      }
      else{
        return '';
      }
    }
  }

  const refName = (r) => {
    if (dataARM) {
      const array = dataARM.filter((ref) => ref._id === r);
      if (array.length > 0) {
        return array[0].itemName;
      }
    } else {
      const index = references.findIndex((ref)=> ref!=null && ref._id === r);
      if(references[index]){
        return references[index].itemName;
      }else{
        return '';
      }
    }
  }

  const deleteRelation = () => {
    btn_flag != 'publish' &&
    setDataARelM(()=> {
      return [...dataARelM.slice(0, id), ...dataARelM.slice(id + 1)]
    })
  }
  
  return (
    <div className="Relation FlexColumn">
        <div className="relation-illustration-container FlexRow">
          <img className='relation-item-image' src={process.env.REACT_APP_PUBLIC_FOLDER + imagePath(relation.ref1)} />
          {generateRelationIcon(relation.relation)}
          <img className='relation-item-image' src={process.env.REACT_APP_PUBLIC_FOLDER + imagePath(relation.ref2)} />
          {dataARelM ? <LiaTimesSolid className='relation-x' onClick={deleteRelation}/> : ""}
        </div>
        <div className="relation-text-container FlexRow">
          {refName(relation.ref1)}&nbsp; 
          {relation.relation}&nbsp;
          {refName(relation.ref2)}.
        </div>
    </div>
  )
}

export default Relation