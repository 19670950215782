import React, { useState, useEffect } from 'react';
import { FiChevronUp } from 'react-icons/fi';
import './BackToTopButton.css';

const BackToTopButton = () => {
    // Function to scroll to the top
    const handleScrollToTop = () => {
      scrollToAnchor('top');  // Scroll to the element with id 'top'
    };

  const scrollToAnchor = (location) => {
    const anchor = document.querySelector('#' + location);
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="back-to-top" onClick={handleScrollToTop}>
        <FiChevronUp size={30} />
    </div>
      )
};

export default BackToTopButton;
