import { Input, Tooltip } from "antd";
import { useEffect, useState } from "react";

export function isValidateURL(url) {
  const pattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?(\?[^\s#]*)?(#[^\s]*)?$/;
    return pattern.test(url);
}

export default function HyperLinkInput({ className, placeholder, value, onChange, tooltip, validation }) {
  const [val, setVal] = useState(value)
  const [isError, setIsError] = useState()
  useEffect(() => {
    setVal(value)
  }, [value])
  useEffect(() => {
    if ( val == '') {
      setIsError(null)
      return
    }
    if ( !isValidateURL(val) ) {
      setIsError(true)
      return
    } else {
      setIsError(null)
      return
    }
  }, [validation])
  const onInputChange = (e) => {
    const inputValue = e.target.value;
    setVal(inputValue); // Update local state
    onChange(e); // Pass the full event object to the parent
  };
  return (
    <Tooltip title={tooltip} trigger={"focus"}>
      <Input
        className={`${className}`}
        value={val}
        placeholder={placeholder}
        status={isError ? "error": null}
        onChange={onInputChange} />
    </Tooltip>
  )
}