import * as formApi from '../api/formRequest'

export const removeFormRow = (rkey) => (dispatch) => {
  dispatch({ type: "ACTION_FORM_REMOVE_ROW", data: { key: rkey } })
}

export const removeFormMadeby = (data) => (dispatch) => {
  dispatch({ type: "ACTION_REMOVE_FORM_MADEBY", data: data })
}
export const removeFormSoldby = (data) => (dispatch) => {
  dispatch({ type: "ACTION_REMOVE_FORM_SOLDBY", data: data })
}
export const removeFormSubstitute = (data) => (dispatch) => {
  dispatch({ type: "ACTION_REMOVE_FORM_SUBSTITUTE", data: data })
}
export const removeFormTheme = (data) => (dispatch) => {
  dispatch({ type: "ACTION_REMOVE_FORM_THEME", data: data })
}
export const initPreCreation = () => async (dispatch) => {
  dispatch({ type: "FORM_PRE_CREATION_INIT" })
}

export const addProccessCount = () => async (dispatch) => {
  dispatch({ type: "FORM_PROCCESSED_COUNT_ADD" })
}

export const addFixedCount = () => async (dispatch) => {
  dispatch({ type: "FORM_NEED_FIX_COUNT_ADD" })
}

export const newForm = () => async (dispatch) => {
  dispatch({ type: "FORM_NEW" })
}

export const newFormLow = () => async (dispatch) => {
  dispatch({ type: "FORM_NEW_ROW" })
}

export const onChangeFormName = (val) => async (dispatch) => {
  dispatch({ type: "ON_CHANGE_FORM_NAME", data: val })
}

export const actionUpdateFormProduct = (data) => async (dispatch) => {
  dispatch({ type: "ACTION_UPDATE_FORM_PRODUCT", data: data })
}

export const actionUpdateFormCategory = (data) => async (dispatch) => {
  dispatch({ type: "ACTION_UPDATE_FORM_CATEGORY", data: data })
}

export const actionUpdateFormMadeBy = (data) => async (dispatch) => {
  dispatch({ type: "ACTION_UPDATE_FORM_MADEBY", data: data })
}

export const actionUpdateFormSoldBy = (data) => async (dispatch) => {
  dispatch({ type: "ACTION_UPDATE_FORM_SOLDBY", data: data })
}

export const actionUpdateFormSubstitute = (data) => async (dispatch) => {
  dispatch({ type: "ACTION_UPDATE_FORM_SUBSTITUTE", data: data })
}

export const onChangeFormProduct = (data) => async (dispatch) => {
  dispatch({ type: "FORM_PRODUCT_UPDATED", data: data })
}

export const onChangeFormCategory = (data) => async (dispatch) => {
  dispatch({ type: "FORM_CATEGORY_UPDATED", data: data })
}

export const onChangeFormMadeBy = (data) => async (dispatch) => {
  dispatch({ type: "FORM_MADEBY_UPDATED", data: data })
}

export const onChangeFormSubstitute = (data) => async (dispatch) => {
  dispatch({ type: "FORM_SUBSTITUTE_UPDATED", data: data })
}

export const onChangeFormSoldBy = (data) => async (dispatch) => {
  dispatch({ type: "FORM_SOLDBAY_UPDATED", data: data })
}

export const onAddFormMadeBy = (data) => async (dispatch) => {
  dispatch({ type: "FORM_MADEBY_ADD", data: data })
}

export const onAddFormSoldBy = (data) => async (dispatch) => {
  dispatch({ type: "FORM_SOLDBY_ADD", data: data })
}

export const onAddFormSubstitute = (data) => async (dispatch) => {
  dispatch({ type: "FORM_SUBSTITUTE_ADD", data: data })
}

export const onAddFormTheme = (data) => async (dispatch) => {
  dispatch({ type: "FORM_THEME_ADD", data: data })
}

export const onSaveForm = (data, formName, formId, userId) => async (dispatch) => {
  try {
    const formData = (await formApi.saveForm(data, formName, formId, userId)).data
    // if (formData) {
      dispatch({
        type: "FORM_SAVE", data: {
          msg: `${formName} Saved Successfully`,
          formData
        }
      })
      dispatch({ type: "ON_SHEET_ADD", data: formData })
    // }

  } catch (error) {
    dispatch({
      type: "FORM_SAVE", data: {
        msg: 'Save Form Failed'
      }
    })
  }
}


export const onGetSheetInformation = (sheetId) => async (dispatch) => {
  try {
    const { data } = await formApi.getSheetInformation(sheetId)
    dispatch({ type: "FORM_SHEET_LOAD", data })
  } catch (err) {
    console.log(err)
    dispatch({
      type: "ERROR_MSG", data: {
        msg: "Getting Sheet Information Error"
      }
    })
  }
}
export const messageInit = () => async (dispatch) => {
  dispatch({ type: "MSG_INIT" })
}
export const setValidationStatus = (vStatus) => async (dispatch) => {
  dispatch({
    type: "FORM_VALIDATE_STATUS_CHANGE",
    data: {
      vStatus: vStatus
    }
  })
}

export const deleteForm = (userId, formId) => async (dispatch) => {
  try {
    const { result } = await formApi.deleteFrom(userId, formId)
    dispatch({ type: "FORM_NEW" })
    dispatch({
      type: "ON_REMOVE_FORM_SHEET", data: {
        formId: formId
      }
    })
    dispatch({
      type: "ERROR_MSG", data: {
        msg: "Successfully Removed"
      }
    })
  } catch (err) {
    console.log(err)
    dispatch({
      type: "ERROR_MSG", data: {
        msg: "Error from Server"
      }
    })
  }
}