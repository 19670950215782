import axios  from "axios";

const API = axios.create({baseURL: process.env.REACT_APP_API_BASE_URL});

// export const  fetch_datumfeedback = (datumID, userID) => API.get(`/datum/fetchfeedback/${datumID}/${userID}`,{datumID:datumID, userID:userID})

export const fetch_datumfeedback = async (datumID, userID) => {
    const response = await API.get(`/datum/fetchfeedback/${datumID}/${userID}`);
    return response.data; // Ensure this returns the correct structure
};

export const  save_datumfeedback = (feedbacktype, datumID, userID) => API.put(`/datum/savefeedback/${feedbacktype}/${datumID}/${userID}`,{feedbacktype:feedbacktype,datumID:datumID, userID:userID})