import { useDispatch, useSelector } from 'react-redux';
import './CategoryBrowse.css';
import React, { useEffect, useState } from 'react';
import Reference from '../CreateDatum/AddReferenceModal/Reference/Reference';
import { getCategoryFeed } from '../../../actions/ItemAction';
import Category from './Category/Category';
import { TbCategory2 } from 'react-icons/tb';

const CategoryBrowse = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.authReducer.authData);
    const { loading: authDataLoading } = useSelector((state) => state.authReducer);
    const { categoryFeed, navPath } = useSelector((state) => state.categoryFeedReducer);

    const filtersObj = {
        sort: null,
    };
    const [filters, setFilters] = useState(filtersObj);

    useEffect(() => {
        if (!authDataLoading) {
            dispatch(getCategoryFeed(user._id, null, null, null));
        }
    }, [authDataLoading]);

    const handleChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const handleChecked = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.checked });
    };

    const handleSubmitFilters = (e) => {
        e.preventDefault();
        // Logic to handle filters submission
    };

    const handleNavClick = (itemId, index) => {
        dispatch(getCategoryFeed(user._id, itemId, index, JSON.stringify(filters)));
    };

    // Determine if it's the main category view
    const isMainCategory = !navPath || navPath.length === 0;

    return (
        <>
            <div className="General-filter-bar General-hidden" id="itemFeed_filter_bar">
                <form action="" className="FlexColumn General-filter-form" onSubmit={handleSubmitFilters}>
                    <span className="FlexRow General-filter-form">
                        <span className="General-filter-key">Username:</span>
                        <input
                            type="text"
                            placeholder="Filter by user name..."
                            name="username"
                            className="General-input"
                            onChange={handleChange}
                        />
                    </span>
                    <span className="FlexRow General-filter-form">
                        <span className="General-filter-key">Keyword:</span>
                        <input
                            type="text"
                            placeholder="Filter by keyword..."
                            name="keyword"
                            className="General-input"
                            onChange={handleChange}
                        />
                    </span>
                    {user.userType !== 0 ? (
                        <div className="FlexColumn General-filter-form">
                            <span className="FlexRow General-filter-form">
                                <span className="General-filter-key">Include Subscribed:</span>
                                <input id="subscribed" name="subscribed" type="checkbox" onClick={handleChecked} />
                            </span>
                        </div>
                    ) : (
                        <div className="FlexColumn General-filter-form">
                            <span className="FlexRow General-filter-form">
                                <span className="General-filter-key">Include Followed:</span>
                                <input id="followed" name="followed" type="checkbox" onClick={handleChecked} />
                            </span>
                        </div>
                    )}
                    <button className="button signupButton" type="submit">
                        Apply Filters
                    </button>
                </form>
            </div>

            <div className="catNavPath FlexRow">
                <div className="cb-nav FlexRow" onClick={() => handleNavClick(null, null)}>
                    <TbCategory2 />
                </div>
                {navPath &&
                    navPath.map((n, id) => (
                        <div className="cb-nav FlexRow" onClick={() => handleNavClick(n.itemId, id)} key={id}>
                            {n.itemName}
                        </div>
                    ))}
            </div>

            <div className="ItemFeed">
                {categoryFeed && categoryFeed.length > 0
                    ? categoryFeed.map((item, _id) => {
                          // Show only categories in the main section
                          if (isMainCategory) {
                              return item.itemType === 4 && <Category category={item} key={_id} />;
                          }

                          // Show both categories and references in subcategories
                          return item.itemType === 4 ? (
                              <Category category={item} key={_id} />
                          ) : (
                              <Reference reference={item} key={_id} location="itemList" />
                          );
                      })
                    : 'There are no Items in your Category Browse.'}
            </div>
        </>
    );
};

export default CategoryBrowse;
