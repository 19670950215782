const categoryFeedReducer = (
    state = {categoryFeed: [], navPath: null, loading: false, error: false}, 
    action
) => {
    switch(action.type) {
        case "ITEM_FEED_START":
            return {...state, loading: true, error: false}
        case "ITEM_FEED_SUCCESS":
            // console.log(action.data);
            if (action.data.nav === null) {
                return {...state, categoryFeed: action.data.itemFeed, navPath: [], loading: false, error: false}
            } else if (typeof action.data.nav === "object") {
                return {...state, categoryFeed: action.data.itemFeed, navPath: [...state.navPath, action.data.nav], loading: false, error: false}
            } else if (typeof action.data.nav === "number") {
                return {...state, categoryFeed: action.data.itemFeed, navPath: [...state.navPath.slice(0,action.data.nav + 1)], loading: false, error: false}
            }
            /* falls through */
        case "ITEM_FEED_FAIL":
            return {...state, loading: false, error: true}
        
            
        case "LOG_OUT":
            return {categoryFeed: [], navPath: null, loading: false, error: false}

        default: 
            return state
    }
}

export default categoryFeedReducer