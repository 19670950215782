/**
 * formData element Object type
 *  product: { product: string, filename: string }
 *  category: { category: string, filename: string }
 *  madeby: [{ business: string, filename: string}]
 *  soldby: [{business: string, filename: string, sourcelink: string, sourcetext: string, sourcedes: string}]
 *  substitute: [{product: string, filename: string}]
 */
const NEW_FORM = {
  isNew: true,
  product: {
    refId: '-1',
    filename: {
      original: '',
      cloudName: '',
      _id: ''
    },
    refString: '',
    refItems: []
  },
  category: {
    refId: '-1',
    filename: {
      original: '',
      cloudName: '',
      _id: ''
    },
    refString: '',
    refItems: [],
    datumId: ''
  },
  madeby: [{
    refId: '-1',
    filename: {
      original: '',
      cloudName: '',
      _id: ''
    },
    refString: '',
    refItems: [],
    datumId: ''
  }],
  soldby: [{
    refId: '-1',
    filename: {
      original: '',
      cloudName: '',
      _id: ''
    },
    refString: '',
    refItems: [],
    sourceLink: '',
    sourceText: '',
    sourceDes: '',
    datumId: ''
  }],
  substitute: [{
    refId: '-1',
    filename: {
      original: '',
      cloudName: '',
      _id: ''
    },
    refString: '',
    refItems: [],
    datumId: ''
  }]
}
const itemReducer = (
  state = {
    formData: [],
    formName: '',
    formId: '-1',
    validateStauts: false,
    needFixCount: 0,
    processedCount: 0,
    saveFlag: false,
    createFlag: false,
    msg: ''
  },
  action
) => {
  switch (action.type) {
    case "ACTION_FORM_REMOVE_ROW":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: [ ...state.formData.filter((e, index) => index !=action.data.key)]
      }
    case "ACTION_UPDATE_FORM_PRODUCT":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: [ ...state.formData.map((e, index) => {
          if ( index != action.data.key ) return e
          else {
            return {
              ...e,
              product: action.data.product
            }
          }
        })]
      }
    case "ACTION_UPDATE_FORM_CATEGORY":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: [ ...state.formData.map((e, index) => {
          if ( index != action.data.key ) return e
          else {
            return {
              ...e,
              category: action.data.category
            }
          }
        })]
      }
    case "ACTION_UPDATE_FORM_MADEBY":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: [ ...state.formData.map((e, index) => {
          if ( index != action.data.rkey ) return e
          else {
            return {
              ...e,
              madeby: [...e.madeby.map((el, cIndex) => {
                if ( cIndex != action.data.ckey ) return el
                else {
                  return action.data.madeby
                }
              })]
            }
          }
        })]
      }
    case "ACTION_REMOVE_FORM_MADEBY":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: [ ...state.formData.map((e, index) => {
          if ( index != action.data.rkey ) return e
          else {
            return {
              ...e,
              madeby: [...e.madeby.filter((el, cIndex) => action.data.ckey != cIndex )]
            }
          }
        })]
      }
    case "ACTION_UPDATE_FORM_SOLDBY":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: [ ...state.formData.map((e, index) => {
          if ( index != action.data.rkey ) return e
          else {
            return {
              ...e,
              soldby: [...e.soldby.map((el, cIndex) => {
                if ( cIndex != action.data.ckey ) return el
                else {
                  return action.data.soldby
                }
              })]
            }
          }
        })]
      }
    case "ACTION_REMOVE_FORM_SOLDBY":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: [ ...state.formData.map((e, index) => {
          if ( index != action.data.rkey ) return e
          else {
            return {
              ...e,
              soldby: [...e.soldby.filter((el, cIndex) => action.data.ckey != cIndex )]
            }
          }
        })]
      }
    case "ACTION_UPDATE_FORM_SUBSTITUTE":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: [ ...state.formData.map((e, index) => {
          if ( index != action.data.rkey ) return e
          else {
            return {
              ...e,
              substitute: [...e.substitute.map((el, cIndex) => {
                if ( cIndex != action.data.ckey ) return el
                else {
                  return action.data.substitute
                }
              })]
            }
          }
        })]
      }
    case "ACTION_REMOVE_FORM_SUBSTITUTE":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: [ ...state.formData.map((e, index) => {
          if ( index != action.data.rkey ) return e
          else {
            return {
              ...e,
              substitute: [...e.substitute.filter((el, cIndex) => cIndex != action.data.ckey)]
            }
          }
        })]
      }
      case "FORM_THEME_ADD":
        return {
          ...state,
          saveFlag: true,
          createFlag: true,
        };
      case "ACTION_REMOVE_FORM_THEME":
        return {
          ...state,
          saveFlag: true,
          createFlag: true,
        };
    case "FORM_PRE_CREATION_INIT":
      return {
        ...state,
        processedCount: 0,
        needFixCount: 0
      }
    case "FORM_PROCCESSED_COUNT_ADD":
      return {
        ...state,
        processedCount: state.processedCount + 1
      }
    case "FORM_NEED_FIX_COUNT_ADD":
      return {
        ...state,
        needFixCount: state.needFixCount + 1
      }
    case "FORM_VALIDATE_STATUS_CHANGE":
      return {
        ...state,
        validateStatus: action.data.vStatus
      }
    case "FORM_SHEET_LOAD":
      return {
        ...state,
        saveFlag: false,
        createFlag: true,
        formData: action.data.rowData,
        formName: action.data.sheetName,
        formId: action.data._id
      }
    case "ERROR_MSG":
      return {
        ...state,
        msg: action.data.msg
      }
    case "MSG_INIT":
      return {
        ...state,
        msg: ''
      }
    case "FORM_SAVE":
      return {
        ...state,
        formData: action.data.formData.rowData,
        formId: action.data.formData._id,
        formName: action.data.formData.sheetName,
        msg: action.data.msg,
        saveFlag: false,
      }
    case "FORM_SOLDBAY_UPDATED":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: state.formData.map((el, index) => {
          if (index == action.data.key) {
            return {
              ...el,
              soldby: el.soldby.map((mele, subi) => {
                if (action.data.index == subi) {
                  return {
                    refId: action.data.field == 'refId' ? action.data.str : mele.refId,
                    business: action.data.field == 'business' ? action.data.str : mele.business,
                    filename: action.data.field == 'filename' ? action.data.str : mele.filename,
                    sourceLink: action.data.field == 'sourceLink' ? action.data.str : mele.sourceLink,
                    sourceText: action.data.field == 'sourceText' ? action.data.str : mele.sourceText,
                    sourceDes: action.data.field == 'sourceDes' ? action.data.str : mele.sourceDes,
                    refString: action.data.field == 'refString' ? action.data.str : mele.refString,
                    refItems: action.data.field == 'refItems' ? action.data.str : mele.refItems
                  }
                } else return mele
              })
            }
          } else return el
        })
      }
    case "FORM_SOLDBY_ADD":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: state.formData.map((el, index) => {
          if (index == action.data.key) {
            return {
              ...el,
              soldby: [...el.soldby, {
                ...NEW_FORM.soldby[0]
              }]
            }
          } else return el
        })
      }
    case "FORM_SUBSTITUTE_UPDATED":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: state.formData.map((el, index) => {
          if (index == action.data.key) {
            return {
              ...el,
              substitute: el.substitute.map((mele, subi) => {
                if (action.data.index == subi) {
                  return {
                    ...mele,
                    refId: action.data.field == 'refId' ? action.data.str : mele.refId,
                    product: action.data.field == 'product' ? action.data.str : mele.product,
                    filename: action.data.field == 'filename' ? action.data.str : mele.filename,
                    refString: action.data.field == 'refString' ? action.data.str : mele.refString,
                    refItems: action.data.field == 'refItems' ? action.data.str : mele.refItems
                  }
                } else return mele
              })
            }
          } else return el
        })
      }
    case "FORM_SUBSTITUTE_ADD":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: state.formData.map((el, index) => {
          if (index == action.data.key) {
            return {
              ...el,
              substitute: [...el.substitute, {
                ...NEW_FORM.substitute[0]
              }]
            }
          } else return el
        })
      }
    case "FORM_MADEBY_ADD":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: state.formData.map((el, index) => {
          if (index == action.data.key) {
            return {
              ...el,
              madeby: [...el.madeby, {
                ...NEW_FORM.madeby[0]
              }]
            }
          } else return el
        })
      }
    case "FORM_MADEBY_UPDATED":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: state.formData.map((el, index) => {
          if (index == action.data.key) {
            return {
              ...el,
              madeby: el.madeby.map((mele, subi) => {
                if (subi == action.data.index) {
                  return {
                    refId: action.data.field == 'refId' ? action.data.str : mele.refId,
                    business: action.data.field == 'business' ? action.data.str : mele.business,
                    filename: action.data.field == 'filename' ? action.data.str : mele.filename,
                    refString: action.data.field == 'refString' ? action.data.str : mele.refString,
                    refItems: action.data.field == 'refItems' ? action.data.str : mele.refItems
                  }
                } else return mele
              })
            }
          } else return el
        })
      }
    case "FORM_CATEGORY_UPDATED":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: state.formData.map((el, index) => {
          if (index == action.data.key) {
            return {
              ...el,
              category: {
                refId: action.data.field == 'refId' ? action.data.str : el.category.refId,
                category: action.data.field == 'category' ? action.data.str : el.category.category,
                filename: action.data.field == 'filename' ? action.data.str : el.category.filename,
                refString: action.data.field == 'refString' ? action.data.str : el.category.refString,
                refItems: action.data.field == 'refItems' ? action.data.str : el.category.refItems
              }
            }
          } else return el
        })
      }
    case "FORM_PRODUCT_UPDATED":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: state.formData.map((el, index) => {
          if (index == action.data.key) {
            return {
              ...el,
              product: {
                refId: action.data.field == 'refId' ? action.data.str : el.product.refId,
                product: action.data.field == 'product' ? action.data.str : el.product.product,
                filename: action.data.field == 'filename' ? action.data.str : el.product.filename,
                refString: action.data.field == 'refString' ? action.data.str : el.product.refString,
                refItems: action.data.field == 'refItems' ? action.data.str : el.product.refItems
              }
            }
          } else return el
        })
      }
    case "FORM_NEW":
      return { ...state, formData: [], formName: '', formId: '-1' }
    case "FORM_NEW_ROW":
      return {
        ...state,
        saveFlag: true,
        createFlag: true,
        formData: [
          ...state.formData, Object.assign(
            {},
            {
              ...NEW_FORM,
              key: state.formData.length
            }
          )
        ]
      }
    case "ON_CHANGE_FORM_NAME":
      return { ...state, formName: action.data, saveFlag: true, createFlag: true }
    // I don't know what these do either - might not be necessary
    case "LOG_OUT":
      return {
        formData: [],
        formName: '',
        formId: '-1',
        validateStauts: false,
        msg: '',
        saveFlag: false,
        createFlag: false
      }
    default:
      return state
  }

};

export default itemReducer;