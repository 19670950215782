import React, { useState, useEffect } from 'react'
import CreateDatum1 from './CreateDatum/CreateDatum1'
import { CreateItem } from './CreateItem/CreateItem'
import './Main.css'
import UserFeed from './UserFeed/UserFeed'
import DataFeed from './Feed/DataFeed'
import MainBar from './MainBar/MainBar'
import { useSelector } from 'react-redux'
import CategoryBrowse from './CategoryBrowse/CategoryBrowse'
import ItemFeed from './ItemFeed/ItemFeed'
import Datumdraft from './Feed/DatumState/Datumdraft'
import BackToTopButton from './Button/BackToTopButton'
// import DraftModal from '../../components/Main/Feed/Datum/DraftModal'

const Main = () => {

  const [mainContent, setMainContent] = useState({ current: "itemFeed", previous: "itemFeed" });
  const { user } = useSelector((state) => state.authReducer.authData);
  // const { modalData } = useSelector((state) => state.modalReducer);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const isOpen = useSelector((state) => state.datumeditReducer.isOpen)

  var userFeedType;
  var userFeedText;
  switch (user.userType) {
    case 0:
      userFeedType = 2;
      userFeedText = "Group";
      break;
    case 1:
      userFeedType = 2;
      userFeedText = "Group";
      break;
    case 2:
      userFeedType = 1;
      userFeedText = "Author";
      break;
    default:
      break;
  }

  useEffect(() => {

    if (isOpen === true) {
      setIsModalOpen(true)
    }
    else {
      setIsModalOpen(false)
    }
  }, [isOpen])

  const renderContentComponent = () => {
    switch (mainContent.current) {
      case "itemFeed":
        return <ItemFeed />;
      case "dataFeed":
        return <DataFeed
          setMainContent={setMainContent} />;
      case "categoryBrowse":
        return <CategoryBrowse />;
      case "userFeed":
        return <UserFeed />;
      case "c_item":
        return (
          <CreateItem
            setMainContent={setMainContent}
          />
        )
      case "c_datum":
        return (
          <CreateDatum1
            setMainContent={setMainContent}
            ceFlag='1'
          />
        );
      case "c_datumview":
        return (
          <Datumdraft setMainContent={setMainContent} datumstate='draft' btn_flag='draft' />
        );
      default:
        return <ItemFeed />;
    }
  }

  // I believe this was the original modal, which was scrapped
  // const datumModal = () =>{
  //   if(isModalOpen)
  //     return <DraftModal setMainContent={setMainContent} onClose={toggleModal} datum_i={modalData.data} ceFlag='2' btn_flag='public' datum_state='pubdatum' />;
  // }

  return (
    <div className='Main'>
      <MainBar
        mainContent={mainContent}
        setMainContent={setMainContent}
        userFeedType={userFeedType}
        userType={user.userType}
        userFeedText={userFeedText}
      />
      {renderContentComponent()}
      {/* {isModalOpen && <DraftModal setMainContent={setMainContent} onClose={toggleModal} datum_i={modalData.data} ceFlag='2' btn_flag='public' datum_state='pubdatum' />} */}
    </div>
  )
}

export default Main