const feedbackReducer = (
    state = {
        count: {
            error: 0,
            relation: 0,
            reference: 0,
            incorrect: 0,
            trigger: 0,
            source: 0,
            inappropriate: 0
        },
        flag: {
            error: false,
            relation: false,
            reference: false,
            incorrect: false,
            trigger: false,
            source: false,
            inappropriate: false
        }
    },
    action
) => {
    switch (action.type) {
        case "FETCH_COUNT":
            // console.log('Current state:', state);
            // console.log('Action count:', action.count);
            // console.log('Action result:', action.result);
            return {
                ...state, // Spread the existing state
                count: {
                    ...state.count, // Spread the existing count state
                    error: action.count.error, // Update the error count
                    relation: action.count.relation, // Update the relation count
                    reference: action.count.reference, // Update the reference count
                    incorrect: action.count.incorrect, // Update the incorrect count
                    trigger: action.count.trigger, // Update the trigger count
                    source: action.count.source, // Update the source count
                    inappropriate: action.count.inappropriate // Update the inappropriate count
                },
                flag:{
                    ...state.flag,
                    error: action.result.error, // Update the error count
                    relation: action.result.relation, // Update the relation count
                    reference: action.result.reference, // Update the reference count
                    incorrect: action.result.incorrect, // Update the incorrect count
                    trigger: action.result.trigger, // Update the trigger count
                    source: action.result.source, // Update the source count
                    inappropriate: action.result.inappropriate // Update the inappropriate count
                }
            };
        case "SAVE_FAIL":
            // Handle save failure (optional)
            return {
                ...state,
                // You can update flags or handle errors here if needed
            };
        default:
            return state;
    }
};
export default feedbackReducer;