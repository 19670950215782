import React from 'react'
import { useState, useEffect } from 'react';
import './UserView.css'
import { generateUserTypeIcon } from '../../tools/userTypeConversion';
import { VscTypeHierarchy } from 'react-icons/vsc'
import { AiOutlineBarChart, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { GiStamper } from 'react-icons/gi'
import { BsBookmarkCheck, BsEye, BsInfo } from 'react-icons/bs'
import { RiUserFollowFill, RiUserFollowLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux';
import { TbCompass } from 'react-icons/tb'
import { followUser, subscribeUser } from '../../actions/UserAction';
import { Tabs } from '@mantine/core';
import { getUser } from '../../api/UserRequest';
import { getDatum_One  } from '../../api/DatumManipulation';
import { getItemDetail } from '../../api/ItemRequest';
import Value from '../ProfileLeft/Compass/Value/Value';
import UserStats from './UserStats';
import { getDataFeed } from '../../actions/DataFeedAction';
import DataFeed from '../Main/Feed/DataFeed';
import ItemFeed from '../Main/ItemFeed/ItemFeed';
import DatumList from '../Main/Feed/Datum/DatumList';
import UserList from './UserList';

const UserView = ({user}) => {

  const dispatch = useDispatch();

  const serverPublic = process.env.REACT_APP_PUBLIC_FOLDER;
  const {user: sessionUser, loading: authDataLoading} = useSelector((state) => state.authReducer.authData);
  const [mainContent, setMainContent] = useState(null);
  const [sortOrder, setSortOrder] = useState('stamped');
  const [sortOrder1, setSortOrder1] = useState('mostReferenced');
  const [followUsers, setFollowUsers] = useState([]);
  const [subscribeUsers, setSubscribeUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Sloading, setSLoading] = useState(true);
  const [Floading, setFLoading] = useState(true);
  const [stampedData, setStampedData] = useState({});
  const [activeTab, setActiveTab] = useState("profile");

  useEffect(() => {
    setActiveTab("profile");
  }, [user]);

  useEffect(() => {
    const fetchStampsData = async () => {
      try {
        setLoading(true);
        const reversedStamps = [...user.stamps].reverse();
        const data = await Promise.all(
          reversedStamps.map(async (stamp) => {
            if (stamp.datumId) {
              const response = await getDatum_One(stamp.datumId);
              const logDetails = {
                userId: user._id,
                username: user.username
            }
            const encodedLogDetails = encodeURIComponent(JSON.stringify(logDetails));
              let authorDetails = await getUser(response.data.userId, encodedLogDetails);
              response.data.authorId = authorDetails.data._id;
              response.data.authorName = authorDetails.data.username;
              response.data.authorImage = authorDetails.data.userImage;
              for (let r = 0; r < response.data.references.length; r++) {
                let refId = response.data.references.shift();
                let referenceDetails = await getItemDetail(refId)
                response.data.references.push(referenceDetails.data);
            }
            const groupId = user._id;
            let groupInfo = await getUser(groupId, encodedLogDetails);
            stamp.groupImage = groupInfo.data.userImage;
            let compass = groupInfo.data.compass

            if (stamp.triggers.length > 0) {
                for (let t = 0; t < stamp.triggers.length; t++) {
                    const triggeredValueIndex = stamp.triggers[t].value;
                    const valueText = compass[triggeredValueIndex].value || "Unknown";
                    stamp.triggers[t].valueText = valueText;
                }
            }

            // Combine stampDetails with the response data
            response.data.stampDetails = [
                ...(response.data.stampDetails || []),
                {
                    groupImage: stamp.groupImage,
                    triggers: stamp.triggers,
                },
            ];
              return { datumId: stamp.datumId, data: response.data };
            }
          })
        );
  
        const stampedDataObject = data.reduce((acc, { datumId, data }) => {
          acc[datumId] = data;
          return acc;
        }, {});
  
        setStampedData(stampedDataObject);
      } catch (error) {
        console.error('Error fetching stamps data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    if (user && user.stamps && user.stamps.length > 0) {
      fetchStampsData();
    }
  }, [user.stamps]);
  

  useEffect(() => {
    // Function to fetch the user details by followId
    const fetchFollowUsers = async () => {
      try {
        const users = await Promise.all(
          user.follow.map(async (followId) => {
            const logDetails = {
              userId: user._id,
              username: user.username
          }
          const encodedLogDetails = encodeURIComponent(JSON.stringify(logDetails));
          const response = await getUser(followId, encodedLogDetails);
            return response.data;
          })
        );
        setFollowUsers(users);
      } catch (error) {
        console.error('Error fetching follow users:', error);
      } finally {
        setFLoading(false);
      }
    };

    if (user.follow && user.follow.length > 0) {
      fetchFollowUsers();
    } else {
      setFLoading(false);
    }
  }, [user.follow]);

  useEffect(() => {
    // Function to fetch the user details by subscribeId
    const fetchSubscribeUsers = async () => {
      try {
        const users = await Promise.all(
          user.subscribe.map(async (subscribeId) => {
            const logDetails = {
              userId: user._id,
              username: user.username
          }
          const encodedLogDetails = encodeURIComponent(JSON.stringify(logDetails));
          const response = await getUser(subscribeId, encodedLogDetails);
            return response.data;
          })
        );
        setSubscribeUsers(users);
      } catch (error) {
        console.error('Error fetching subscribed users:', error);
      } finally {
        setSLoading(false);
      }
    };

    if (user.subscribe && user.subscribe.length > 0) {
      fetchSubscribeUsers();
    } else {
      setSLoading(false);
    }
  }, [user.subscribe]);
  
  const updateMainContent = (content) => {
    setMainContent(content);
  };
  const handleSortToggle = () => {
    setSortOrder((prev) => (prev === 'stamped' ? 'recentlyAuthored' : 'stamped'));
  };
  const handleSortToggle1 = () => {
    setSortOrder1((prev) => (prev === 'mostReferenced' ? 'recentlyAuthored' : 'mostReferenced'));
  };


  const actionType = () => {
    switch (sessionUser.userType) {
      case 0:
        if (user.userType === 2) {
          return {
            db: "follow",
            action: "Follow",
            reverse: "Unfollow"
          };
        } 
        break;
      case 1:
        break;
      case 2:
        if (user.userType === 1) {
          return {
            db: "subscribe",
            action: "Subscribe",
            reverse: "Unsubscribe"
          };
        }
        break;
    }
  }

  const generateButton = () => {
    const getActions = actionType();
    if (getActions != null) {
      const dblist = getActions["db"];
      if (!sessionUser[dblist].includes(user._id)) {
        return (
          <button 
            className='button' 
            onClick={()=>{handleAction(dblist)}}
          >
            {getActions["action"]}
          </button>
        )
      } else {
        return (
          <button 
            className='grayButton'
            onClick={()=>{handleAction(dblist)}}
          >
            {getActions["reverse"]}
          </button>
        )
      }
    } 
  }

  const handleAction = (db) => {
    const userCompass = user.compass.map((v)=>{
      return({
        value: v.value,
        group: user._id
    })})
    const userDetails = {
      user: user,
      compass: userCompass
    }
    // !!!! Need to refresh UserView stats and FollowCard after action
    // Why does the user array need to be set equal to the filtered array, but push doesn't?
    if (db === "follow") {

      const followLogic = () => {
        dispatch(followUser(userDetails, sessionUser))
        
        // Don't I want to be doing this action in the reducer?
        // Isn't this duplicate of what's happening in authReducer @ FOLLOW_USER?
        // Commenting to see if it removes functionality
        // if (!user.follow.includes(sessionUser._id)) {
        //   user.follow.push(sessionUser._id);
        // } else {
        //   user.follow = user.follow.filter((id)=>id!==sessionUser._id);
        // }
        // After commenting, functionality was still normal - must have been redundant.  Keeping just in case.
      }

      // Demo section - remove the if/else logic later
      if (sessionUser.isAdmin) {
        // user is Admin - go ahead 
        followLogic();
      } else {
        if (user.isAdmin) {
          // error - regular user cannot follow admin
        } else {
          // regular user following regular user - go ahead
          followLogic();
        }
      }
    } else {

      const subscribeLogic = () => {
        dispatch(subscribeUser(userDetails, sessionUser));
        // same as in follow above
        // similarly commented out as above
        // if (!user.subscribe.includes(sessionUser._id)) {
        //   user.subscribe.push(sessionUser._id);
        // } else {
        //   user.subscribe = user.subscribe.filter((id)=>id!==sessionUser._id);
        // }
      }

      // Demo section - remove the if/else logic later
      if (sessionUser.isAdmin) {
        // user is Admin - go ahead 
        subscribeLogic();
      } else {
        if (user.isAdmin) {
          // error - regular user cannot follow admin
        } else {
          // regular user following regular user - go ahead
          subscribeLogic();
        }
      }
    }
  }


  return (
    <div className="UserView AppGeneralCard FlexColumn">
        <div className="ProfileImages">
          <img src={serverPublic + user.userCover} alt="" />
          <img src={serverPublic + user.userImage} alt="" />
        </div>
        <div className='FlexColumn uv-profile-container'>
          <div className='FlexColumn uv-profile-info'>
            <div className="uv-profile-header">
              <div className="uv-profile-name">
                {/* <span>(Points)</span> */}
                <span>@{user.username}</span>
                <span>{user.displayname}</span>
              </div>
              <div className='uv-type-icon-container'>
                {generateButton()}
                {generateUserTypeIcon(user.userType)}
              </div>
            </div>

            <UserStats user={user}/>
              
          </div>



          <Tabs radius="md" value={activeTab} onTabChange={setActiveTab} className='GeneralTabs'>
            <Tabs.List grow>
              <Tabs.Tab value="profile" icon={<BsInfo />}></Tabs.Tab>
              {user.userType === 2
                ? <Tabs.Tab value="compass" icon={<TbCompass />}></Tabs.Tab> : ""
              }
              {user.userType === 1
                ? <Tabs.Tab value="data" icon={<AiOutlineBarChart />}></Tabs.Tab> : ""
              }
              {user.userType === 1
                ? <Tabs.Tab value="items" icon={<VscTypeHierarchy/>}></Tabs.Tab> : ""
              }
              {user.userType === 2
                ? <Tabs.Tab value="stamps" icon={<GiStamper/>}></Tabs.Tab> : ""
              }
              {user.userType !== 0
                ? <Tabs.Tab value="subscribe" icon={<BsBookmarkCheck/>}></Tabs.Tab> : ""
              }
              {user.userType !== 1
                ? <Tabs.Tab value="follow" icon={<RiUserFollowLine/>}></Tabs.Tab> : ""
              }
              
            </Tabs.List>

            <Tabs.Panel value="profile" pt="xs"> 
            <div className="FlexColumn uv-profile-container">
              <span className="user-tagline">
                {user.tagline && user.tagline.trim()
                  ? user.tagline
                  : "User hasn't added a tagline and personal statement"}
              </span>
              <br></br>
              <span dangerouslySetInnerHTML={{ __html: user.statement || "" }}></span>
            </div>
          </Tabs.Panel>

            {user.userType !== 1 ?
              <Tabs.Panel value="compass" pt="xs">
                <div className='uv-compass-container'>
                    {user.compass.length > 0
                      ? user.compass.map((value, id)=>{
                          return <Value data={value} location="compassTab" key={id} />
                      })
                      : "This user has not set their Compass yet."
                    }
                </div>
              </Tabs.Panel>
            : ""}

          {user.userType !== 1 ? (
            <Tabs.Panel value="follow" pt="xs">
              <div className="inside-container">
                {user.userType === 0 ? (
                user.follow && user.follow.length > 0 ? (
                  followUsers.length > 0 ? (
                    followUsers.map((followUser, index) => (
                      <UserList
                        key={`follow_${index}`}
                        data={followUser}
                        id={followUser._id}
                      />
                    ))
                  ) : (
                    <div>Loading users...</div>
                  )
                ) : (
                  <div>No groups followed by this user.</div>
                )
              ) : (
                user.follow && user.follow.length > 0 ? (
                  followUsers.length > 0 ? (
                    followUsers.map((followUser, index) => (
                      <UserList
                        key={`follow_${index}`}
                        data={followUser}
                        id={followUser._id}
                      />
                    ))
                  ) : (
                    <div>Loading users...</div>
                  )
                ) : (
                  <div>No users following this group.</div>
                )
              )}
              </div>
            </Tabs.Panel>
          ) : ""}


            {user.userType === 1 && (
              <Tabs.Panel value="data" pt="xs">
                <button onClick={handleSortToggle}>
                    {sortOrder === 'stamped' ? 'Sort by Most Recently Authored' : 'Sort by Most Stamped'}
                </button>
                <br></br>
                <br></br>
                <div className=" uv-data-container inside-container">
                  <DataFeed user={user} setMainContent={updateMainContent} sortOrder={sortOrder} />
                </div>
              </Tabs.Panel>
            )}

            {user.userType === 1 && (
              <Tabs.Panel value="items" pt="xs">
                <button onClick={handleSortToggle1}>
                {sortOrder1 === 'mostReferenced' ? 'Sort by Most Recently Authored' : 'Sort by Most Referenced'}
              </button>
              <br></br>
              <br></br>
              <div className="uv-items-container inside-container">
                <ItemFeed user={user} sortOrder={sortOrder1} />
              </div>
              </Tabs.Panel>
            )}

            {user.userType === 2 && (
              <Tabs.Panel value="stamps" pt="xs">
                 <div className="uv-stamps-container stamps-container">
                 {loading ? (
                    <p>Loading...</p>
                  ) : Object.keys(stampedData).length > 0 ? (
                    Object.keys(stampedData).map((datumId) => (
                      <DatumList
                        key={datumId}
                        id={datumId}
                        user={user}
                        data={stampedData[datumId]}
                        setMainContent={setMainContent}
                        datum_state="pubdatum"
                      />
                    ))
                  ) : (
                    <p>No stamps available</p>
                  )}
                </div>
              </Tabs.Panel>
            )}

            {user.userType !== 0 ? (
              <Tabs.Panel value="subscribe" pt="xs">
                <div className=" inside-container">
                  {user.userType === 1 ? (
                    user.subscribe && user.subscribe.length > 0 ? (
                      subscribeUsers.length > 0 ? (
                        subscribeUsers.map((subscribedUser, index) => (
                          <UserList
                            key={`subscribe_${index}`}
                            data={subscribedUser}
                            id={subscribedUser._id}
                          />
                        ))
                      ) : (
                        <div>Loading groups...</div>
                      )
                    ) : (
                      <div>No group subscribed to this user.</div>
                    )
                  ) : (
                    user.subscribe && user.subscribe.length > 0 ? (
                      subscribeUsers.length > 0 ? (
                        subscribeUsers.map((subscribedUser, index) => (
                          <UserList
                            key={`subscribe_${index}`}
                            data={subscribedUser}
                            id={subscribedUser._id}
                          />
                        ))
                      ) : (
                        <div>Loading authors...</div>
                      )
                    ) : (
                      <div>No author subscribed to this group.</div>
                    )
                  )}
                </div>
              </Tabs.Panel>
            ) : ""}


          </Tabs>


          
        </div>
    </div>
  )
}

export default UserView