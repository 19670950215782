import './Notification.css'
import React, { useState, useEffect, Children } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom'

import { fetchAllNote, fetchNoteCount, makeReadNote } from '../../../api/NotificationRequest';
import { setNoteData, setUnreadNoteCount } from '../../../actions/notificationActions';
import { Avatar, List, Badge } from 'antd';
import { FileTextOutlined, BellOutlined } from '@ant-design/icons';
import { MdOutlineNotifications } from 'react-icons/md';


const Notification = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.authReducer.authData);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allNote, setAllNote] = useState(null);
    const [noteCount, setNoteCount] = useState(0);
    const count_unread_note = useSelector((state) => state.notificationReducer.noteCount);
    // const note_data = useSelector((state) => state.notificationReducer.noteData);

    const changeDate = (time) => {
        const date = new Date(time);
        return (date.toDateString());
    }

    const fetchnote = async () => {
        try {

            const unread_note_arr = (await fetchNoteCount(user._id)).data;
            dispatch(setUnreadNoteCount(unread_note_arr));

        } catch (error) {
            console.error('Error fetching announcement:', error);
        }
    };
    useEffect(() => {


        fetchnote();
    }, []);
    useEffect(() => {
        let items = [];
        let iii = 0;
        if (count_unread_note) {
            count_unread_note.forEach((note, index) => {
                // if (note.unread == true) {

                items.push(
                    {
                        index: index,
                        title: note.title,
                        text: note.text,
                        time: note.updatedAt,
                        _id: note._id
                    }
                )
                // }
            });
            setAllNote(items); // Update state with fetched notes
        }
        // console.log(items)
        setNoteCount(items.length);
    }, [count_unread_note])
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    const handlchange = (key) => {

    }

    const detail_click = (index) => {

    }

    const text_over_flag = (text) => {
        if (text && text.length > 100) {
            return true;
        } else {
            return false;
        }
    }

    const make_elipse_text = (text, index, time) => {
        return (
            text_over_flag(text) ?
                <>
                    <div class="text-container">
                        <p id={`text${index}`} class="collapsed" dangerouslySetInnerHTML={{ __html: text }}>
                        </p>
                        <div class="footer">
                            <span class="date-font">{changeDate(time)}</span>
                            <span   id={`toggleButton${index}`} onClick={() => { show_detail_btn(index) }}>
                                <button class="show-more" >Show More</button>
                            </span>
                        </div>
                    </div>
                </>
                :
                <>
                    <div>
                        {text}
                        <div class="footer">
                            <span class="date-font">{changeDate(time)}</span>
                        </div>
                    </div>
                </>
        );
    }

    const show_detail_btn = (index) => {
        const textElement = document.getElementById("text" + index);
        if (textElement.classList.contains("collapsed")) {
            textElement.classList.remove("collapsed");
            textElement.classList.add("expanded");
            // this.textContent = "Show Less";
            document.getElementById('toggleButton'+index).innerHTML='<button class="show-more" >Show Less</button>';
        } else {
            textElement.classList.remove("expanded");
            textElement.classList.add("collapsed");
            // this.textContent = "Show More";
            document.getElementById('toggleButton'+index).innerHTML='<button class="show-more" >Show More</button>';
        }
    }
    const sel_list = async (index, id) => {

        const res = await makeReadNote(id, user._id);
        document.getElementById('btn' + index).style.display = 'none';
        fetchnote();
    }


    return (
        <>
            <div
                className="notification-icon dropdown dropbtn flex items-center"
            >
                <div className='flex'>
                    <MdOutlineNotifications style={{width:'19px', height:'19px'}} />
                    <Badge count={noteCount}>
                    </Badge>
                    {/* <span className="notification-text">&nbsp;Notifications</span> */}
                    <Link  to={`/viewnote`}><span className='FlexRow h-page-span notification-text'> Notifications</span></Link>
                </div>
                <div class="dropdown-content absolute top-[50px]">
                    <div className="alarm_head">
                        <>
                            {user && <Link className='linkcss' to={`/viewnote`}><span className='FlexRow h-page-span'> Open Notifications Panel</span></Link>}
                            {user && user.isAdmin && (<Link className='linkcss' to={`/createnote`}><span className='FlexRow h-page-span'> Create Note</span></Link>)}
                        </>
                    </div>
                    {
                        allNote &&
                        <List
                            itemLayout='horizontal'
                            dataSource={allNote}
                            renderItem={(item, index) => (
                                // item.note_type == 1 &&
                                <List.Item
                                    id={`btn${index}`}
                                >
                                    <List.Item.Meta
                                        style={{ paddingRight: 15, paddingLeft: 0 }}
                                        // style={{ paddingLeft: 10 }}
                                        avatar={<span style={{ paddingRight: 10, paddingLeft: 15 }}><FileTextOutlined /></span>}
                                        title={<a onClick={() => { sel_list(index, item._id) }}>{item.title}</a>}
                                        description={make_elipse_text(item.text, index, item.time)}
                                    />
                                </List.Item>
                            )}
                        />
                    }
                </div>
            </div>

            {/* {isModalOpen && <Alarm_modal onClose={toggleModal} />} */}
        </>
    )

}

export default Notification;