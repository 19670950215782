const notificationReducer = (
  state = { noteData: null, noteCount: null },
  action
) => {
  switch (action.type) {
    case "COUNT_NOTE":
      return { ...state, noteCount: action.noteCount  };
      case "NOTE_DATA":
      return { ...state, noteData: action.noteData  };
    
    default:
      return state
  }
};

export default notificationReducer;