import axios from "axios";
import { Button, Input, Spin, Tooltip } from "antd";
import SimpleBar from "simplebar-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import Capitalize from "chicago-capitalize";
import { LoadingOutlined } from "@ant-design/icons";
import { BsTrash } from "react-icons/bs";
import { MdFileUpload } from 'react-icons/md';
import Reference from "../../../components/Main/CreateDatum/AddReferenceModal/Reference/Reference";
import { getSmiliarItem, getReferenceItem } from "../../../api/ItemRequest";
import { submitItem as CreateItem } from "../../../api/ItemManipulation";
import { modalItemView } from "../../../actions/ModalAction";
import { actionUpdateFormCategory, addProccessCount, addFixedCount } from "../../../actions/formAction";
import 'simplebar-react/dist/simplebar.min.css';

const INPUT_TOOLTIP = "Search for an existing Category, or enter a new one here (Capitalize your input).";
const PLACEHOLDER = "Category";

export default function CategoryCell({ rowInd, validation, preCreateValidation, createFlag, onShowDetail }) {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  let formData = useSelector((state) => state.formReducer.formData);
  const { user } = useSelector((state) => state.authReducer.authData);
  
  // State variables to manage the component's data
  const [cellItem, setCellItem] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [imageRemove, setImageRemove] = useState(false);
  const [refItem, setRefItem] = useState(null);
  const [options, setOptions] = useState([]);
  const [userInputedSimilar, setUserInputedSimilar] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  useEffect(() => {
    if (refItem) {
      const length = formData.length;
      // const slength = formData[length - 1].category.length;
      formData[length - 1].category.filename.original = refItem.image;
    }
  }, [cellItem, refItem]);

  // Effect to initialize cellItem state when the component mounts or rowInd changes
  useEffect(() => {
    if (rowInd < -1) return;
    const tempItem = formData[rowInd].category;
    if (!tempItem) return;
    setCellItem({ ...tempItem });
    
    // Fetch reference item if it exists
    if (tempItem.refId !== "-1") {
      doGetReferenceItem(tempItem.refId);
    } else {
      setRefItem(null);
    }
  }, [rowInd, formData]);

  // Effect to handle item creation when createFlag changes
  useEffect(() => {
    if (!cellItem || cellItem.refId !== "-1") return;
    if (cellItem.refString === "" && cellItem.filename.cloudName === "") return;
    if (cellItem && cellItem.filename.cloudName === "") return;
    if (cellItem.refString === "" || cellItem.filename.cloudName === "") return;
    const createItem = async () => {
      let createdId = (await CreateItem({
        userId: user._id,
        itemName: cellItem.refString,
        itemType: 4,
        image: cellItem.filename.cloudName,
      })).data;

      dispatch(actionUpdateFormCategory({
        key: rowInd,
        category: { ...cellItem, refId: createdId },
      }));
    };
    createItem();
  }, [cellItem, dispatch, rowInd, user._id]);

  // Effect to validate pre-creation conditions
  useEffect(() => {
    if (preCreateValidation === 0) return;
    if (!onValidate()) {
      dispatch(addProccessCount());
      return;
    }

    const checkPreCreateValidation = async () => {
      if (cellItem?.refId === "-1" || cellItem?.refId.includes("reference;")) {
        dispatch(addProccessCount());
        return;
      }

      const items = (await getSmiliarItem(cellItem?.refString, 4, user._id)).data;
      const filteredItems = items?.filter((item) => item?._id !== refItem?._id);
      const itemIds = filteredItems.map((el) => el._id);

      // Update options if item IDs have changed
      if (itemIds.length !== cellItem.refItems.length) {
        setOptions(filteredItems);
      }

      const sortedArr1 = itemIds.slice().sort();
      const sortedArr2 = cellItem?.refItems.slice().sort();
      for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
          dispatch(addProccessCount());
          dispatch(addFixedCount());
          setOptions(filteredItems);
          return;
        }
      }
      dispatch(addProccessCount());
    };
    checkPreCreateValidation();
  }, [preCreateValidation, cellItem, dispatch, user._id]);

  // Effect to validate when validation changes
  useEffect(() => {
    if (validation === 0) return;
    onValidate();
  }, [validation]);

  // Validation function to check input errors
  const onValidate = () => {
    if (!cellItem) return;
    if (cellItem?.refString === "") {
      setIsError(true);
      return false;
    }
    if (cellItem?.filename?.cloudName === "") {
      setIsImageError(true);
      return false;
    }
    const testVal = Capitalize(cellItem?.refString);
    if (testVal !== cellItem?.refString) {
      setIsError(true);
      return false;
    } else {
      setIsError(false);
    }
    return true;
  };

  // Debounced function to fetch similar items based on user input
  const fetchItems = debounce(async (query) => {
    if (query) {
      try {
        const items = await getSmiliarItem(query, 4, user._id);
        const filteredItems = items?.data?.filter((item) => item?._id !== refItem?._id);
        setOptions(filteredItems);
      } catch (error) {
        setOptions([]);
        console.error("Error fetching items:", error);
      }
    } else {
      setOptions([]);
    }
  }, 500);

  // Function to get reference item details
  const doGetReferenceItem = (refId) => {
    if (refId.includes("reference;")) {
      const posString = refId.split(";")[1];
      const posAry = posString.split("-");
      const rowData = formData[parseInt(posAry[0])];
      let itemInfo = rowData.category;
      setRefItem({
        itemName: itemInfo.refString,
        itemType: "Category",
        image: itemInfo.filename.cloudName,
        itemReference: refId,
      });
    } else {
      getReferenceItem(refId).then((data) => {
        setRefItem({ ...data.data });
      });
    }
  };

  // Function to find user-defined similar items
  const doGetUserSimilarItem = (query) => {
    for (let e1 = 0; e1 < formData.length; e1++) {
      const tempElement = formData[e1].category;
      if (tempElement.refId !== "-1" || e1 === rowInd) continue;
      if (tempElement.refString.indexOf(query) !== -1) {
        setUserInputedSimilar([
          {
            itemName: tempElement.refString,
            itemFile: tempElement.filename,
            itemType: 4,
            itemReference: `${e1}-0`,
          },
        ]);
      }
    }
  };

  // Handle input blur event
  const onBlurInput = (e) => {
    if (e.relatedTarget == null) {
      onDeselect();
    }
  };

  // Deselect options
  const onDeselect = () => {
    setOptions([]);
    setUserInputedSimilar([]);
  };

  // Update item information based on user input
  const onChangeItemInfo = (field, val) => {
    setIsError(false);
    setCellItem({ ...cellItem, [field]: val });
    dispatch(actionUpdateFormCategory({
      key: rowInd,
      category: { ...cellItem, [field]: val },
    }));
    if (field === "refString") {
      fetchItems(val);
      doGetUserSimilarItem(val);
    }
  };

  // Remove selected item
  const onRemoveSelectedItem = () => {
    setCellItem({
      refId: "-1",
      filename: { original: "", cloudName: "" },
      refString: "",
      refItems: [],
      datumId: ''
    });
    dispatch(actionUpdateFormCategory({
      key: rowInd,
      category: {
        refId: "-1",
        filename: { original: "", cloudName: "" },
        refString: "",
        refItems: [],
        datumId: ''
      },
    }));
  };

  // Remove selected file
  const onRemoveSelectedFile = () => {
    setCellItem({
      ...cellItem,
      filename: { original: "", cloudName: "" },
    });
    dispatch(actionUpdateFormCategory({
      key: rowInd,
      category: {
        ...cellItem,
        filename: { original: "", cloudName: "" },
      },
    }));
    setImageRemove(false);
  };

  // Select an option from the dropdown
  const onSelectOption = (item) => {
    setCellItem({
      refId: item._id,
      refItems: options.map((e) => e._id),
    });
    dispatch(actionUpdateFormCategory({
      key: rowInd,
      category: {
        ...cellItem,
        refId: item._id,
        refItems: options.map((e) => e._id),
      },
    }));
    setRefItem({ ...item });
    setOptions([]);
    setUserInputedSimilar([]);
  };

  // Select a user-defined similar item
  const onSelectUserSimilar = (userItem) => {
    setCellItem({
      refId: `reference;${userItem.itemReference}`,
    });
    dispatch(actionUpdateFormCategory({
      key: rowInd,
      category: {
        ...cellItem,
        refId: `reference;${userItem.itemReference}`,
      },
    }));
    setOptions([]);
    setUserInputedSimilar([]);
  };

  // Handle file input change
  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setFileUploading(true);

    // Upload file to Cloudinary
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/file/getName`)
      .then(async (res) => {
        const filename = res.data.filename;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", process.env.REACT_APP_IMAGE_UPLOAD_PRESET);
        formData.append("cloud_name", process.env.REACT_APP_MEDIA_UPLOAD_URL);
        formData.append("public_id", process.env.REACT_APP_IMAGE_UPLOAD_PUBLIC + `/${filename}`);
        formData.append("folder", "tempImage");

        try {
          const uploadRetVal = await axios.post(
            `${process.env.REACT_APP_MEDIA_UPLOAD_URL}`,
            formData
          );
          return axios.post(`${process.env.REACT_APP_API_BASE_URL}/file/saveFileInfo`, {
            original: file.name,
            cloudName: uploadRetVal.data.secure_url,
            reference: "Category",
          });
        } catch (err) {
          console.error("Error Uploading Image: ", err);
        }
      })
      .then((res) => {
        setFileUploading(false);
        const temp = res.data.cloudName.replace(process.env.REACT_APP_PUBLIC_FOLDER, "");
        setIsImageError(false);
        setCellItem({
          ...cellItem,
          filename: {
            original: file.name,
            cloudName: temp,
          },
        });
        dispatch(actionUpdateFormCategory({
          key: rowInd,
          category: {
            ...cellItem,
            filename: {
              original: file.name,
              cloudName: temp,
            },
          },
        }));
      });
  };

  // Function to view details of the selected reference
  const onDetailView = async () => {
    const logDetails = {
      activity: "loaditem",
      data: {
        userId: user._id,
        username: user.username,
        viewed_userId: refItem._id
      },
      response: null,
      status: null,
    };
    dispatch(modalItemView(refItem._id, user._id, logDetails));
    onShowDetail(true);
  };

  return (
    <div className="relative mt-1">
      {cellItem && cellItem.refId === "-1" && (
        <>
          <Tooltip title={INPUT_TOOLTIP} trigger={"focus"}>
            <Input
              value={cellItem.refString}
              placeholder={PLACEHOLDER}
              className={`${isError === false ? "border border-success" : ""}`}
              status={isError === false ? null : "error"}
              onChange={(e) => onChangeItemInfo("refString", e.target.value)}
              onBlur={(e) => onBlurInput(e)}
            />
          </Tooltip>
          <input
            type="file"
            ref={fileInputRef}
            // className="hidden"
            accept="image/*"
            onChange={onFileChange}
          />
          {fileUploading && <Spin indicator={<LoadingOutlined spin />} />}
          {cellItem.filename && cellItem.filename.cloudName !== "" ? (
            <div className="relative">
              <div
                className="mt-1 flex items-center gap-2 cursor-pointer"
                onClick={() => setImageRemove(!imageRemove)}
              >
                {!fileUploading && (
                  <img
                    className="h-4 w-4"
                    src={process.env.REACT_APP_PUBLIC_FOLDER + cellItem.filename.cloudName}
                  />
                )}
                <div>{cellItem.filename.original}</div>
              </div>
              <div className={`relation-item-delete ${imageRemove ? "relation-item-show-delete" : ""}`} >
                <BsTrash size={25} onClick={() => onRemoveSelectedFile()} />
              </div>
            </div>
          ) : (
            <>
              {!fileUploading && (
                <Button className={`w-full mt-1`} danger={isImageError} onClick={() => fileInputRef.current.click()}>
                  {" "}
                  Upload Image{" "}
                  <MdFileUpload className="ml-2 mt-1" size={20} />
                </Button>
              )}
            </>
          )}
        </>
      )}
      {refItem && (
        <div className={`w-[270px]`}>
          <Reference
            location="formSelect"
            reference={refItem}
            id={"Product"}
            setSelectedReference={() => onRemoveSelectedItem()}
            onViewDatum={() => onDetailView()}
          />
        </div>
      )}
      <div className="similarContainer">
        <SimpleBar style={{ maxHeight: 100 }}>
          {userInputedSimilar.length !== 0 && userInputedSimilar[0].itemFile.cloudName !== "" && (
            <div
              className="user-defined-similarContainer"
              onClick={() => onSelectUserSimilar(userInputedSimilar[0])}
            >
              <div className="flex gap-2 items-center">
                <img
                  className="w-4 h-4 bg-contain"
                  src={
                    process.env.REACT_APP_PUBLIC_FOLDER + userInputedSimilar[0].itemFile.cloudName
                  }
                />
                <div>{userInputedSimilar[0].itemName}</div>
              </div>
            </div>
          )}
          {options.length > 0 &&
            options.map((item, index) => (
              <div className="" key={index}>
                <Reference
                  location="fromReference"
                  reference={item}
                  id={index}
                  setDisplayReferences={() => onSelectOption(item)}
                />
              </div>
            ))}
        </SimpleBar>
      </div>
    </div>
  );
}
