import { combineReducers } from "redux";

import authReducer from "./authReducer";
import datumReducer from "./datumReducer";
import itemReducer from "./itemReducer";
import dataFeedReducer from "./dataFeedReducer";
import userFeedReducer from "./userFeedReducer";
import itemFeedReducer from "./itemFeedReducer";
import categoryFeedReducer from "./categoryFeedReducer";
import actionFeedReducer from "./actionFeedReducer";
import modalReducer from "./modalReducer";
import logsReducer from "./logsReducer";
import datumViewReducer from "./datumViewReducer";
import themeReducer from "./themeReducer";
import datumeditReducer from "./datumeditReducer";
import notificationReducer from "./notificationReducer";
import feedbackReducer from "./feedbackReducer";
import formReducer from './formReducer';

export const reducers = combineReducers({
    authReducer, 
    datumReducer, 
    itemReducer, 
    dataFeedReducer, 
    userFeedReducer, 
    itemFeedReducer,
    categoryFeedReducer,
    actionFeedReducer,
    modalReducer,
    logsReducer,
    datumViewReducer,
    themeReducer,
    datumeditReducer,
    notificationReducer,
    feedbackReducer,
    formReducer
})