import { useDispatch } from 'react-redux';
import './EditProfile.css'
import React, { useState } from 'react'
import { uploadMedia } from '../../../actions/uploadAction';
import { updateUser } from '../../../actions/UserAction';
import { useParams } from 'react-router-dom';
import Croppie from 'croppie';
import { LiaTimesSolid } from 'react-icons/lia';
import { FaRotateLeft, FaRotateRight } from 'react-icons/fa6';
import { RiProfileLine } from 'react-icons/ri';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditProfile = ( {user, setProfileContent} ) => {
    
    const params = useParams();
    const dispatch = useDispatch();
    const {password, ...other} = user;
    const [formData, setFormData] = useState(other);
    const [profileImage, setProfileImage] = useState(null);
    const [coverImage, setCoverImage] = useState(null);
    const [isProfileImageCropped, setIsProfileImageCropped] = useState(false);
    const [isCoverImageCropped, setIsCoverImageCropped] = useState(false);

    const handleChange = (e) => {
      setFormData({...formData, [e.target.name]: e.target.value})
    }

    const onImageChange = (e) => {
      
      let uploadContainer, uploadLabel, imageContainer, el, tag, rotateLeftButton, rotateRightButton;

      const removeEventListeners = () => {
        // Remove event listeners to avoid self.elements undefined
        document.getElementById("croppie_x_" + tag).removeEventListener("click", handleCroppieX);
        document.getElementById("croppie_rotate_left_" + tag).removeEventListener("click", handleCroppieRotateLeft);
        document.getElementById("croppie_rotate_right_" + tag).removeEventListener("click", handleCroppieRotateRight);
        document.getElementById("croppie_submit_" + tag).removeEventListener("click", handleSubmitCroppie);
      }

      const handleCroppieX = () => {
        console.log(croppie);
        if (croppie) {croppie.destroy()}
        imageContainer.style.display = "none";
        uploadContainer.style.backgroundColor = "";
        uploadContainer.style.border = "";
        console.log(croppie);

        removeEventListeners();        
      }

      const handleCroppieRotateLeft = () => {
        croppie.rotate(-90);
      }

      const handleCroppieRotateRight = () => {
        croppie.rotate(90);
      }

      const handleDisplayX = () => {
        // Need to set image state to null, remove border around , change button back to blue
        
        if (tag === "profile") {
          setProfileImage(null);
          setIsProfileImageCropped(false);
        } else {
          setCoverImage(null);
          setIsCoverImageCropped(false);
        }  
        removeEventListeners();     
        uploadContainer.style.border = "";
        uploadLabel.style.backgroundColor = "var(--primaryColor)";
        uploadLabel.style.cursor = "cursor";
      }

      const handleSubmitCroppie = () => {

          // event.preventDefault();
          croppie.result('blob').then(function(blob) {
            
            
            // update FormData
            e.target.name === "profileImage"
            ? setProfileImage(blob)
            : setCoverImage(blob);
            
            // display cropped image
            // const croppedImg = "<img src='" + URL.createObjectURL(blob) + "'>";
            const display = document.getElementById("croppie_cropped_display_" + tag);
            display.classList.toggle('General-hidden');
          
            const displayXEl = document.getElementById("croppie_display_x_" + tag);
            displayXEl.addEventListener("click", handleDisplayX);
            
            // destroy croppie instance
            croppie.destroy();
            
            // clear and hide croppie image holder
            el.innerHTML = '';
            imageContainer.style.display = "none";

            // style container and original button  
            uploadContainer.style.backgroundColor = "";
            document.getElementById("upload_" + tag + "_image").style.backgroundColor = "var(--affirmativeGreen)";
          });
      }
      
      if (e.target.files && e.target.files[0]) {

        // Validate for file type
        const ext = e.target.files[0].type;
        const epValEl = document.getElementById("ep_validation");

        if (ext === 'image/jpg' || ext === 'image/jpeg' || ext === 'image/png') {
          
          epValEl.classList.add("General-hidden");

          e.target.name === "profileImage"
              ? tag = "profile"
              : tag = "cover"
          imageContainer = document.getElementById("croppie_image_holder_" + tag);      
          uploadContainer = document.getElementById("upload_media_container_" + tag);
          uploadContainer.style.backgroundColor = "var(--primaryUltraLite)";
          uploadContainer.style.border = "1px solid var(--primaryLite)";
          uploadLabel = document.getElementById("upload_" + tag + "_image");

          if (tag === "profile") {
            // uploadLabel.innerText = "Profile Image";
            setProfileImage(e.target.files[0]);
          } else {
            // uploadLabel.innerText = "Cover Image";
            setCoverImage(e.target.files[0]);
          }
          uploadLabel.style.cursor = "default";

          let img = e.target.files[0];
          imageContainer.style.display = "block";

          el = document.getElementById('croppie_card_body_' + tag);
          
          // split this up based on target name for profile/cover
          if (e.target.name === "profileImage") {
            var croppie = new Croppie(el, {
                viewport: { width: 250, height: 250 },
                boundary: { width: 300, height: 300 },
                showZoomer: true,
                enableOrientation: true
            });
          } else {
            var croppie = new Croppie(el, {
              viewport: { width: 400, height: 175 },
              boundary: { width: 500, height: 500 },
              showZoomer: true,
              enableOrientation: true
          });
          }

          console.log(croppie);

          croppie.bind({
            url: URL.createObjectURL(img),
            orientation: 1
          });
          document.getElementById("croppie_rotate_left_" + tag).addEventListener("click", handleCroppieRotateLeft);
          document.getElementById("croppie_rotate_right_" + tag).addEventListener("click", handleCroppieRotateRight);
          document.getElementById("croppie_x_" + tag).addEventListener("click", handleCroppieX);
          document.getElementById("croppie_submit_" + tag).addEventListener("click", handleSubmitCroppie);
        } else {
          epValEl.innerText = "Your image must be either a .jpg, .jpeg, or .png file.";
          epValEl.classList.remove("General-hidden");
        }
      }
    };

    const handleProfileImageCrop = () => {
      // Logic to crop the profile image
      setIsProfileImageCropped(true);
    };
    
    const handleCoverImageCrop = () => {
      // Logic to crop the cover image
      setIsCoverImageCropped(true);
    };

    const handleUpdateProfile = (e) => {
      e.preventDefault();

      const epValEl = document.getElementById("ep_validation");
      console.log(formData.displayname);
      
      if (formData.displayname === "") {
        console.log("Inside displayname empty")
        epValEl.innerText = "You must have a Display Name.";
        epValEl.classList.remove("General-hidden");
            
      } else if ((profileImage && !isProfileImageCropped) || (coverImage && !isCoverImageCropped)) {
        alert("Please crop the selected image before submitting.");
        return;
      } else {
        let UserData = formData;
        var imageError = false;
        if (profileImage) {
          if (profileImage.size <= 1000000) {
            const data = new FormData();
            // Adjusted for croppie blob
            // const extension = profileImage.name.split('.').pop();
            // const fileName = user._id + "_profile_" + Date.now() + "." + extension;
            const fileName = user._id + "_profile_" + Date.now();
            data.append("name", fileName);
            data.append("file", profileImage);
            data.append("upload_preset", 'asx6uxrg');
            data.append("public_id", fileName);
            UserData.userImage = fileName + ".png";
            try {
              dispatch(uploadMedia(data))
            } catch (error) {
              console.log(error)
            }
          } else {
            epValEl.innerText = "Your cropped image filesize must be less than 1mb.";
            epValEl.classList.remove("General-hidden");
            imageError = true;
          }
        } 
        if (!imageError && coverImage) {
          if (coverImage.size <= 1000000) {
            const data = new FormData();
            // Adjusted for croppie blob
            // const extension = coverImage.name.split('.').pop();
            // const fileName = user._id + "_cover_" + Date.now() + "." + extension;
            const fileName = user._id + "_cover_" + Date.now();
            data.append("name", fileName);
            data.append("file", coverImage);
            data.append("upload_preset", 'asx6uxrg');
            data.append("public_id", fileName);
            UserData.userCover = fileName + ".png";
            try {
              dispatch(uploadMedia(data))
            } catch (error) {
              console.log(error)
            }
          } else {
            epValEl.innerText = "Your cropped image filesize must be less than 1mb.";
            epValEl.classList.remove("General-hidden");
            imageError = true;
          }
        }
        if (!imageError) {
          dispatch(updateUser(params.id, UserData));
          setProfileContent("card")
        }
      }
    }


    return (
        <div className="EditProfile">
          <form action="">
            <h2 className='ep-title FlexRow'>
              <RiProfileLine/>
              Edit Profile
            </h2>
            <div className='FlexColumn input-form'>

                {/* Text Inputs */}
                <label className='ep-label'>Display Name:</label>
                <input 
                    type="text"
                    placeholder={user.displayname}
                    className='editInfoInput'
                    name='displayname'
                    onChange={handleChange}
                />
                <label className='ep-label'>Username:</label>
                <input 
                    type="text"
                    placeholder={"@" + user.username}
                    className='editInfoInput'
                    name='username'
                    disabled
                />
                <label className='ep-label'>Tagline:</label>
                <input 
                    type="text"
                    value={formData.tagline || ""}
                    placeholder={user.tagline ? user.tagline : "Sum yourself up in a few words."}
                    className='editInfoInput'
                    name='tagline'
                    onChange={handleChange}
                />
                <label className='ep-label'>Personal Statement:</label>
                {/* TODO: Update backend to handle formatting */}
                <ReactQuill
                  value={formData.statement || ""}
                  placeholder={user.statement || "Describe yourself, your values, and what you're doing on NorthStar."}
                  className="editInfoInput quill-editor"
                  
                  onChange={(content) => setFormData({ ...formData, statement: content })}
              />
                

                {/* Image Inputs */}
                <div className='ep-upload-media-container' id="upload_media_container_profile">
                  
                  {/* Profile Image Upload */}
                  <label className='ep-media-label' id="upload_profile_image">
                    <input type="file" name="profileImage" accept="image/png, image/gif, image/jpeg" onChange={onImageChange}/>
                    Upload Profile Image
                  </label>  

                  <div id="croppie_image_holder_profile" style={{display: "none"}}>
                    <div className="croppie-card-header">
                      Adjust image and select "Crop Image" when finished.
                    </div>
                    <div className="croppie-card-body" id="croppie_card_body_profile"></div>
                    <div className="croppie-card-footer FlexRow">
                      <FaRotateLeft id="croppie_rotate_left_profile" />
                      <FaRotateRight id="croppie_rotate_right_profile" />
                      <div onClick={handleProfileImageCrop} className="button croppie-crop-image-button" id="croppie_submit_profile">Crop Image</div>
                      <LiaTimesSolid id="croppie_x_profile" />
                    </div>
                  </div>

                  <div id="croppie_cropped_display_profile" className='croppie-cropped-display General-hidden'></div>
                  {profileImage && isProfileImageCropped && 
                    <div className='croppie-display-container'>
                      <img src={URL.createObjectURL(profileImage)}></img>
                      <div className='croppie-display-x' id='croppie_display_x_profile'>
                        <LiaTimesSolid/>
                      </div>
                    </div>
                  }

                </div>

                {/* Cover Image Upload */}
                <div className='ep-upload-media-container' id="upload_media_container_cover">

                  <label className='ep-media-label' id="upload_cover_image">
                    <input type="file" name="coverImage" accept="image/png, image/gif, image/jpeg" onChange={onImageChange}/>
                    Upload Cover Image
                  </label>

                  <div id="croppie_image_holder_cover" style={{display: "none"}}>
                    <div className="croppie-card-header">
                    Adjust image and select "Crop Image" when finished.
                    </div>
                    <div className="croppie-card-body" id="croppie_card_body_cover"></div>
                    <div className="croppie-card-footer FlexRow">
                      <FaRotateLeft id="croppie_rotate_left_cover" />
                      <FaRotateRight id="croppie_rotate_right_cover" />
                      <div onClick={handleCoverImageCrop} className="button croppie-crop-image-button" id="croppie_submit_cover">Crop Image</div>
                      <LiaTimesSolid id="croppie_x_cover" />
                    </div>
                  </div>
                  
                  <div id="croppie_cropped_display_cover" className='croppie-cropped-display General-hidden'></div>
                  {coverImage && isCoverImageCropped && 
                    <div className='croppie-display-container'>
                      <img src={URL.createObjectURL(coverImage)}></img>
                      <div className='croppie-display-x' id='croppie_display_x_cover'>
                        <LiaTimesSolid/>
                      </div>
                    </div>
                  }

                </div>
                
                <div id="ep_validation" className='validationError General-hidden'></div>
                
                <button className='button infoButton' onClick={handleUpdateProfile}>Update Profile</button>

            </div>
          </form>
        </div>

    );
  }

export default EditProfile
