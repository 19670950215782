import { BiUser } from 'react-icons/bi';
import './LandingStats.css';
import Launch from '../../../img/rocketlaunch.png';
import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup';
import { getStats } from '../../../api/StatsRequest';
import { generateUserTypeIcon } from '../../../tools/userTypeConversion';
import { VscTypeHierarchy } from 'react-icons/vsc';
import { AiOutlineBarChart, AiOutlineCheck } from 'react-icons/ai';
import { GiStamper } from 'react-icons/gi';
import { TbCompass, TbCrane } from 'react-icons/tb';
import { Element } from 'react-scroll';
import { MdArrowForwardIos } from 'react-icons/md';
import { FiCalendar } from 'react-icons/fi';
import { getAllValues } from '../../../api/ValuesRequest';
import { enText } from '../../../text/en';
import { scrollToAnchor } from '../../../tools/scrollToAnchor';

const LandingStats = () => {

    const [stats, setStats] = useState(null);
    const [values, setValues] = useState(null);

    useEffect(()=> {
        const getData = async() => {
            const {data} = await getStats();
            setStats(data[0]);
        }
        getData();
        const getValues = async() => {
            const {data} = await getAllValues();
            setValues(data);
        }
        getValues();
      },[])

    const expandTopic = (topicId) => {
        document.getElementById("topic_" + topicId).classList.toggle("General-hidden");
        document.getElementById("topic_arrow_" + topicId).classList.toggle("General-exp-arrow-con");
    }

  return (
    <div className="LandingStats FlexColumn">
        
        <Element id='anchor_stats' name='anchor_stats' ></Element>


        <div className="ls-broad-container ls-stat-type-container">
            
        <h2 className='landing-aboutHeader'>North Star by the numbers:</h2>
        
            <span className='ls-stat-container'>
                <span className='FlexRow ls-stat-title'>
                    <span><img src={Launch} className='ls-launchImg'/></span>
                    <h2>Phase</h2>  
                    <span className='ls-stat-value'>0</span>
                </span>
                <span className='ls-stat-description'>
                    {enText.landingStats.phase_body}
                </span>
            </span>

            <span className='ls-stat-container'>
                <span className='FlexRow ls-stat-title'>
                    <span></span>
                    <h2>Topics</h2>  
                </span>
                <span className='ls-stat-value ls-topicsTable-launched'>
                    Live: 0
                </span>
                <span className='ls-stat-value ls-topicsTable-building'>
                    Building: 1
                </span>
                <span className='ls-stat-description'>
                    {enText.landingStats.topicsDesc}
                </span>
                <div className='ls-topicsTable-key FlexRow'>
                    <span>Key:</span>
                    <span className='ls-topicsTable-future FlexRow'>
                        <FiCalendar/>
                        <span>Future Topic</span>
                    </span>
                    <span className='ls-topicsTable-building FlexRow'>
                        <TbCrane/>
                        <span>Topic is being built...</span>
                    </span>
                    <span className='ls-topicsTable-launched FlexRow'>
                        <AiOutlineCheck/>
                        <span>Topic is live!</span>
                    </span>
                </div>
                <div className='ls-topicsTable'>
                        <div className='ls-topicsTable-topic ls-topicsTable-building FlexRow'>
                            <span className='ls-topicsTable-iconCell'><TbCrane/></span>
                            {enText.landingStats.topics_supportLocal_Tri}
                            <MdArrowForwardIos id={"topic_arrow_1"} className='ls-topicsTable-arrowCell General-exp-arrow General-exp-arrow-con' onClick={()=>expandTopic(1)}/>
                        </div>
                        <div id="topic_1" className='ls-topicsTable-details FlexColumn General-hidden'>
                            {enText.landingStats.topics_supportLocal_Tri_details}
                            <div className='ls-subtopic'>
                                <div className='ls-topicsTable-topic ls-topicsTable-building'>
                                    <span className='ls-topicsTable-iconCell'><TbCrane/></span>
                                    {enText.landingStats.topics_supportLocal_JC}
                                </div>
                                <div className='ls-topicsTable-themes'>
                                    {enText.landingStats.topics_supportLocal_JC_themes}
                                </div>
                                <div className='ls-topicsTable-topic ls-topicsTable-building'>
                                    <span className='ls-topicsTable-iconCell'><TbCrane/></span>
                                    {enText.landingStats.topics_supportLocal_Jones}
                                </div>
                                <div className='ls-topicsTable-themes'>
                                    {enText.landingStats.topics_supportLocal_Jones_themes}
                                </div>
                                <div className='ls-topicsTable-topic ls-topicsTable-building'>
                                    <span className='ls-topicsTable-iconCell'><TbCrane/></span>
                                    {enText.landingStats.topics_supportLocal_Greene}
                                </div>
                                <div className='ls-topicsTable-themes'>
                                    {enText.landingStats.topics_supportLocal_Greene_themes}
                                </div>
                                <div className='ls-topicsTable-topic ls-topicsTable-building'>
                                    <span className='ls-topicsTable-iconCell'><TbCrane/></span>
                                    {enText.landingStats.topics_supportLocal_Eliz}
                                </div>
                                <div className='ls-topicsTable-themes'>
                                    {enText.landingStats.topics_supportLocal_Eliz_themes}
                                </div>
                                <div className='ls-topicsTable-topic ls-topicsTable-future'>
                                    <span className='ls-topicsTable-iconCell'><FiCalendar/></span>
                                    {enText.landingStats.topics_supportLocal_Kings}
                                </div>
                                <div className='ls-topicsTable-topic ls-topicsTable-future'>
                                    <span className='ls-topicsTable-iconCell'><FiCalendar/></span>
                                    {enText.landingStats.topics_supportLocal_Bristol}
                                </div>
                            </div>
                        </div>
                        {/* <div className='ls-topicsTable-topic ls-topicsTable-launched FlexRow'>
                            <span className='ls-topicsTable-iconCell'><AiOutlineCheck/></span>
                            {enText.landingStats.topics_politicsTN}
                            <MdArrowForwardIos id={"topic_arrow_2"} className='ls-topicsTable-arrowCell General-exp-arrow General-exp-arrow-con' onClick={()=>expandTopic(2)}/>
                        </div>
                        <div id="topic_2" className='ls-topicsTable-details FlexColumn General-hidden'>
                            {enText.landingStats.topics_politicsTN}
                        </div> */}
                </div>
                
            </span>

            <span className='ls-stat-container'>
                <span className='FlexRow ls-stat-title'>
                    <span><TbCompass/></span>
                    <h2>Values</h2>  
                </span>
                <span className='ls-stat-value'>
                    {stats && values && <CountUp start={0} end={values.filter((v)=>v.valueType===1).length} duration={3}/>}
                </span>
                <span className='ls-stat-description'>
                    Values are the reason we're all here: What change would you like to see in the world around you?  How can North Star help you to align your actions with that vision?  As North Star grows, more Values will be added.  Below are the Values being used now:
                </span>
                <div className='ls-topicsTable-key FlexRow'>
                    <span>Key:</span>
                    <span className='landing-values-core FlexRow'>
                        <span>Core Value</span>
                    </span>
                    <span className='FlexRow'>
                        <span>Action Value</span>
                    </span>
                </div>
                <div className='landing-values-container'>
                    {values && values.map((value, key)=>{
                        return (
                            <div key={key} id={"landing_values_" + key} className={value.valueType === 0 ? "landing-values-core" : "landing-values-value"}>
                                {value.value}
                            </div>
                        )
                    })}
                </div>
                <span className='ls-stat-description'>
                    Action Values are what General Users and Groups assess Items in North Star against.  They are the lens through which Groups are assessing Data, and if they find that an Item goes against their values, they will indicate one of these Action Values specifically.  Core Values are broader categories that Action Values may fall into.  Core Values are helpful for organizing the Action Values.
                </span>

            </span>

        </div>
        
        <div className="ls-users-container ls-stat-type-container">
            
            <span className='ls-stat-container'>
                <span className='FlexRow ls-stat-title'>
                    <span>{generateUserTypeIcon(0)}</span>
                    <h2>General Users</h2>  
                </span>
                <span className='ls-stat-value'>
                    {stats && <CountUp start={0} end={stats.general} duration={3}/>}
                </span>
                <span className='ls-stat-description'>
                    General Users are who North Star was built for.  When they want to learn more about a product, business, or person, they search for it on the app and make sure that it aligns with their values.
                </span>
            </span>

            <span className='ls-stat-container'>
                <span className='FlexRow ls-stat-title'>
                    <span>{generateUserTypeIcon(1)}</span>
                    <h2>Authors</h2>  
                </span>
                <span className='ls-stat-value'>
                    {stats && <CountUp start={0} end={stats.authors} duration={3}/>}
                </span>
                <span className='ls-stat-description'>
                    Authors create all of the content on North Star, including the Items that you search for, the Data that describe them, and the relationships between them all.
                </span>
            </span>

            <span className='ls-stat-container'>
                <span className='FlexRow ls-stat-title'>
                    <span>{generateUserTypeIcon(2)}</span>
                    <h2>Groups</h2>  
                </span>
                <span className='ls-stat-value'>
                    {stats && <CountUp start={0} end={stats.groups} duration={3}/>}
                </span>
                <span className='ls-stat-description'>
                    Groups do the heavy lifting for the General Users.  They find only the trustworthy and relevant data and bring it to their followers.
                </span>
            </span>

        </div>

        <div className="ls-content-container ls-stat-type-container">

            <span className='ls-stat-container'>
                <span className='FlexRow ls-stat-title'>
                    <span><VscTypeHierarchy/></span>
                    <h2>Items</h2>  
                </span>
                <span className='ls-stat-value'>
                    {stats && <CountUp start={0} end={stats.items} duration={3}/>}
                </span>
                <span className='ls-stat-description'>
                    Items are the products, businesses, and people that you use North Star to learn more about.  Try looking up and Item to see if it aligns with your values.  Don't see an Item that you're looking for?  Create an Author account and create it!
                </span>
            </span>

            <span className='ls-stat-container'>
                <span className='FlexRow ls-stat-title'>
                    <span><AiOutlineBarChart/></span>
                    <h2>Data</h2>  
                </span>
                <span className='ls-stat-value'>
                    {stats && <CountUp start={0} end={stats.data} duration={3}/>}
                </span>
                <span className='ls-stat-description'>
                    Data are all of the information that describe the Items, backed up by sources that you can check for yourself.  They describe the Items themselves, as well as the relationships between Items.  This is how you learn if an Item aligns or conflicts with your values.
                </span>
            </span>

            <span className='ls-stat-container'>
                <span className='FlexRow ls-stat-title'>
                    <span><GiStamper/></span>
                    <h2>Stamps</h2>  
                </span>
                <span className='ls-stat-value'>
                    {stats && <CountUp start={0} end={stats.stamps} duration={3}/>}
                </span>
                <span className='ls-stat-description'>
                    When a Group stamps Data, they become visible to the Group's followers.  The more Data that have been stamped, the more complete the information that gets filtered down.  This is a good way to tell if the Group you're following is working hard to bring you the information you're looking for.
                </span>
            </span>
        </div>

        <div className='landing-backToTop ls-content-container' onClick={()=>scrollToAnchor("anchor_landing_top")}>Back to Top</div>
{/* Values, Topics, Phases */}
{/* Kickoff Topic Challenge Blurb */}
    </div>
  )
}

export default LandingStats