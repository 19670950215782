import axios from "axios"

const API = axios.create({baseURL: process.env.REACT_APP_API_BASE_URL});

// Why can't I add more lines to each of the below arrow functions without
// it affecting the async nature of the function when called in authaction?
export const saveForm = (formData, formName, formId, userId) => API.post(`/form/${userId}/save`, {
  formData,
  formName,
  formId
})

export const getSheetInformation = (sheetId) => API.post(`/form/sheet/${sheetId}`)

export const deleteFrom = (userId, formId) => API.post(`/form/remove/${userId}`, {
  formId
})

export const getDatumItemList = (userId, productId, refId, style) => API.post('/form/getDatumItems', {
  userId: userId,
  productId: productId,
  secondId: refId,
  type: style
})

export const getDatumItemById = (id) => API.post('/form/getDatumItemById', {
  id: id
})

export const getDatumRelation = (productId, secondId, datumId) => API.post('/form/getDatumRelation', {
  productId,
  secondId,
  datumId
})