import { Modal } from "antd";
import { useSelector } from "react-redux";
import ItemView from "../../components/Main/Feed/Item/ItemView";
import DatumList from "./DatumListSheet";
export default ({ open, setOpen, title }) => {
  const { modalData } = useSelector((state) => state.modalReducer);
  const getModalTitle = () => {
    if (!open) return "";
    if (modalData.modalType == "referenceItem") {
      return modalData.itemName;
    } else if (modalData.modalType == "referenceDatum") return "Detail View";
  };
  return (
    <>
      {modalData && (
        <Modal
          title={getModalTitle()}
          open={open}
          onCancel={() => setOpen(false)}
          centered={true}
          closable={true}
          footer={null}
        >
          {modalData.modalType == "referenceItem" && <ItemView data={modalData} />}
          {modalData.modalType == "referenceDatum" && <DatumList data={modalData} />}
        </Modal>
      )}
    </>
  );
};
