import axios from "axios"

const API = axios.create({baseURL: process.env.REACT_APP_API_BASE_URL});

// export const submitItem = (formData) => API.post('/item', formData)
// export const triggerItem = (userId, datumId, value, itemId) => API.put('/item/trigger', {userId: userId, datumId: datumId, value: value, itemId: itemId})

// This file should be obsolete - moved to ItemManipulation.js

export const getSimilarProduct = (productName, userId) => API.post('/item/getSimilarProduct', {
  productName: productName,
  userId: userId
})

export const getSimilarCategory = (categoryName, userId) => API.post('/item/getSimilarCategory', {
  categoryName: categoryName,
  userId: userId
})

export const getSmiliarItem = (itemName, itemType, userId ) => API.post('/item/getSimilarItem', {
  itemName,
  itemType,
  userId
})

export const fileExist = (fileName) => API.post('/item/checkFileExist', {
  fileName
})

/* export const getSmiliarFileName = (fileName) => API.post('/item/getSmiliarFileName', {
  pattern: fileName
}) */
export const getSmiliarFileName = (fileName) => API.post('/file/getSimilarFileName', {
  pattern: fileName.toString()
})
export const getReferenceItem = (refId) => API.post('/item/getReferenceItem', {
  refId
})

export const getItemDetail = (id) => API.post('/item/getItemDetail', {
  id
})