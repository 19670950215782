import axios from "axios";
import { Button, Input, Spin, Tooltip } from "antd";
import SimpleBar from "simplebar-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import Capitalize from "chicago-capitalize";
import { LoadingOutlined } from "@ant-design/icons";
import { BsTrash } from "react-icons/bs";
import { MdFileUpload } from 'react-icons/md';
import Reference from "../../../components/Main/CreateDatum/AddReferenceModal/Reference/Reference";
import HyperLinkInput from "../../../components/Common/HyperLinkInput";
import { modalItemView } from "../../../actions/ModalAction";
import { getSmiliarItem, getReferenceItem } from "../../../api/ItemRequest";
import { submitItem as CreateItem } from "../../../api/ItemManipulation";
import {
  actionUpdateFormSoldBy,
  addProccessCount,
  addFixedCount,
} from "../../../actions/formAction";
import 'simplebar-react/dist/simplebar.min.css';
const INPUT_TOOLTIP = "Should follow Chicago Style";
const PLACEHOLDER = "Business";

export default function SoldbyCell({
  index,
  rowInd,
  colInd,
  validation,
  preCreateValidation,
  createFlag,
  onShowDetail
}) {
  console.log(index);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  let formData = useSelector((state) => state.formReducer.formData);
  const { user } = useSelector((state) => state.authReducer.authData);
  const [cellItem, setCellItem] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [imageRemove, setImageRemove] = useState(false);
  const [refItem, setRefItem] = useState(null);
  const [options, setOptions] = useState([]);
  const [userInputedSimilar, setUserInputedSimilar] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  useEffect(() => {
    if (refItem) {
      const length = formData.length;
      const slength = formData[length - 1].soldby.length;
      formData[length - 1].soldby[slength - 1].filename.original = refItem.image;
    }
  }, [cellItem, refItem]);

  useEffect(() => {
    if (validation === 0) return;
    onValidate();
  }, [validation]);

  useEffect(() => {
    if (rowInd < -1 || colInd < -1) return;
    const tempItem = formData[rowInd].soldby;
    if (!tempItem) return;
    if (!tempItem[colInd]) return;
    setCellItem({
      ...tempItem[colInd],
    });
    if (tempItem[colInd].refId !== "-1") {
      doGetReferenceItem(tempItem[colInd].refId);
    } else {
      setRefItem(null);
    }
  }, []);

  useEffect(() => {
    if (!cellItem) return;
    // if (createFlag === 0) return;
    if (cellItem.refId != "-1") return;
    if (cellItem.refString === "" && cellItem.filename.cloudName === "") return
    if (cellItem && cellItem.filename.cloudName === "") return;
    if (cellItem.refString === "" || cellItem.filename.cloudName === "") return;
    const createItem = async () => {
      let createdId = (
        await CreateItem({
          userId: user._id,
          itemName: cellItem.refString,
          itemType: 1,
          image: cellItem.filename.cloudName,
        })
      ).data;
      dispatch(
        actionUpdateFormSoldBy({
          rkey: rowInd,
          ckey: colInd,
          soldby: {
            ...cellItem,
            refId: createdId,
          },
        })
      );
    };
    createItem();
  }, [cellItem]);

  useEffect(() => {
    if (preCreateValidation === 0) return;
    if (onValidate() == false) {
      dispatch(addProccessCount());
      return;
    }
    const checkPreCreateValidation = async () => {
      if (!cellItem) return;

      if (cellItem.refId === "-1" || cellItem.refId.includes("reference;")) {
        dispatch(addProccessCount());
        return;
      }
      const items = (await getSmiliarItem(cellItem.refString, 1, user._id)).data;
      const filteredItems = items?.filter((item) => item?._id !== refItem?._id);
      const itemIds = filteredItems.map((el) => el._id);
      const setDifference = () => {
        setOptions(filteredItems);
      };
      if (itemIds.length !== cellItem.refItems.length) setDifference();
      const sortedArr1 = itemIds.slice().sort();
      const sortedArr2 = cellItem.refItems.slice().sort();
      for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
          dispatch(addProccessCount());
          dispatch(addFixedCount());
          setDifference();
          return;
        }
      }
      dispatch(addProccessCount());
    };
    checkPreCreateValidation();
  }, [preCreateValidation]);

  useEffect(() => {
    if (rowInd < -1 || colInd < -1) return;
    const tempItem = formData[rowInd].soldby;
    if (!tempItem) return;
    if (!tempItem[colInd]) return;
    setCellItem({
      ...tempItem[colInd],
    });
    if (tempItem[colInd].refId !== "-1") {
      doGetReferenceItem(tempItem[colInd].refId);
    } else {
      setRefItem(null);
    }
  }, [formData]);

  const onValidate = () => {
    if (!cellItem) return;
    if (cellItem.refString === "") {
      setIsError(true);
      return false;
    }
    if (cellItem.filename.cloudName === "") {
      setIsImageError(true);
      return false;
    }
    const testVal = Capitalize(cellItem.refString);
    if (testVal !== cellItem.refString) {
      setIsError(true);
      return false;
    } else {
      setIsError(false);
    }
    return true;
  };

  const fetchItems = debounce(async (query) => {
    if (query) {
      try {
        const items = await getSmiliarItem(query, 1, user._id);
        const filteredItems = items?.data?.filter((item) => item?._id !== refItem?._id);
        setOptions(filteredItems);
      } catch (error) {
        setOptions([]);
        console.error("Error fetching items:", error);
      }
    } else {
      setOptions([]);
    }
  }, 500);

  const doGetReferenceItem = (refId) => {
    if (refId.includes("reference;")) {
      console.log("REF ID: ", refId);
      const posString = refId.split(";")[1];
      const posAry = posString.split("-");
      const rowData = formData[parseInt(posAry[0])];
      let itemInfo = rowData.soldby[posAry[1]] || rowData.madeby[0];
      console.log("Item Info: ", itemInfo);
      if (itemInfo.refString === "" && itemInfo.filename.cloudName === "") {
        setRefItem(null);
        dispatch(
          actionUpdateFormSoldBy({
            rkey: rowInd,
            ckey: colInd,
            soldby: {
              refId: "-1",
              filename: {
                original: "",
                cloudName: "",
              },
              refString: "",
              refItems: [],
              sourceLink: "",
              sourceText: "",
              sourceDes: "",
            },
          })
        );
      } else {
        setRefItem({
          itemName: itemInfo.refString,
          itemType: "Soldby",
          image: itemInfo.filename.cloudName,
          itemReference: refId,
        });
      }
    } else {
      getReferenceItem(refId).then((data) => {
        setRefItem({
          ...data.data,
        });
      });
    }
  };

  const doGetUserSimilarItem = (query) => {
    for (let e1 = 0; e1 < formData.length; e1++) {
      const tempElement0 = formData[e1].soldby;
      const tempElement1 = formData[e1].madeby;
      const tempElement = [...tempElement0, ...tempElement1];
      console.log("Temp Elements (SOLD BY): ", tempElement);
      for (let e2 = 0; e2 < tempElement.length; e2++) {
        const temp = tempElement[e2];
        if (temp.refId !== "-1") continue;
        if (e1 === rowInd && e2 === colInd) continue;
        if (temp.refString.indexOf(query) !== -1) {
          setUserInputedSimilar([
            {
              itemName: temp.refString,
              itemFile: temp.filename,
              itemType: 1,
              itemReference: `${e1}-${e2}`,
            },
          ]);
        }
      }
    }
  };
  
  const onBlurInput = (e) => {
    if (e.relatedTarget === null) {
      onDeselect();
    }
  };
  const onDeselect = () => {
    setOptions([]);
    setUserInputedSimilar([]);
  };

  const onChangeItemInfo = (field, val) => {
    setIsError(false);
    setCellItem({
      ...cellItem,
      [field]: val,
    });
    dispatch(
      actionUpdateFormSoldBy({
        rkey: rowInd,
        ckey: colInd,
        soldby: {
          ...cellItem,
          [field]: val,
        },
      })
    );
    if (field === "refString") {
      fetchItems(val);
      doGetUserSimilarItem(val);
    }
  };

  const onRemoveSelectedItem = () => {
    setCellItem({
      refId: "-1",
      filename: {
        original: "",
        cloudName: "",
      },
      refString: "",
      refItems: [],
      sourceLink: "",
      sourceText: "",
      sourceDes: "",
      datumId: ''
    });
    dispatch(
      actionUpdateFormSoldBy({
        rkey: rowInd,
        ckey: colInd,
        soldby: {
          refId: "-1",
          filename: {
            original: "",
            cloudName: "",
          },
          refString: "",
          refItems: [],
          sourceLink: "",
          sourceText: "",
          sourceDes: "",
          datumId: ''
        },
      })
    );
  };

  const onRemoveSelectedFile = () => {
    setCellItem({
      ...cellItem,
      filename: {
        original: "",
        cloudName: "",
      },
    });
    dispatch(
      actionUpdateFormSoldBy({
        rkey: rowInd,
        ckey: colInd,
        soldby: {
          ...cellItem,
          filename: {
            original: "",
            cloudName: "",
          },
        },
      })
    );
    setImageRemove(false);
  };

  const onSelectOption = (item) => {
    setCellItem({
      refId: item._id,
      refItems: options.map((e) => e._id),
    });
    dispatch(
      actionUpdateFormSoldBy({
        rkey: rowInd,
        ckey: colInd,
        soldby: {
          ...cellItem,
          refId: item._id,
          refItems: options.map((e) => e._id),
        },
      })
    );
    setRefItem({
      ...item,
    });
    setOptions([]);
    setUserInputedSimilar([]);
  };

  const onSelectUserSimilar = (userItem) => {
    setCellItem({
      refId: `reference;${userItem.itemReference}`,
    });
    dispatch(
      actionUpdateFormSoldBy({
        rkey: rowInd,
        ckey: colInd,
        soldby: {
          ...cellItem,
          refId: `reference;${userItem.itemReference}`,
        },
      })
    );
    setOptions([]);
    setUserInputedSimilar([]);
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setFileUploading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/file/getName`)
      .then(async (res) => {
        const filename = res.data.filename;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", process.env.REACT_APP_IMAGE_UPLOAD_PRESET);
        formData.append("cloud_name", process.env.REACT_APP_MEDIA_UPLOAD_URL);
        formData.append("public_id", process.env.REACT_APP_IMAGE_UPLOAD_PUBLIC + `/${filename}`);
        formData.append("folder", "tempImage");
        try {
          const uploadRetVal = await axios.post(
            `${process.env.REACT_APP_MEDIA_UPLOAD_URL}`,
            formData
          );
          return axios.post(`${process.env.REACT_APP_API_BASE_URL}/file/saveFileInfo`, {
            original: file.name,
            cloudName: uploadRetVal.data.secure_url,
            reference: "Soldby",
          });
        } catch (err) {
          console.error("Error Uploading Image: ", err);
        }
      })
      .then((res) => {
        setFileUploading(false);
        const temp = res.data.cloudName.replace(process.env.REACT_APP_PUBLIC_FOLDER, "");
        setCellItem({
          ...cellItem,
          filename: {
            original: file.name,
            cloudName: temp,
          },
        });
        setIsImageError(false)
        dispatch(
          actionUpdateFormSoldBy({
            rkey: rowInd,
            ckey: colInd,
            soldby: {
              ...cellItem,
              filename: {
                original: file.name,
                cloudName: temp,
              },
            },
          })
        );
      });
  };
  const onDetailView = () => {
    const logDetails = {
      activity: "loaditem",
      data: {
        userId: user._id,
        username: user.username,
        viewed_userId: refItem._id
      },
      response: null,
      status: null,
    };
    dispatch(modalItemView(refItem._id, user._id, logDetails))
    onShowDetail(true)
  };
  return (
    <div className="relative mt-1">
      {cellItem && cellItem.refId === "-1" && (
        <>
          <Tooltip title={INPUT_TOOLTIP} trigger={"focus"}>
            <Input
              value={cellItem.refString}
              placeholder={PLACEHOLDER}
              className={`${isError === false ? "border border-success" : ""}`}
              status={isError === false ? null : "error"}
              onChange={(e) => onChangeItemInfo("refString", e.target.value)}
              onBlur={(e) => onBlurInput(e)}
            />
          </Tooltip>
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept="image/*"
            onChange={onFileChange}
          />
          {fileUploading && <Spin indicator={<LoadingOutlined spin />} />}
          {cellItem.filename && cellItem.filename.cloudName !== "" ? (
            <>
              <div className="relative">
                <div
                  className="mt-1 flex items-center gap-2 cursor-pointer"
                  onClick={() => setImageRemove(!imageRemove)}
                >
                  {!fileUploading && (
                    <img
                      className="h-4 w-4"
                      src={process.env.REACT_APP_PUBLIC_FOLDER + cellItem.filename.cloudName}
                    />
                  )}
                  <div>{cellItem.filename.original}</div>
                </div>
                <div className={`relation-item-delete ${imageRemove ? "relation-item-show-delete" : ""}`} >
                  <BsTrash size={25} onClick={() => onRemoveSelectedFile()} />
                </div>
              </div>
            </>
          ) : (
            <>
              {!fileUploading && (
                <Button className={`w-full mt-1`} danger={isImageError} onClick={() => fileInputRef.current.click()}>
                  {" "}
                  Upload Image{" "}
                  <MdFileUpload className="ml-2 mt-1" size={20} />
                </Button>
              )}
            </>
          )}
          <HyperLinkInput
            className="mt-1"
            onChange={(e) => onChangeItemInfo("sourceLink", e.target.value)}
            placeholder="Source Link"
            tooltip={"Must be a valid hyperlink"}
            value={cellItem.sourceLink}
            validation={validation}
          />
          <Input
            className="mt-1"
            onChange={(e) => onChangeItemInfo("sourceText", e.target.value)}
            placeholder="Source Text"
            value={cellItem.sourceText}
          />
          <Input
            className="mt-1"
            onChange={(e) => onChangeItemInfo("sourceDes", e.target.value)}
            placeholder="Source Description (Optional)"
            value={cellItem.sourceDes}
          />
        </>
      )}
      {/* Display source inputs if a Reference is selected but a datum has not been selected */}
      {formData[rowInd].soldby[index] && 
      (formData[rowInd].soldby[index]?.datumId === "" || formData[rowInd].soldby[index]?.datumId === null) && 
      cellItem && cellItem.refId !== "-1" && 
      (
        <div className={`w-[270px]`}>
          <Reference
            location="formSelect"
            reference={refItem}
            id={"Soldby"}
            setSelectedReference={() => onRemoveSelectedItem()}
            onViewDatum={() => onDetailView()}
          />
          <HyperLinkInput
            className="mt-1"
            onChange={(e) => onChangeItemInfo("sourceLink", e.target.value)}
            placeholder="Source Link"
            tooltip={"Must be a valid hyperlink"}
            value={cellItem.sourceLink}
            validation={validation}
          />
          <Input
            className="mt-1"
            onChange={(e) => onChangeItemInfo("sourceText", e.target.value)}
            placeholder="Source Text"
            value={cellItem.sourceText}
          />
          <Input
            className="mt-1"
            onChange={(e) => onChangeItemInfo("sourceDes", e.target.value)}
            placeholder="Source Description (Optional)"
            value={cellItem.sourceDes}
          />
        </div>
      )}
      {/* Display only Reference if a datum has been selected */}
      {formData[rowInd].soldby[index].datumId && formData[rowInd].soldby[index].datumId !== "" && (
        <div className={`w-[270px]`}>
          <Reference
            location="formSelect"
            reference={refItem}
            id={"Soldby"}
            setSelectedReference={() => onRemoveSelectedItem()}
            onViewDatum={() => onDetailView()}
          />
        </div>
      )}
      <div className="similarContainer">
        <SimpleBar style={{ maxHeight: 100 }}>
          {userInputedSimilar.length !== 0 && userInputedSimilar[0].itemFile.cloudName !== "" && (
            <div
              className="user-defined-similarContainer"
              onClick={() => onSelectUserSimilar(userInputedSimilar[0])}
            >
              <div className="flex gap-2 items-center">
                <img
                  className="w-4 h-4 bg-contain"
                  src={
                    process.env.REACT_APP_PUBLIC_FOLDER + userInputedSimilar[0].itemFile.cloudName
                  }
                />
                <div>{userInputedSimilar[0].itemName}</div>
              </div>
            </div>
          )}
          {options.length > 0 &&
            options.map((item, index) => (
              <div className="" key={index}>
                <Reference
                  location="fromReference"
                  reference={item}
                  id={index}
                  setDisplayReferences={() => onSelectOption(item)}
                />
              </div>
            ))}
        </SimpleBar>
      </div>
    </div>
  );
}
