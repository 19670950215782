
import axios from 'axios';


export const datumEditModal_Open = () => async(dispatch) => {
    dispatch({type: "DATUM_MODAL_OPEN", isOpen: true})
}
export const datumEditModal_Close = () => async(dispatch) => {
    dispatch({type: "DATUM_MODAL_CLOSE", isOpen: false})
}


export const datumReload = (isReload) => async(dispatch) => {
    dispatch({type: "DATUM_RELOAD", isReload: isReload})
}

