const logsReducer = (
    state = {logs: null, loading: false, error: false}, 
    action
) => {
    switch(action.type) {
        case "FETCH_LOGS_START":
            return {...state, loading: true, error: false}
        case "FETCH_LOGS_SUCCESS":
            return {...state, logs: action.data, loading: false, error: false}
        case "FETCH_LOGS_FAIL":
            return {...state, loading: false, error: true}        
        case "LOG_OUT":
            return {logs: null, loading: false, error: false}
        
        default: 
            return state
    }
}

export default logsReducer