import React from 'react';
import { Tabs } from 'antd';
import Header from '../../components/Header/Header';
import ViewNote from './ViewNote';
import './CreateNote.css';
import { MdOutlineNotifications } from "react-icons/md";
import { RiFeedbackLine, RiProfileLine } from "react-icons/ri";
import { TbRubberStampOff } from "react-icons/tb";
import { FcApproval } from "react-icons/fc";
import { AiOutlineNotification } from "react-icons/ai";
import NotificationBoard from '../../components/Header/Notification/NotificationBoard.';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationFilled, ProfileFilled, TagsFilled, ScheduleFilled, LikeFilled, MessageOutlined } from '@ant-design/icons';
const ViewAllNote = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.authReducer.authData);
    const note_component = [
        {
            label: (
                <span>
                    <span>
                        <MdOutlineNotifications className='icon-b' />
                    </span>
                    
                    <span className="tab-label icon-a">All Notifications</span>
                </span>
            ),
            key: 0,
            children: <NotificationBoard key={0} tab_index='0' tab_key={0} /> // Pass key as tab_key
        },
        {
            label: (
                <span>
                    <AiOutlineNotification className='icon-b' />
                    <span className="tab-label icon-a">Announcements</span>
                </span>
            ),
            key: 1,
            children: <NotificationBoard key={1} tab_index='1' tab_key={1} /> // Pass key as tab_key
        },
        {
            label: (
                <span>
                    <RiProfileLine className='icon-b' />
                    <span className="tab-label icon-a">Profile Setup and Orientation</span>
                </span>
            ),
            key: 2,
            children: `Coming Soon`
        },
        ...(user.userType === 1 || user.isAdmin? [{
            label: (
                <span>
                    <RiFeedbackLine className='icon-b' />
                    <span className="tab-label icon-a">Datum Feedback</span>
                </span>
            ),
            key: 3,
            children: <NotificationBoard key={3} tab_index='3' tab_key={3} /> // Pass key as tab_key
        }] : []),
        ...(user.userType === 2 || user.isAdmin ?  [{
            label: (
                <span>
                    <TbRubberStampOff className='icon-b' />
                    <span className="tab-label icon-a">Unstamp Requests</span>
                </span>
            ),
            key: 4,
            children: <NotificationBoard key={4} tab_index='4' tab_key={4} />
        }] : []),
        ...(user.userType === 0 || user.isAdmin ? [{
            label: (
                <span>
                    <FcApproval className='icon-b' />
                    <span className="tab-label icon-a">Substitute Approvals</span>
                </span>
            ),
            key: 5,
            children: `Coming Soon`
        }] : []),
    ];

    const onChange = (key) => {
        // console.log("Active tab key:", key);
    };

    return (
        <div className="Profile HeaderGrid">
            <div>
                <Header />
            </div>
            <Tabs onChange={onChange} type="card" items={note_component} />
        </div>
    );
};
export default ViewAllNote;