import React, { useEffect, useState } from 'react'
import {    MenuFoldOutlined,    MenuUnfoldOutlined,   } from '@ant-design/icons';

import { Button, Layout, Menu, theme } from 'antd';
import { MdOutlineNoteAdd } from "react-icons/md";
import './CreateNote.css'
import Header1 from '../../components/Header/Header'
import { useSelector } from 'react-redux'
import CreateNote_content from './CreateNote_Content'
import { fetchAllNote , fetchNoteCount} from '../../api/NotificationRequest';
import { setUnreadNoteCount } from '../../actions/notificationActions';
import { useDispatch } from 'react-redux';

const { Header, Sider, Content } = Layout;

const CreateNote = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.authReducer.authData);
    const [collapsed, setCollapsed] = useState(false);
    const [allNote, setAllNote] = useState('');
    const [item_arr, setItem_arr] = useState('');

    const [selectedKey, setSelectedKey] = useState('0'); // State to track selected menu item
    const { token: { colorBgContainer, borderRadiusLG }, } = theme.useToken();
    const handleMenuClick = (key) => {
        setSelectedKey(key); // Update selected key
    };


    const init = async () => {
        const res = await fetchAllNote('1',user._id)
        setAllNote(res.data);
    }
    useEffect(() => {
        if (allNote) {
            let note_arr = [];
            note_arr.push({
                key: 0,
                icon: <MdOutlineNoteAdd />,
                label: 'Create a Note'
            })
            note_arr.push(...allNote.map((item, index) => (
                item.note_type == `1` &&
                {
                    key: index + 1,
                    icon: <MdOutlineNoteAdd />,
                    label: item.title,
                }
            )));
            setItem_arr(note_arr);
        }
    }, [allNote])

    useEffect(() => {
        init();
    }, [])

    const handleDeleteNote = (noteid, index) => {
        init();

    };


    // Function to render content based on selected key
    const renderContent = () => {
        switch (selectedKey) {
            case '0':
                return <CreateNote_content item_index={selectedKey} view_flag={1} title={''} content={''} noteid={'new'} />;
            default:
                // return ;
                return allNote[selectedKey - 1] ?
                    (<CreateNote_content item_index={selectedKey} view_flag='1' title={allNote[selectedKey - 1].title} content={allNote[selectedKey - 1].text} noteid={allNote[selectedKey - 1]._id} onDelete={handleDeleteNote} />)
                    :
                    <></>
        }
    };

    return (
        <div className="Profile HeaderGrid">
            <div>
                <Header1 />
            </div>
            <div className=''>
                <Layout>
                <Sider trigger={null} collapsible collapsed={collapsed} style={{ height: '70vh', overflowY: 'auto', background: '#f0f2f5' }}>
                        <div className="demo-logo-vertical" />
                        <Menu
                            theme="light"
                            mode="inline"
                            defaultSelectedKeys={['0']}

                            onClick={({ key }) => handleMenuClick(key)} // Handle menu item click
                            items={item_arr}
                        // {item_arr.map((item,index)=>({
                        //     key: item.key,
                        //     icon: item.icon,
                        //     label: item.label
                        // }))}
                        />
                    </Sider>
                    <Layout>
                        <Header style={{ padding: 0, background: colorBgContainer ,background: '#f0f2f5'  }}>
                            <Button
                                type="text"
                                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                    // background: '#f0f2f500' 
                                }}
                            />
                        </Header>
                        <Content
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                minHeight: 500,
                                background: colorBgContainer,
                                borderRadius: borderRadiusLG,
                                background: '#f0f2f500' 
                            }}
                        >

                            {renderContent()} {/* Render the selected content */}
                        </Content>
                    </Layout>
                </Layout>
            </div>
        </div>
    )
}

export default CreateNote