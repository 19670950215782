import { useEffect, useState } from "react";
import { Input, Popconfirm } from "antd";
import { BiCheck, BiSolidTrash, BiEditAlt } from "react-icons/bi";
import './SheetTitle.css'
export default function SheetTitle({ sheetTitle, onChange, onRemove }) {
  const [title, setTitle] = useState(sheetTitle)
  const [edit, setEdit] = useState(false)
  const [onMouseSheetNameOver, setOnMouseSheetNameOver] = useState(false)
  const [sheetNameStatus, setSheetNameStatus] = useState(true)
  useEffect(() => {
    setTitle(sheetTitle)
  }, [sheetTitle])

  const onChangeTitle = (val) => {
    if ( val == '' ) setSheetNameStatus(false)
    setTitle(val)
  }
  const onChangeConfirm = () => {
    if ( title == '' ) return
    onChange(title)
    setEdit(false)
  }
  return (
    <>
      {edit ? (
        <div className="sheet-title-container">
          <div
            className={`border border-solid rounded-sm pr-2 ${
              !sheetNameStatus ? "border-error" : "border-success"
            } flex text-middle gap-2  items-center justify-end`}
          >
            <Input
              variant="borderless"
              onChange={(e) => onChangeTitle(e.target.value)}
              value={title}
              status={!sheetNameStatus ? "error" : null}
              placeholder="Please input Sheet Name"
            />
            <BiCheck
              onMouseEnter={() => setOnMouseSheetNameOver(true)}
              onMouseLeave={() => setOnMouseSheetNameOver(false)}
              size={onMouseSheetNameOver ? "20px" : "15px"}
              onClick={() => onChangeConfirm()}
            />
          </div>
          <Popconfirm
            title="Delete the Sheet"
            description="Are you sure you want to delete this Sheet?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => onRemove()}
          >
            <BiSolidTrash size={"20px"} />
          </Popconfirm>
        </div>
      ) : (
        <div className="sheet-title-container">
          <div>{title}</div>
          <BiEditAlt
            onClick={() => setEdit(true)}
            onMouseEnter={() => setOnMouseSheetNameOver(true)}
            onMouseLeave={() => setOnMouseSheetNameOver(false)}
            size={onMouseSheetNameOver ? "20px" : "15px"}
          />
          <Popconfirm
            title="Delete the Sheet"
            description="Are you sure to delete this Sheet ?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => onRemove()}
          >
            <BiSolidTrash size={"20px"} />
          </Popconfirm>
        </div>
      )}
    </>
  );
}
