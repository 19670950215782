import React from 'react';
import { useState, useEffect } from 'react';
import CreateDatum from '../../CreateDatum/CreateDatum1';
import './DraftModal.css'
import { useDispatch } from 'react-redux';
import { Button, List, Skeleton, Modal } from 'antd';
import { datumEditModal_Close } from '../../../../actions/datumeditAction';

const Alarm_modal = ({ setMainContent, openFlag, onClose, ceFlag, datum_i, btn_flag, datum_state }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(openFlag);

  // Update isModalOpen when openFlag changes
  useEffect(() => {
    setIsModalOpen(openFlag);
  }, [openFlag]);

  return (
    <Modal
      style={{ top: 70 }}
      open={isModalOpen}
      onOk={() => {
        setIsModalOpen(false);
        dispatch(datumEditModal_Close());
        onClose(); // Correctly invoke onClose
      }}
      onCancel={() => {
        setIsModalOpen(false);
        dispatch(datumEditModal_Close());
        onClose(); // Correctly invoke onClose
      }}
      footer={null} // Hide the footer buttons
    >
      <CreateDatum
        setMainContent={setMainContent}
        ceFlag={ceFlag}
        datum_i={datum_i}
        btn_flag={btn_flag}
        dlg_mode={datum_state}
        onClose={onClose}
      />
    </Modal>
  );
};

export default Alarm_modal;