import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, notification } from "antd";
import Capitalize from "chicago-capitalize";
import Header from "../../components/Header/Header";
import DetailViewModal from "./DetailViewModal";
import './cat.css';
import {
  newForm,
  newFormLow,
  onChangeFormName,
  onAddFormMadeBy,
  onAddFormSoldBy,
  onAddFormSubstitute,
  onSaveForm,
  onGetSheetInformation,
  messageInit,
  deleteForm,
  initPreCreation,
  removeFormRow,
  removeFormMadeby,
  removeFormSoldby,
  removeFormSubstitute,
  removeFormTheme,
} from "../../actions/formAction";
import ProductCell from "./TableCell/ProductCell";
import CategoryCell from "./TableCell/CategoryCell";
import MadebyCell from "./TableCell/MadeByCell";
import SoldbyCell from "./TableCell/SoldByCell";
import SubstituteCell from "./TableCell/SubstituteCell";
import SheetTitle from "./SheetTitle";
import ShowDatumList from "./ShowDatumList";
import { RELATION } from "../../const";
import "./Tool.css";
// import { Color } from "antd/es/color-picker";
import { VscTools } from "react-icons/vsc";
import AddThemeModal from "../../components/Main/CreateDatum/AddThemeModal/AddThemeModal";
import { BiHash } from "react-icons/bi";
import { BsPlusLg, BsTrash } from "react-icons/bs";
import { DatabaseFilled } from "@ant-design/icons";

const Profile = () => {
  const dispatch = useDispatch();
  const [validationNum, setValidationNum] = useState(0);
  const [preCreateValidation, setPreCreateValidation] = useState(0);
  const [createFlag, setCreateFlag] = useState(0);
  const { user } = useSelector((state) => state.authReducer.authData);
  let formData = useSelector((state) => state.formReducer.formData);
  const formName = useSelector((state) => state.formReducer.formName);
  const formId = useSelector((state) => state.formReducer.formId);
  const proccessCount = useSelector((state) => state.formReducer.processedCount);
  const needFixCount = useSelector((state) => state.formReducer.needFixCount);
  const saveFlag = useSelector((state) => state.formReducer.saveFlag);
  const createAble = useSelector((state) => state.formReducer.createFlag);
  const msg = useSelector((state) => state.formReducer.msg);
  const [sheetNames, setSheetNames] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [showDetail, setShowDetail] = useState(false)
  const [exactError, setExactError] = useState("")
  const [sources, setSources] = useState([])

  // const onLoadSheetInformation = (sheetId) => {
  //   // console.log('sheetID', sheetId)
  //   // dispatch(onGetSheetInformation(sheetId));
  // };
  
  
  // Start ATM________________
  const [openATM, setOpenATM] = useState(false);
  const [dataATM, setDataATM] = useState([]);
  const [newThemes, setnewThemes] = useState([])

  // useEffect(() => {
  //   let tempDataAtm = []
  //   let formLength = user.form.length;
  //   for (let i = 0; i < formLength; i++) {
  //     if (user.form[i].sheetName === formName) {
  //       let themeLength = user.form[i].rowData.length;
  //       for (let j = 0; j < themeLength; j++) {
  //         if (user.form[i].rowData[j].themes) {
  //           tempDataAtm.push(user.form[i].rowData[j].themes)
  //         }
  //       }
  //     }
  //   }
  //   console.log("TemDataAtm: ", tempDataAtm);
  //   setDataATM(tempDataAtm[0] || []);
  // }, [user]);
  useEffect(() => {
    let tempDataAtm = [];
    let formLength = user.form.length;
  
    // Iterate through the form to process rows
    for (let i = 0; i < formLength; i++) {
      if (user.form[i].sheetName === formName) {
        let themeLength = user.form[i].rowData.length;
  
        for (let j = 0; j < themeLength; j++) {
          const currentRow = user.form[i].rowData[j];
  
          if (currentRow.themes) {
            // Ensure each theme is added only once
            currentRow?.themes?.forEach((theme) => {
              if (!tempDataAtm?.some(existingTheme => existingTheme.theme === theme.theme)) {
                tempDataAtm?.push(theme);
              }
            });
          }
  
          // Carry over themes from previous rows to the current row
          if (j > 0) {
            const previousRow = user.form[i].rowData[j - 1];
            if (previousRow.themes) {
              previousRow.themes.forEach((theme) => {
                if (!currentRow?.themes?.some(existingTheme => existingTheme.theme === theme.theme)) {
                  currentRow?.themes?.push(theme);
                }
              });
            }
          }
        }
      }
    }
  
    console.log("TempDataAtm: ", tempDataAtm);
    setDataATM(tempDataAtm);
    setnewThemes(tempDataAtm);
  }, [user]);
  
  

  useEffect(() => {
    let length = formData.length;
    let array = [];
    
    for (let i = 0; i < length; i++) {
      const soldby = formData[i]?.soldby;
      if (soldby?.[soldby.length - 1]?.sourceLink !== "" && soldby?.[soldby.length - 1]?.sourceText !== "") {
        array.push({
          link: soldby[soldby.length - 1]?.sourceLink,
          text: soldby[soldby.length - 1]?.sourceText,
          description: soldby[soldby.length - 1]?.sourceDes,
        });
      }
    }
  
    setSources(array);
  }, [formData]);

  useEffect(() => {
    if (exactError) {
      openNotificationWarning(exactError);
      setExactError("");
    }
  }, [exactError]);

  const prepareThemeMarkup = (theme, key) => {
    return (
      <div
        key={key}
        className="cd-atm-container FlexRow"
        onClick={() => toggleDeleteButton("atm_delete_" + key)}
      >
        <BsTrash
          id={"atm_delete_" + key}
          className="cd-atm-delete"
          onClick={() => removeTheme(key)}
        />
        <div className="cd-atm-trend">{theme.theme}</div>
      </div>
    );
  };
  
  const removeTheme = (index) => {
    setDataATM(() => {
      return [...dataATM.slice(0, index), ...dataATM.slice(index + 1)]
    })
    setnewThemes(() => {
      return [...dataATM.slice(0, index), ...dataATM.slice(index + 1)]
    })
    dispatch(removeFormTheme());
    let test = formData.length;
    for (let i = 0; i < test; i++) {
      formData[i]?.themes?.splice(index, 1);
    }
  }

  const toggleDeleteButton = (id) => {
    console.log("ToggleDelete triggered with id: " + id);
    const deleteButtonEl = document.getElementById(id);
    console.log(deleteButtonEl.style.display);
    if (deleteButtonEl.style.display == "none" || deleteButtonEl.style.display == "") {
      deleteButtonEl.style.display = "block";
    } else {
      deleteButtonEl.style.display = "none";
    }
    console.log(deleteButtonEl.style.display);
  }

  // Set ATM behavior
  useEffect(() => {
    // console.log("Inside useEffect with dataATM array at: " + dataATM.length);
    const alertEl = document.getElementById("cd_atm_alert");
    const atmOpenEl = document.getElementById("cd_atm_open");
    if (dataATM.length == 5) {
      alertEl.style.display = "block";
      alertEl.innerText = "Maximum number of themes reached.";
      atmOpenEl.onclick = null;
      setOpenATM(false);
    } else {
      alertEl.style.display = "none";
      atmOpenEl.onclick = () => setOpenATM(true);
    }
  }, [dataATM])
  // __________________________________________ End ATM

  useEffect(() => {
    if (proccessCount === 0) return;
    let count = 0;
    for (let i = 0; i < formData.length; i++) {
      count = count + 2 + formData[i].soldby.length + formData[i].madeby.length + formData[i].substitute.length;
    }
    if (count === proccessCount) {
      if (needFixCount === 0) {
        setCreateFlag(createFlag < 1000 ? createFlag + 1 : 1);
        openNotification("All content successfully created!");
      } else {
        openNotificationWarning(`There were ${needFixCount} errors that need to be fixed.`);
      }
      dispatch(initPreCreation());
    }
  }, [proccessCount, needFixCount]);

  useEffect(() => {
    setDataATM([]);
    setnewThemes([]);
    setSheetNames(
      user.form
        .sort((a, b) => a.sheetName.localeCompare(b.sheetName))
        .map((el, index) => {
          // console.log('sheetID-------------------', el)
          return {
            label: (
              <div 
              // onClick={() => onLoadSheetInformation(el._id)} key={el._id}
              >
                {el.sheetName}
              </div>
            ),
            key: el._id, // Use a unique key
          }
        })
    );
  }, [user.form]);

  const onClick = ({ key }) => {
    // console.log(`Click on item ${key}`);
    dispatch(onGetSheetInformation(key));
  };

  useEffect(() => {
    if (msg !== "") {
      openNotification(msg);
    }
  }, [msg]);

  const onNewSheet = () => {
    setDataATM([]);
    setnewThemes([]);
    dispatch(newForm());
  };

  const addMadeBy = (key) => {
    for (let i = 0; i < formData.length; i++) {
      if (formData[i].key !== key) continue;
      for (let i2 = 0; i2 < formData[i].madeby.length; i2++) {
        const temp = formData[i].madeby[i2];
        if (temp.refId === "-1" && (temp.business === "" || temp.filename === "")) {
          alert("Please Fill All Made By Input");
          return;
        }
      }
    }
    dispatch(onAddFormMadeBy({ key }));
  };

  const addSoldBy = (key) => {
    for (let i = 0; i < formData.length; i++) {
      if (formData[i].key !== key) continue;
      for (let i2 = 0; i2 < formData[i].soldby.length; i2++) {
        const temp = formData[i].soldby[i2];
        if (temp.refId == "-1" && (temp.business === "" || temp.filename === "")) {
          alert("Please Fill All SoldBy Input");
          return;
        }
      }
    }
    dispatch(onAddFormSoldBy({ key }));
  };

  const addSubstitute = (key) => {
    for (let i = 0; i < formData.length; i++) {
      if (formData[i].key !== key) continue;
      for (let i2 = 0; i2 < formData[i].substitute.length; i2++) {
        const temp = formData[i].substitute[i2];
        if (temp.refId == "-1" && (temp.product == "" || temp.filename == "")) {
          alert("Please Fill All Substitute Input");
          return;
        }
      }
    }
    dispatch(onAddFormSubstitute({ key }));
  };

  const onDeleteConfirm = () => {
    if (formId === "-1") {
      dispatch(newForm());
    } else {
      dispatch(deleteForm(user._id, formId));
      dispatch(newForm());
    }
  };

  const onNewRow = () => {
    dispatch(newFormLow());
  };

  const onSave = () => {
    if (formName === "") {
      alert("Please give your sheet a name.");
      return;
    }
    let updatedFormData = formData.map((item, index) => {
      // Check if this is the current index
      const isCurrentIndex = index === formData.length - 1;
  
      // Handle theme logic
      if (isCurrentIndex) {
        // If `dataATM` has themes, add them; otherwise, set themes to null
        const updatedThemes = dataATM && dataATM.length > 0 ? dataATM : null;
        return {
          ...item,
          themes: updatedThemes,
        };
      } else {
        // Keep the existing themes for other rows
        return item;
      }
    });
  
    // Dispatch the updated formData
    dispatch(onSaveForm(updatedFormData, formName, formId, user._id));
  };

  const onPreCreation = () => {
    if (formName === "")
      {
        setExactError("You need to provide a name for this sheet first!")
        return false
      } 
    setValidationNum(validationNum < 1000 ? validationNum + 1 : 1);
    const onChicagoValidation = (val) => {
      const testVal = Capitalize(val);
      if (testVal !== val) {
        return false;
      } else {
        return true;
      }
    };
    for (let i = 0; i < formData.length; i++) {
      const formElement = formData[i];
      const product = formElement.product;
      if (product.refId === "-1") {
        if (!onChicagoValidation(product.refString)) {
          if (!onChicagoValidation(product.refString)) {
            setExactError("Validation failed for product: Invalid input format in refString.");
          }
          console.log("Product failing pre-create validation at ", product);
          return false;
        }
      }
      const category = formElement.category;
      if (category.refId === "-1") {
        if (category.refString === "") continue;
        if (!onChicagoValidation(category.refString)) {
          if (!onChicagoValidation(category.refString)) {
            setExactError("Validation failed for category: Non chicago format");
          }
          console.log("Category failing pre-create validation at ", category);
          return false;
        }
      }
      const madeby = formElement.madeby;
      for (let j = 0; j < madeby.length; j++) {
        const madebyElem = madeby[j];
        if (madebyElem.refId === "-1") {
          if (madebyElem.refString === "") continue;
          if (
            !onChicagoValidation(madebyElem.refString) ||
            madebyElem.refString === ""
          ) {
            if (!onChicagoValidation(madebyElem.refString)) {
              setExactError("Validation failed for madeby: Invalid input format in refString.");
            } else if (madebyElem.refString === "") {
              setExactError("Validation failed for madeby: refString is empty.");
            }
            console.log("Made by failing pre-create validation");
            return false;
          }
        }
      }

      const soldby = formElement.soldby;
      for (let j = 0; j < soldby.length; j++) {
        const soldbyElem = soldby[j];
        
        if (soldbyElem.refId === "-1") {
          if (soldbyElem.refString === "") continue;
          if (
            !onChicagoValidation(soldbyElem.refString) ||  
            soldbyElem.refString === ""
          ) {
            // Set the exact error message before returning
            if (!onChicagoValidation(soldbyElem.refString)) {
              setExactError("Validation failed for soldby: Invalid input format.");
            } else if (soldbyElem.refString === "") {
              setExactError("Validation failed for soldby: refString is empty.");
            }
            console.log("Sold by by failing pre-create validation");
            return false;
          }
        }
      }

      const substitute = formElement.substitute;
      for (let j = 0; j < substitute.length; j++) {
        const substituteElem = substitute[j];
        if (substituteElem.refId === "-1") {
          if (substituteElem.refString === "") continue;
          if (!onChicagoValidation(substituteElem.refString) || substituteElem.refId === "-1") {
            if (!onChicagoValidation(substituteElem.refString)) {
              setExactError("Validation failed for substitute: Invalid input format in refString.");
            }
            console.log("Substitute failing pre-create validation");
            return false;
          }
        }
      }
    }
    return true;
  };


  const onCreate = () => {
    let updatedFormData = formData.map((item, index) => {
      // Check if this is the current index
      const isCurrentIndex = index === formData.length - 1;
  
      // Handle theme logic
      if (isCurrentIndex) {
        // If `dataATM` has themes, add them; otherwise, set themes to null
        const updatedThemes = dataATM && dataATM.length > 0 ? dataATM : null;
        return {
          ...item,
          themes: updatedThemes,
        };
      } else {
        // Keep the existing themes for other rows
        return item;
      }
    });
  
    // Dispatch the updated formData
    dispatch(onSaveForm(updatedFormData, formName, formId, user._id));

    // Dispatch the form data 
    dispatch(initPreCreation());
    alert("Please save your sheet after creating it");
    // dispatch(onSaveForm(formDataWithThemes, formName, formId, user._id));
    let preCreationFlag = false;
    preCreationFlag = onPreCreation();
    if (!preCreationFlag) {
      return
      // openNotificationWarning("There are still values that need to be filled in.");
    } else {
      setPreCreateValidation(preCreateValidation < 1000 ? preCreateValidation + 1 : 1);
    }
  };

  const openNotificationWarning = (msg) => {
    api["warning"]({
      message: "Warning!",
      description: msg,
      duration: 3,
      showProgress: true,
    });
    dispatch(messageInit());
  };

  const openNotification = (msg) => {
    api["success"]({
      message: "Success!",
      description: msg,
      duration: 3,
      showProgress: true,
    });
    dispatch(messageInit());
  };

  const onRemoveRow = (rkey) => {
    const lastRow = formData[formData.length - 1];
    let madebyCondition = true
    let soldbyCondition = true
    let substituteCondition = true

    for (let j = 0; j < lastRow.madeby.length; j++) {
      if (lastRow.madeby[j].refId === "-1") {
        madebyCondition = false
      }
    }
    for (let i = 0; i < lastRow.soldby.length; i++) {
      if (lastRow.soldby[i].refId === "-1") {
        soldbyCondition = false
      }
    }
    for (let k = 0; k < lastRow.substitute.length; k++) {
      if (lastRow.substitute[k].refId === "-1") {
        substituteCondition = false
      }
    }

    if (lastRow.product.refId !== "-1" || lastRow.category.refId !== "-1" || madebyCondition || soldbyCondition || substituteCondition) {
      const confirmDelete = window.confirm("Are you sure you want to delete the last row? It contains data.");
      if (!confirmDelete) return;
    }
    dispatch(removeFormRow(rkey));
  };

  const onRemoveMadeby = (rkey, ckey) => {
    dispatch(removeFormMadeby({ rkey, ckey }));
  };

  const onRemoveSoldby = (rkey, ckey) => {
    dispatch(removeFormSoldby({ rkey, ckey }));
  };

  const onRemoveSubstitute = (rkey, ckey) => {
    dispatch(removeFormSubstitute({ rkey, ckey }));
  };
  
  return (
    <div className="Profile HeaderGrid">
      {contextHolder}
      <div>
        <Header />
      </div>
      <div className="Tool-Container">
        <div className="FlexRow gap-2 tool-sheet-title"><VscTools /><div>Products Worksheet</div></div>
        <div className="FlexRow tool-header">

          <div className="tool-headerButton-container">
            <Dropdown className={"button text-button px-2 ant-dropdown"} menu={{ items: sheetNames, onClick }} trigger={["click"]}>
              <span className="tool-sheetsDropdown">My Sheets</span>
            </Dropdown>
            <Button type={"Primary"} className={"button"} onClick={() => onNewSheet()}>
              New
            </Button>
            <Button
              type={"Primary"}
              className={`button`}
              onClick={() => onSave()}
              style={{
                backgroundColor: saveFlag ? "#87d068" : "",
                borderColor: saveFlag ? "#87d068" : "",
              }}
              disabled={!saveFlag}
            >
              Save
            </Button>
            {/* <Button type={"Primary"} className={"button"} onClick={() => onValidate()}>
              Validate
            </Button> */}
            <Button type={"Primary"} className={"button"} onClick={() => onCreate()} disabled={!createAble}>
              Create
            </Button>
          </div>
          <div className="tool-themeSelector-container">
            <span className='cd-input-span'>
              <BiHash />
              <div id='cd_themes' className='cd-input cd-themes'>
              {(formData.some(item => item?.themes?.length > 0) || dataATM.length > 0) ? (
              // Combine and deduplicate themes from formData and dataATM
              Array.from(
                new Map(
                  [
                    // Combine formData themes and ensure they don't have duplicate names
                    ...formData
                      .flatMap(item => item.themes ?? [])
                      .map((theme, index) => [theme.theme, theme]),

                    // Combine dataATM themes and ensure they don't have duplicate names
                    ...dataATM.map((theme, index) => [theme.theme, theme]),
                  ]
                ).values()
              )
                .concat(
                  // Add newly created themes separately and ensure they don't duplicate
                  newThemes.filter(newTheme => 
                    ![...formData.flatMap(item => item.themes ?? []), ...dataATM]
                      .some(existingTheme => existingTheme.theme === newTheme.theme)
                  )
                )
                .map((theme, index) => prepareThemeMarkup(theme, index))  // Render the themes without duplicates
            ) : (
              "No themes selected"
            )}

            </div>

              <BsPlusLg id="cd_atm_open" />
            </span>
            <div id="cd_atm_alert" className='cd-atm-alert'></div>
            {openATM && <div className="cd-m-container">
              <AddThemeModal
                setOpenATM={setOpenATM}
                dataATM={dataATM}
                setDataATM={setDataATM}
              />
            </div>}
          </div>
        </div>
        <div className="tool-main-table relative px-2 py-2 bg-white">
          <div>
            <SheetTitle
              sheetTitle={formName}
              onChange={(val) => dispatch(onChangeFormName(val))}
              onRemove={() => onDeleteConfirm()}
            />
          </div>
          <div className="border border-primary table-container">
            {/* Header */}
            <div className="grid grid-cols-5 bg-gray">
              <div className="p-2 text-center text-middle table-header border-b border-primary">Product</div>
              <div className="p-2 text-center text-middle table-header border-b border-primary">Category</div>
              <div className="p-2 text-center text-middle table-header border-b border-primary">Made By</div>
              <div className="p-2 text-center text-middle table-header border-b border-primary">Sold By</div>
              <div className="p-2 text-center text-middle table-header border-b border-primary">Substitute For</div>
            </div>
            {/* Body */}
            {formData.map((rData, index) => (
              <div className="grid grid-cols-5" key={index}>
                <ProductCell
                  rowInd={index}
                  validation={validationNum}
                  preCreateValidation={preCreateValidation}
                  createFlag={createFlag}
                  onShowDetail={setShowDetail}
                />
                <div className="p-1 flex flex-col justify-center border-b border-r border-primary category-cell">
                  <CategoryCell
                    rowInd={index}
                    validation={validationNum}
                    preCreateValidation={preCreateValidation}
                    createFlag={createFlag}
                    onShowDetail={setShowDetail}
                  />
                  <div className="cat">
                  <ShowDatumList
                    userId={user._id}
                    productId={rData.product.refId}
                    refId={rData.category.refId}
                    reference={rData.category}
                    rowInd={index}
                    type={RELATION.CATEGORY}
                    createFlag={createFlag}
                    themes={(() => {
                      // Get existing themes from the specific index in formData
                      const existingThemes = formData[index]?.themes ?? [];
                      
                      // Combine themes from dataATM and formData, deduplicating by the `theme` property
                      const combinedThemes = [
                        ...existingThemes,
                        ...(dataATM ?? []).filter(
                          atmTheme => !existingThemes.some(
                            existingTheme => existingTheme.theme === atmTheme.theme
                          )
                        ),
                      ];
                  
                      return combinedThemes;
                    })()} 
                    onShowDetail={setShowDetail}
                  />
                  </div>
                </div>
                <div key={index} className="flex flex-col justify-center p-1 border-b border-r border-primary madeby-cell">
                  {rData.madeby.map((ele, ind) => (
                    <div key={ind}>
                      <div
                        className={`my-2 mx-2 border-0 border-t-2 border-gray ${ind > 0 ? "visible" : "invisible"}`}
                      ></div>
                      <MadebyCell
                        rowInd={index}
                        colInd={ind}
                        validation={validationNum}
                        preCreateValidation={preCreateValidation}
                        createFlag={createFlag}
                        onShowDetail={setShowDetail}
                      />
                      <ShowDatumList
                        rowInd={index}
                        colInd={ind}
                        userId={user._id}
                        productId={rData.product.refId}
                        refId={ele.refId}
                        type={RELATION.MADEBY}
                        reference={ele}
                        createFlag={createFlag}
                        themes={(() => {
                          // Get existing themes from the specific index in formData
                          const existingThemes = formData[index]?.themes ?? [];
                          
                          // Combine themes from dataATM and formData, deduplicating by the `theme` property
                          const combinedThemes = [
                            ...existingThemes,
                            ...(dataATM ?? []).filter(
                              atmTheme => !existingThemes.some(
                                existingTheme => existingTheme.theme === atmTheme.theme
                              )
                            ),
                          ];
                      
                          return combinedThemes;
                        })()} 
                        onShowDetail={setShowDetail}
                      />
                    </div>
                  ))}
                  <div className="tool-cellButton-container">
                    <button className="button tool-buttons" onClick={() => addMadeBy(index)}>
                      + Add
                    </button>
                    {rData.madeby.length > 1 && (
                      <button
                        className="button-red tool-buttons"
                        onClick={() => onRemoveMadeby(index, rData.madeby.length - 1)}
                      >
                        Remove Last
                      </button>
                    )}
                  </div>
                </div>
                <div key={index} className="flex flex-col justify-center p-1 border-b border-r border-primary soldby-cell">
                  {rData.soldby.map((ele, ind) => (
                    <div key={ind}>
                      <div
                        className={`my-2 mx-2 border-0 border-t-2 border-gray ${ind > 0 ? "visible" : "invisible"}`}
                      ></div>
                      <SoldbyCell
                        index={ind}
                        rowInd={index}
                        colInd={ind}
                        validation={validationNum}
                        preCreateValidation={preCreateValidation}
                        createFlag={createFlag}
                        onShowDetail={setShowDetail}
                      />
                      <ShowDatumList
                        rowInd={index}
                        colInd={ind}
                        userId={user._id}
                        productId={rData.product.refId}
                        refId={ele.refId}
                        type={RELATION.SOLDBY}
                        reference={ele}
                        createFlag={createFlag}
                        themes={(() => {
                          // Get existing themes from the specific index in formData
                          const existingThemes = formData[index]?.themes ?? [];
                          
                          // Combine themes from dataATM and formData, deduplicating by the `theme` property
                          const combinedThemes = [
                            ...existingThemes,
                            ...(dataATM ?? []).filter(
                              atmTheme => !existingThemes.some(
                                existingTheme => existingTheme.theme === atmTheme.theme
                              )
                            ),
                          ];
                      
                          return combinedThemes;
                        })()} 
                        sources={sources[index] ?? []}
                        onShowDetail={setShowDetail}
                      />
                    </div>
                  ))}
                  <div className="tool-cellButton-container">

                    <button className="button tool-buttons" onClick={() => addSoldBy(index)}>
                      + Add
                    </button>
                    {rData.soldby.length > 1 && (
                      <button
                        className="button-red tool-buttons"
                        onClick={() => onRemoveSoldby(index, rData.soldby.length - 1)}
                      >
                        Remove Last
                      </button>
                    )}
                  </div>
                </div>
                <div key={index} className="flex flex-col justify-center border-b border-primary p-1 substitute-cell">
                  {rData.substitute.map((ele, ind) => (
                    <div key={ind}>
                      <div
                        className={`my-2 mx-2 border-0 border-t-2 border-gray ${ind > 0 ? "visible" : "invisible"}`}
                      ></div>
                      <SubstituteCell
                        key={ind}
                        rowInd={index}
                        colInd={ind}
                        validation={validationNum}
                        preCreateValidation={preCreateValidation}
                        createFlag={createFlag}
                        onShowDetail={setShowDetail}
                      />
                      <ShowDatumList
                        rowInd={index}
                        colInd={ind}
                        userId={user._id}
                        productId={rData.product.refId}
                        refId={ele.refId}
                        type={RELATION.SUBSTITUTE}
                        reference={ele}
                        createFlag={createFlag}
                        themes={(() => {
                          // Get existing themes from the specific index in formData
                          const existingThemes = formData[index]?.themes ?? [];
                          
                          // Combine themes from dataATM and formData, deduplicating by the `theme` property
                          const combinedThemes = [
                            ...existingThemes,
                            ...(dataATM ?? []).filter(
                              atmTheme => !existingThemes.some(
                                existingTheme => existingTheme.theme === atmTheme.theme
                              )
                            ),
                          ];
                      
                          return combinedThemes;
                        })()} 
                        onShowDetail={setShowDetail}
                      />
                    </div>
                  ))}
                  <div className="tool-cellButton-container">
                    <button className="button tool-buttons" onClick={() => addSubstitute(index)}>
                      + Add
                    </button>
                    {rData.substitute.length > 1 && (
                      <button
                        className="button-red tool-buttons"
                        onClick={() => onRemoveSubstitute(index, rData.substitute.length - 1)}
                      >
                        Remove Last
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="tool-bottom">
            <button className="button tool-buttons" onClick={onNewRow}>
              + New Row
            </button>
            {formData.length > 1 && (
              <button
                className="button-red tool-buttons"
                
                onClick={() => onRemoveRow(formData.length - 1)}
              >
                Remove Last Row
              </button>
            )}
          </div>
        </div>
      </div>
      <DetailViewModal open={showDetail} setOpen={setShowDetail} title={"Sample"} />
      <div className="Small-Screen text-center">This tool is only available on screen sizes larger than 1000px.</div>
    </div>
  );
};

export default Profile;
