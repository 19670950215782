const datumeditReducer = (
    state = { datumData: null, isOpen: false, isReload:false },
    action
) => {
    switch (action.type) {
        case "DATUM_MODAL_OPEN":
            return { ...state, isOpen: true };
        case "DATUM_MODAL_CLOSE":
            return { ...state, isOpen: false };
        case "DATUM_RELOAD":
            return { ...state, isReload: action.isReload };
        default:
            return state
    }
};

export default datumeditReducer;