import axios from 'axios';

import * as FeedbackAPI from '../api/FeedbackRequest';

export const fetch_datumfeedback = (datumID, userID) => async (dispatch) => {
    try{
        const {count, result} = await FeedbackAPI.fetch_datumfeedback(datumID, userID);
        
        // console.log('-------------------------------------------------------',count, result)
        dispatch({type: "FETCH_COUNT",count:count,result:result});
        
    }catch(error){
        console.error('Error fetching feedback:', error);
        dispatch({type: "FETCH FAIL"})
    }
    // dispatch({type: "DATUM_MODAL_OPEN", isOpen: true})
}
export const save_datumfeedback = (feedbackback_type,datumID,userID) => async(dispatch) => {
    // dispatch({type: "DATUM_MODAL_CLOSE", isOpen: false})
    try {
        // console.log('==================',datumID)
       await FeedbackAPI.save_datumfeedback(feedbackback_type,datumID,userID);
       const {count, result} = await FeedbackAPI.fetch_datumfeedback(datumID, userID);
        
        // console.log('-------------------------------------------------------',count, result)
        dispatch({type: "FETCH_COUNT",count:count,result:result});
       
    //    return 'success';
    } catch (error) {
        console.error('Error saving feedback:', error);
        // Optionally dispatch an action to handle the error
        dispatch({ type: "SAVE_FAIL" });
    }
}