import { useDispatch, useSelector } from 'react-redux';
import { getCategoryFeed } from '../../../../actions/ItemAction';
import './Category.css'
import React, { useState } from 'react'
import { generateItemTypeIcon } from '../../../../tools/itemTypeConversion';
import { ImLink } from 'react-icons/im';


const Category = ( {category} ) => {
    
    const {user} = useSelector((state)=>state.authReducer.authData);
    const {navPath} = useSelector((state)=> state.categoryFeedReducer);
    const serverPublic = process.env.REACT_APP_PUBLIC_FOLDER;
    const dispatch = useDispatch();
    
    const handleClick = () => {
        console.log(user._id, category._id, JSON.stringify(filtersObj))
        dispatch(getCategoryFeed(user._id, category._id, {itemId: category._id, itemName: category.itemName}, JSON.stringify(filtersObj)))
    }

    var adoptionIndex;
    const isInAdoptions = (() => {
        // This is throwing an error when viewing data in the modal for some reason.
        // I'm not sure if this is a good fix or not
        if (category.adoptions) {
            adoptionIndex = category.adoptions.indexOf(user._id);
            // console.log("Index: " + adoptionIndex);
        }
        return (
            <div className='arm-link-item-adoption FlexRow'>
                <div className='arm-link-item-adoption-index'>{adoptionIndex + 1}</div>
                <div className='arm-link-item-userImage'>
                    <img src={serverPublic + user.userImage} />
                </div>
            </div>
        )
    })()
    
    const filtersObj = {
        sort: null,
    }
    const [filters, setFilters] = useState(filtersObj);
  
    return (
    <div 
        className="Category FlexRow"
        onClick={handleClick}
    >
        <div className="arm-link-item-imageContainer">
            <img src={serverPublic + category.image} />
        </div>
        <div className='arm-link-item-infoContainer FlexColumn'>
            <div className="arm-link-item-nameBar FlexRow">
                <div className="arm-link-item-itemName">{category.itemName}</div>
                <div className='arm-link-item-itemIcon'>{generateItemTypeIcon(category.itemType)}</div>
            </div>
            <div className='arm-link-item-itemLinks FlexRow'>
                <ImLink/>
                {/* As with isInAdoptions above, reference.links was throwing an error for being undefined
                This seems to have worked */}
                {category.links && category.links.length}
                {adoptionIndex > -1 ? isInAdoptions : ''}
            </div>
        </div>
    </div>
  )
}

export default Category