import { useDispatch, useSelector } from 'react-redux'
import './UserFeed.css'
import React, { useEffect, useState } from 'react'
import { getUserFeed } from '../../../actions/UserFeedAction';
import UserDetail from '../../User/UserDetail';
import { generateValueList } from '../../../tools/generateValueList';

const UserFeed = ( {userFeedType}) => {
  
    const dispatch = useDispatch();
    // Can I combine the following 2 lines somehow?  
    const {user} = useSelector((state)=> state.authReducer.authData);
    const {loading: authDataLoading} = useSelector((state)=> state.authReducer);
    const {userFeed, loading: userFeedLoading} = useSelector((state)=> state.userFeedReducer);
    
    const filtersObj = {
        username: null,
        keyword: null,
        theme: null,
        subscribed: false,
        followed: false,
        value: null
    }
    const [filters, setFilters] = useState(filtersObj);

    useEffect (()=> {
        if (!authDataLoading) {
            dispatch(getUserFeed(user._id, null));
            const loadValueList = async() => {
                const output = await generateValueList("userFeedFilter");
                console.log(userFeedType);
                // Still trying to figure out what's going on here.  Getting null error for gvl_dropdown_container
                if(document.getElementById("gvl_dropdown_container")){
                    document.getElementById("gvl_dropdown_container").innerHTML = output;
                    document.getElementById("gvl_dropdown").addEventListener("change", (e)=>handleChange(e));
                }                    
            };
            user.userType !== 2 && loadValueList();
        }
    }, [authDataLoading])

    const handleChange = (e) => {
    // console.log(document.getElementById("subscribed").checked);
    setFilters({...filters, [e.target.name]: e.target.value})
    }

    const handleChecked = (e) => {
    setFilters({...filters, [e.target.name]: e.target.checked})
    }

    const handleSubmitFilters = (e) => {
    e.preventDefault();
    dispatch(getUserFeed(user._id, JSON.stringify(filters)))
    }

    return (
        <>
            <div className="General-filter-bar General-hidden" id="userFeed_filter_bar">
                {/* 1&2: FILTER By keyword */}
                {/* 1&2: FILTER By username */}
                {/* 1: SORT DEFAULT by Most Data authored */}
                {/* 1: SORT by most data stamped */}
                {/* 1: SORT by most items authored */}
                {/* 1: FILTER Unsubscribed (checkbox? checked by default?) */}
                {/* 2: Most stamped by default*/}
                {/* 2: By value */}
                
                {/* 2: FILTER Unfollowed (checkbox? checked by default?) */}
                <form action="" className='FlexColumn General-filter-form' onSubmit={handleSubmitFilters}>
                    <span className='FlexRow General-filter-form'>
                        <span className='General-filter-key'>Username:</span>
                        <input type="text" placeholder="Filter by user name..." name="username" className='General-input' onChange={handleChange}/>
                    </span>
                    <span className='FlexRow General-filter-form'>
                        <span className='General-filter-key'>Keyword:</span>
                        <input type="text" placeholder="Filter by keyword..." name="keyword" className='General-input' onChange={handleChange}/>
                    </span>
                    {userFeedType === 2 &&
                        <div className='FlexColumn General-filter-form'>
                            <span className='FlexRow General-filter-form'>
                                <span className='General-filter-key'>Value:</span>
                                <div className="gvl-dropdown-container" id="gvl_dropdown_container"></div>
                            </span>
                        </div>
                    }
                    {user.userType !== 0 
                        ? <div className='FlexColumn General-filter-form'>
                            <span className='FlexRow General-filter-form'>
                                <span className='General-filter-key'>Include Subscribed:</span>
                                <input id="subscribed" name="subscribed" type="checkbox" onClick={handleChecked}/>
                            </span>
                        </div>
                        : <div className='FlexColumn General-filter-form'>
                            <span className='FlexRow General-filter-form'>
                                <span className='General-filter-key'>Include Followed:</span>
                                <input id="followed" name="followed" type="checkbox" onClick={handleChecked}/>
                            </span>
                        </div>
                    }
                    
                    
                    <button className="button signupButton" type="submit" >
                        Apply Filters 
                    </button>    
                </form>
            </div>
            <div className='UserFeed'>
                {userFeed && userFeed.length > 0 
                ? userFeed.map((user, id) => {
                    return <UserDetail data={user} userKey={id} key={id}/>
                })
                : "No users match your filter criteria, or you're already following all available Groups.  Try adjusting your filters."
                }
            </div>
        </>
    
  )
}

export default UserFeed