const actionFeedReducer = (
    state = {actionFeed: null, loading: false, error: false}, 
    action
) => {
    switch(action.type) {

        case "ACTION_FEED_START":
            return {...state, loading: true, error: false}
        case "ACTION_FEED_SUCCESS":
            // Instead of an if statement, I wanted to input the action.data.action value as the key programmatically.
            if (state.actionFeed) {
                return {...state, actionFeed: {...state.actionFeed, [action.data.action]: action.data.data}, loading: false, error: false}
            } else {
                const initialObj = {
                    follow: [],
                    subscribe: [],
                    isSet: true
                }
                initialObj[action.data.action] = action.data.data;
                return {...state, actionFeed: initialObj, loading: false, error: false}
            }
            // This was working, but returned only the action keypair generated on initial load.  I want the other key generated too,
            // so that there's a placeholder to add users too in ACTION_FEED_ADJUST.
            // return {...state, actionFeed: {...state.actionFeed, [action.data.action]: action.data.data}, loading: false, error: false}
            
        case "ACTION_FEED_FAIL":
            return {...state, loading: false, error: true}
        case "ACTION_FEED_ADJUST":
            const userAction = action.data.action;
            // I think I need to add logic to create the new actionFeed 
            if (state.actionFeed[userAction].filter((obj)=>obj._id === action.data.user._id).length === 0) {
                // Not in actionFeed array - adding
                return{...state, actionFeed: {...state.actionFeed, [userAction]: [...state.actionFeed[userAction], action.data.user]}}
            } else {
                // In actionFeed array - removing
                return{...state, actionFeed: {...state.actionFeed, [userAction]: [...state.actionFeed[userAction].filter((obj)=>obj._id !== action.data.user._id)]}}
            }

        case "LOG_OUT":
            return {actionFeed: null, loading: false, error: false}
        
        default: 
            return state
    }
}

export default actionFeedReducer