import { sendLog } from "../api/LogsRequest";

export const logVisitorConnectionDetails = (userDetails) => {
    const options = {method: 'GET'};
    const fields = "&fields=ip_address,city,region,region_iso_code,postal_code,country,country_code,continent"
    
    if (userDetails) {
      const {activity, userId, username} = userDetails;
      var logDetails = {
          activity,
          data: {
              userId,
              username
          }
      }      
    } else {
        logDetails = {
          activity: "visit"
        }
    }
    
    fetch(process.env.REACT_APP_ABSTRACT_URL + fields, options)
    .then(response => {
      logDetails.response = response.status       
      return response.json();
    })
    .then(response => {
      if (logDetails.response !== 200) {
        logDetails.data = {...logDetails.data, ...response.error};
        logDetails.status = 1
      } else {
        logDetails.data = {...logDetails.data, ...response};
        logDetails.status = 0
      }
      sendLog(JSON.stringify(logDetails));
    })
    .catch(err => console.error(err));
  }