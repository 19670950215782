import React, { useEffect, useState } from 'react'
import './DatumList.css'
import { FiBox } from 'react-icons/fi'
import { TbCompass } from 'react-icons/tb'
import { BiHash, BiPen } from 'react-icons/bi'
import { AiOutlineBarChart } from 'react-icons/ai'
import { GiStamper } from 'react-icons/gi'
import { MdOutlineAssignmentTurnedIn } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { BsEye } from 'react-icons/bs'
import { loadModal } from '../../../../actions/ModalAction'
import { HiArrowsRightLeft } from 'react-icons/hi2'
import { generateRelationIcon } from '../../../../tools/generateRelationIcon'
import Relation from '../../CreateDatum/AddRelationModal/Relation/Relation'
import DraftModal from './DraftModal'

const DatumList = ({ data, id, datum_state, setMainContent, user: propUser }) => {

    // data.stamps.length > 0 &&
    const { user: reduxUser } = useSelector((state) => state.authReducer.authData)
    const cU = useSelector((state) => state.authReducer.authData)
    const user = propUser || reduxUser;
    const isOpen = useSelector((state) => state.datumeditReducer.isOpen)
    const [stamped, setStamped] = useState(data.stamps.includes(cU.user._id))
    const [stamps, setStamps] = useState(data.stamps.length)
    const [viewed, setViewed] = useState(data.views.includes(cU.user._id))
    const [views, setViews] = useState(data.views.length)

    const theme_arr = useSelector((state) => state.themeReducer.theme);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    useEffect(() => {
        if (isOpen == true) {

        }
        else {
            setIsModalOpen(false)
        }
    }, [isOpen])

    const userImgPath = process.env.REACT_APP_PUBLIC_FOLDER + data.authorImage;


    const datumContainerClass = () => {
        var classText = 'Datum';
        if (datum_state == 'draft') { classText = 'Datum-Draft' }
        if (data.itemTriggered) { classText += ' triggeredDatum' }
        if (viewed) { classText += ' viewedDatum' }
        if (stamped) { classText += ' stampedDatum' }
        if (user.userType === 0) { classText += ' viewedDatum' }

        return classText;
    }

    const createdDate = () => {
        const date = new Date(data.createdAt);
        return (date.toDateString());
    }

    const followedGroupsBar = () => {
        return (
            <div className="bottom-bar FlexRow">
                <div className="dl-followed-groups">
                    {data.stampDetails && data.stampDetails.map((stamp, id) => {
                        return <img key={id} src={process.env.REACT_APP_PUBLIC_FOLDER + stamp.groupImage} />
                    })}
                </div>
            </div>
        )
    }
    const authorThemeBar = () => {
        return (
            <div className="bottom-bar FlexRow">
                <div className="datumDetails">
                    <div className="generalBar authorBar">
                        {datum_state == 'draft' ?
                            ''
                            :
                            <>
                                <BiPen />
                                <img src={userImgPath} alt="" className='authorBarImg' />
                            </>
                        }
                        <span className="datumAuthor">
                            @{data.authorName}
                        </span>
                        <span className="timestamp">{createdDate()}</span>
                    </div>

                    {(data.themes.length > 0) && <div className="generalBar themesBar">
                        <BiHash />
                        <span className="themes">
                            {data.themes.map((theme, _id) => {
                                if (theme_arr && theme_arr[theme]) {
                                    return (
                                        <span className='theme'>
                                            <a href='#'>
                                                {theme_arr[theme]}&nbsp;&nbsp;
                                            </a>
                                        </span>
                                    )
                                } else if (data?.themes?.[_id]?.theme) {
                                    return (
                                        <span className='theme'>
                                            <a href='#'>
                                                {data.themes[_id].theme}&nbsp;&nbsp;
                                            </a>
                                        </span>
                                    )
                                }
                            })}
                        </span>
                    </div>}
                </div>
            </div>
        )
    }

    const getTriggers = () => {
        switch (user.userType) {
            case 0:
                let triggerCount = 0;
                for (let g = 0; g < data.stampDetails.length; g++) {
                    if (data.stampDetails[g].triggers) {
                        triggerCount += data.stampDetails[g].triggers.length;
                    }
                }
                return triggerCount;

            case 2:
                const stampIndex = user.stamps.findIndex((stamp) => stamp.datumId === data._id);
                if (stampIndex > -1) {
                    return user.stamps[stampIndex].triggers.length
                } else {
                    return 0;
                }
        }

    }
    const [triggers, setTriggers] = useState(getTriggers());

    const dispatch = useDispatch();
    const loadDatum = () => {

        if (datum_state == 'draft') {
            toggleModal()
            // console.log('-----------------', datum_state)
        } else
        // if (user.userType == 2) 
        {
            const modalPackage = {
                modalType: "datum",
                data: data,
                stamped: stamped,
                setStamped: setStamped,
                stamps: stamps,
                setStamps: setStamps,
                viewed: viewed,
                setViewed: setViewed,
                views: views,
                setViews: setViews,
                triggers: triggers,
                setTriggers: setTriggers,
                datumtype: datum_state,
                setMainContent: setMainContent,
            }

            const logDetails = {
                activity: "view_datum",
                data: {
                    userId: user._id,
                    username: user.username,
                    datumId: data._id,
                    datumText: data.datumText
                },
                response: null,
                status: null
            }

            dispatch(loadModal(modalPackage, logDetails));
        }
    }

    return (
        <>
            <div
                id={"datum_container_" + id}
                className={datumContainerClass()}
                onClick={loadDatum}
            >
                <div className="datumBody">
                    <div className="generalBar">
                        {/* <AiOutlineBarChart size="1rem"/> */}
                        {data.datumText !== '' && data.datumText !== null && <span className='datumText'>
                            {data.datumText}
                        </span>}
                    
                        {(data.datumText === '' || data.datumText === null) && <span className='dl-relations FlexColumn'>
                            {data.relations.map((r) => {
                                return (
                                    <div className="FlexRow dl-relationRow">
                                        <span className="dl-endIcon FlexRow">
                                            {generateRelationIcon(r.relation)}
                                        </span>
                                        <div className='dl-vl'></div>
                                        <span className="dl-relation">
                                            <Relation
                                                relation={r}
                                                references={data.references}
                                            />
                                        </span>
                                    </div>
                                )
                            })}

                        </span>}
                    </div>
                    <div className="generalBar attributes" >
                        <FiBox />
                        <span className="referenced">{data.references.length}</span>
                        <HiArrowsRightLeft />
                        <span className="relations">{data.relations.length}</span>
                        <MdOutlineAssignmentTurnedIn />
                        <span className="sources">{data.sources.length}</span>
                        <GiStamper style={stamped ? { color: 'var(--primaryColor)' } : { color: 'gray' }} />
                        <span className="stamps">
                            {stamps}
                        </span>
                        <BsEye style={viewed ? { color: 'var(--primaryColor)' } : { color: 'gray' }} />
                        <span className="views">
                            {views}
                        </span>
                        {user.userType !== 1
                            ? <>
                                <TbCompass className={triggers > 0 ? 'conflictIcon' : ''} />
                                <span className="conflictCount">{triggers ? triggers : "0"}</span>
                            </>
                            : ""
                        }

                        {/* Here I'll put the followed group stamp tally for general users */}
                    </div>
                </div>
                <hr />

                {user.userType === 0
                    ? followedGroupsBar()
                    : authorThemeBar()
                }


            </div>
            {isModalOpen &&
                <DraftModal
                    setMainContent={setMainContent}
                    onClose={toggleModal}
                    datum_i={data}
                    openFlag={isModalOpen}
                    ceFlag='2'
                    btn_flag='draft'
                    datum_state={datum_state}
                />}

        </>
    )
}

export default DatumList