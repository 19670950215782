const datumViewReducer = (
    state = { datumData: [] }, 
    action
)   => {
        switch(action.type) {
            case "DATUM_VIEW":
                return{...state, datumData: action.data};
            default: 
                return state
        }
    };

export default datumViewReducer;