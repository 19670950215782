import React, { useEffect, useState } from "react";
import { Spin, Button, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { TagOutlined } from '@ant-design/icons';
import { create_note } from "../../api/NotificationRequest";
import './CreateNote.css';
import { fetchNoteCount, delete_note } from "../../api/NotificationRequest";
import { setUnreadNoteCount } from "../../actions/notificationActions";
import { setNoteData } from "../../actions/notificationActions";

const CreateNote_content = ({ item_index, view_flag, title, content, read_flag, noteid, onDelete }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.authReducer.authData);
    const { TextArea } = Input;
    const [noteTitle, setNoteTitle] = useState('');
    const [noteContent, setNoteContent] = useState('');
    const [warning, setWarning] = useState({ title: false, content: false });
    const [messageApi, contextHolder] = message.useMessage();

    const count_unread_note = useSelector((state) => state.notificationReducer.noteCount);

    
    const fetchnote = async () => {
        try {
    
          const unread_note_arr = (await fetchNoteCount(user._id)).data;
          dispatch(setUnreadNoteCount(unread_note_arr));
    
        } catch (error) {
          console.error('Error fetching announcement:', error);
        }
      };

    useEffect(() => {
        setNoteTitle(title)
        setNoteContent(content)
    }, [item_index])

    const check_box = () => {

        if (noteTitle.trim() === '') {
            setWarning((prev) => ({ ...prev, title: true }));
        } else {
            setWarning((prev) => ({ ...prev, title: false }));
        }

        if (noteContent.trim() === '') {
            setWarning((prev) => ({ ...prev, content: true }));
        } else {
            setWarning((prev) => ({ ...prev, content: false }));
        }
    }

    const deletenote = async () => {
        await delete_note(noteid);
        onDelete(noteid, item_index); // Call the onDelete function to update the parent state
        setNoteTitle('')
        setNoteContent('')
    }

    const savenote = async () => {
        // Check if title or content is empty
        check_box();
        // If both title and content are valid, proceed to save the note
        if (noteTitle.trim() !== '' && noteContent.trim() !== '') {
            const res = await create_note(noteid, noteTitle, noteContent);
            // Handle the response from create_note if necessary

            messageApi.open({
                type: 'success',
                content: 'This is a success message',
            });
            setNoteTitle('')
            setNoteContent('')
            fetchnote();
        }
    };
    const onChangeTitle = (e) => {
        setNoteTitle(e.target.value);

    };

    const onChangeContent = (e) => {
        setNoteContent(e.target.value);
    };
    return (
        <>
            {contextHolder}
            <div className="nf-div">
                <Input
                    id="note_title"
                    size="large"
                    placeholder="Please write here the title of notification"
                    prefix={<TagOutlined />}
                    value={noteTitle}
                    style={{
                        fontSize: '16px', // Control font size
                        color: '#333', // Control text color
                        backgroundColor: view_flag == '2' ? '#f5f5f5' : '#fff', // Optional: change background color when disabled
                    }}
                    onChange={onChangeTitle}
                    className={warning.title ? 'warning-input' : ''}
                    disabled={view_flag == '2'}
                />
                {warning.title && <div className="warning-text">Title is required!</div>}
            </div>
            <div className="nf-div">
                <TextArea
                    id="note_txt"
                    showCount
                    size="middle"
                    maxLength={1000}
                    onChange={onChangeContent}
                    value={noteContent}
                    placeholder="Please write here the content of notification"
                    // style={{ height: 400, resize: 'none' }}
                    style={{
                        resize: 'none',
                        height: 400,
                        resize: 'none',
                        fontSize: '16px', // Control font size
                        color: '#333', // Control text color
                        backgroundColor: view_flag == '2' ? '#f5f5f5' : '#fff', // Optional: change background color when disabled
                    }}
                    className={warning.content ? 'warning-input' : ''}
                    disabled={view_flag == '2'}
                />
                {warning.content && <div className="warning-text">Content is required!</div>}
            </div>
            <div className="btn-div">
                {
                    item_index == 0 ?
                        view_flag == '1' && <Button type="primary" onClick={() => { savenote() }}>Announcement</Button>
                        :
                        view_flag == '1' && <Button type="primary" onClick={() => { savenote() }}>Change Notification</Button>
                }
                {item_index != 0 && view_flag == '1' && <Button type="primary" danger onClick={() => { deletenote() }}>Delete Notification</Button>}
            </div>
        </>
    );
};

export default CreateNote_content;