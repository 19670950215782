import { Element } from 'react-scroll';
import { fetchItemsAutocomplete } from '../../../api/ItemManipulation';
import Reference from '../CreateDatum/AddReferenceModal/Reference/Reference';
import './ItemSearch.css';
import React, { useState } from 'react'
import { BsSearch } from 'react-icons/bs';
import { useSelector } from 'react-redux';

const ItemSearch = () => {
    
  const {user} = useSelector((state)=>state.authReducer.authData);

  
  const BaseInstruction = () => {
    return (
      <>
        <span>Type the name of an item that you want to learn more about.</span>
        <span>It can be a person, business, product, or location.</span>    
      </>
    )
  }

  const InstructionContent = () => {
    switch (user.userType) {
      case 0:
        if (user.follow.length === 0) {
          return (
            <span className='General-validationError'>You aren't following any groups yet!  Check out the Browse Groups tab and start following some groups that align with your personal values.  Then come back and search for an item to learn more about it.</span>
          )     
        } else {
          return (
            <>
              <BaseInstruction />
              <span>You can only see data that your followed Groups have stamped.  The more Groups you follow, the more information you will have available to you.  To follow more Groups, head to the Browse Groups tab and find those that align with your personal values!</span>
            </>
        )}
      case 1:
        if (user.data.length === 0) {
          return (
            <span className='General-validationError'>You haven't authored any data yet!  There won't be any information about any of the items you try to view until you author data that reference the item.  Head over to the Create tabs to start authoring content!</span>
          )
        } else {
          return (
            <>
              <BaseInstruction />
              <span>When viewing an item, you will only see data that you have authored.  The more data you author, the more information you will see when viewing an item.</span>
            </>
          )
        }
      case 2:
        if (user.stamps.length === 0) {
          return (
            <span className='General-validationError'>You haven't stamped any data yet!  There won't be any information about any of the items you try to view until you stamp data.  Check out the Browse Data tab and start stamping data that your followers will find relevant.  Then come back and search for an item.</span>
          )
        } else {
          return (
            <>
              <BaseInstruction />
              <span>When viewing an item, you will only see data that you have stamped.  The more data you stamp, the more information you and your followers will see when viewing an item.</span>
            </>
        )}
      default: 
        break;
    }
  }
    
  const searchInstruction = () => {
          
    return (
      <div className='is-instruction FlexColumn'>
        {user && <InstructionContent/>}
      </div>
    )
  } 
  const [displayReferences, setDisplayReferences] = useState(searchInstruction);

  const autocomplete = async() => {
    const inputEl = document.getElementById("item_search_input");
    const value = inputEl.value;
    if (value.length > 2) {
      const res = await fetchItemsAutocomplete(value);
      const data = res.data;    
      data && setDisplayReferences(data.map((reference, _id) => {
          
          return (
            <Reference 
              reference={reference} 
              key={_id} 
              location="itemSearch"
              searchString={value}
            />
          )
        })
      )
    } else {
      setDisplayReferences(searchInstruction);
    }
  }

    return (
      <>
        <div className="ItemSearch FlexColumn"> 
            <div className="Search is-search FlexRow">
                <BsSearch/>
                <input id="item_search_input" onChange={autocomplete} className='is-input' name="itemSearch" type="text" placeholder="Find an item..." />
            </div>
            <div id="is_content" className="arm-content">{displayReferences}</div>
        <Element id='anchor_profile' name='anchor_profile' ></Element>
        </div>
      </>
    
      )
    }

export default ItemSearch