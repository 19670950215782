import React, { useEffect } from 'react'
import './Home.css';
import ProfileSide from '../../components/ProfileSide/ProfileSide';
import Main from '../../components/Main/Main';
import Header from '../../components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { Modal, useMantineTheme } from '@mantine/core';
import { closeModalAction } from '../../actions/ModalAction';
import DatumView from '../../components/Main/Feed/Datum/DatumView';
import UserView from '../../components/User/UserView';
import ItemView from '../../components/Main/Feed/Item/ItemView';
import LoadingSpinner from '../../components/Main/Feed/Item/LoadingSpinner';

import { fetch_theme } from '../../api/ItemManipulation';
import { setTheme } from '../../actions/ThmemAction';
import BackToTopButton from '../../components/Main/Button/BackToTopButton';
const Home = () => {

  const [opened, { open, close }] = useDisclosure(false);
  const theme = useMantineTheme();

  const dispatch = useDispatch();
  const { modalData, rModalOpened, loading } = useSelector((state) => state.modalReducer);

  useEffect(() => {
    open();
  }, [loading]);
  
  const init = async () => {
    const res = await fetch_theme();
    dispatch(setTheme(res.data))
  }
  useEffect(() => {
    init()
  }, [])



  useEffect(() => {
    if (rModalOpened === true) {
      // console.log("rModalOpened", rModalOpened);
      open();
    } else {
      close();
    }
  }, [rModalOpened])

  const closeModal = () => {
    // console.log("Inside closeModal")
    // console.log("rModalOpened", rModalOpened);
    dispatch(closeModalAction());
    close();
  }

  const modalSwitch = () => {
    if (loading) {
      return (<LoadingSpinner />);
    }
    switch (modalData.modalType) {
      case "datum":
        return (
          <DatumView
            data={modalData.data}
            stampedProp={modalData.stamped}
            setStampedProp={modalData.setStamped}
            stampsProp={modalData.stamps}
            setStampsProp={modalData.setStamps}
            viewedProp={modalData.viewed}
            setViewedProp={modalData.setViewed}
            viewsProp={modalData.views}
            setViewsProp={modalData.setViews}
            triggersProp={modalData.triggers}
            setTriggersProp={modalData.setTriggers}
            datumtype={modalData.datumtype}
            setMainContent= {modalData.setMainContent}
          />
        )
      case "user":
        return (
          <UserView
            user={modalData.data}
          />
        )

      case "item":
        return (
          <ItemView
            data={modalData.data}
          />
        )

      case "error":
        return (
          "An error occurred while loading.  Close this window and try again."
        )

      default: break;

    }
  }

  const modalSize = () => {
    const width = window.innerWidth;
    if (width < 650) {
      return width * 0.8;
    } else {
      return 575;
    }
  }


  return (

    <div className="Home HeaderGrid">
      <a href="#top" id="top"></a>
      <div className='home-header-container'>
        <Header location="home" />
      </div>
      <div className='BodyGrid'>
        <ProfileSide />
        <Main />
        {/* <TrendsSide/> */}
      </div>
      <BackToTopButton />

      <Modal
        opened={opened}
        onClose={closeModal}
        overlayProps={{
          color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
        size={modalSize()}
        withCloseButton={false}
      >
        {
        modalData && modalSwitch()}

      </Modal>

      
    </div>

  )
}

export default Home