import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, List, Skeleton, Modal } from 'antd';
import './NotificationBoard.css';
import { fetchAllNote, fetchNoteCount, makeReadNote } from '../../../api/NotificationRequest';
import { setNoteData, setUnreadNoteCount } from '../../../actions/notificationActions';
import { NotificationFilled, TagsFilled, LikeFilled } from '@ant-design/icons';
import { EyeOutlined, EyeInvisibleFilled } from '@ant-design/icons';
import { getDatum_One } from '../../../api/DatumManipulation';
import { getUser } from '../../../api/UserRequest';
import { getItemDetail } from '../../../api/ItemRequest';
import { useDisclosure } from '@mantine/hooks';
import { fetch_theme } from '../../../api/ItemManipulation';
import { setTheme } from '../../../actions/ThmemAction';
import { getDataFeed } from '../../../api/DataFeedRequest';
import DatumView from '../../Main/Feed/Datum/DatumView';
import { MdOutlineNotifications } from "react-icons/md";
import { RiFeedbackLine, RiProfileLine } from "react-icons/ri";
import { TbRubberStampOff } from "react-icons/tb";
import { FcApproval } from "react-icons/fc";
import { AiOutlineNotification } from "react-icons/ai";

const NotificationBoard = ({ tab_index, tab_key }) => {

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer.authData);
  const note_data = useSelector((state) => state.notificationReducer.noteData);
  const [note_arr, setNote_arr] = useState([]);
  const [notes, setNotes] = useState([]);
  const [initLoading, setInitLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const { modalData, rModalOpened } = useSelector((state) => state.modalReducer);


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const fetchNotes = async () => {
    try {
      const res = await fetchAllNote('all', user._id);
      dispatch(setNoteData(res.data));

      setInitLoading(false); // Set loading to false after fetching
    } catch (error) {
      console.error('Error fetching notes:', error);
      setInitLoading(false); // Also set loading to false in case of error
    }
  };
  const fetchnote = async () => {
    try {

      const unread_note_arr = (await fetchNoteCount(user._id)).data;
      dispatch(setUnreadNoteCount(unread_note_arr));

    } catch (error) {
      console.error('Error fetching announcement:', error);
    }
  };
  useEffect(() => {
    const temp_arr = [];
    if (note_data) {
      temp_arr[0] = note_data;
      for (let i = 1; i <= 5; i++) {
        temp_arr[i] = note_data.filter(note => note.note_type === i);
      }
      setNote_arr(temp_arr);
      setNotes(temp_arr[tab_index]);
    }
  }, [note_data])

  useEffect(() => {
    fetchNotes();
  }, []);

  const iconStyle = (flag) => {
    if (flag === 1) {
      return <AiOutlineNotification />;
    } else if (flag === 3) {
      return <RiFeedbackLine />;
    } else if (flag === 4) {
      return <TbRubberStampOff />;
    } else {
      return null;
    }
  };

  const titleStyle = (flag, title, feedback_obj, note_id) => {

    if (flag === 1) {
      return (<a href="#">{title}</a>);
    } else if (flag === 3 || flag === 4) {
      return (<a href="#" onClick={() => open_datum_modal(feedback_obj, note_id)}>{title}</a>);
    } else {
      return <a href="#">{title}</a>;
    }
  }

  const textStyle = (flag, title, feedback_obj, note_id, note_text) => {

    if (flag === 1) {
      return <div style={{ color: '#000000de' }} dangerouslySetInnerHTML={{ __html: note_text }}></div>;
    } else if (flag === 3 || flag === 4) {
      return <div style={{ color: '#000000de' }} onClick={() => open_datum_modal(feedback_obj, note_id)} dangerouslySetInnerHTML={{ __html: note_text }}></div>;
    } else {
      return <div style={{ color: '#000000de' }} dangerouslySetInnerHTML={{ __html: note_text }}></div>;
    }

  }
  const getTriggers = (datum) => {
    switch (user.userType) {
      case 0:
        let triggerCount = 0;
        for (let g = 0; g < datum.stampDetails.length; g++) {
          if (datum.stampDetails[g].triggers) {
            triggerCount += datum.stampDetails[g].triggers.length;
          }
        }
        return triggerCount;

      case 2:
        const stampIndex = user.stamps.findIndex((stamp) => stamp.datumId === datum._id);
        if (stampIndex > -1) {
          return user.stamps[stampIndex].triggers.length
        } else {
          return 0;
        }
    }

  }

  const [datum, setDatum] = useState();
  const [stamped, setStamped] = useState();
  const [stamps, setStamps] = useState();
  const [viewed, setViewed] = useState();
  const [views, setViews] = useState();
  const [triggers, setTriggers] = useState();

  const open_datum_modal = async (feedback_obj, note_id) => {
    const noteObject1 = JSON.parse(feedback_obj);
    // const response = await getDataFeed(noteObject1.datumID, noteObject1.datum_creator);
    const res = await getDatum_One(noteObject1.datumID);
    const logDetails = {
      userId: user._id,
      username: user.username
    }
    const encodedLogDetails = encodeURIComponent(JSON.stringify(logDetails));
    let authorDetails = await getUser(res.data.userId, encodedLogDetails);
    res.data.authorId = authorDetails.data._id;
    res.data.authorName = authorDetails.data.username;
    res.data.authorImage = authorDetails.data.userImage;
    for (let r = 0; r < res.data.references.length; r++) {
      let refId = res.data.references.shift();
      let referenceDetails = await getItemDetail(refId)
      res.data.references.push(referenceDetails.data);
    }
    const datum = res.data; // Access the data property
    // console.log('datumfeed one:',datum); // Log the full response
    if (datum) {

      // const datum = (await getDatum_One(noteObject1.datumID)).data;
      setDatum(datum);
      const stamped = datum.stamps.includes(user._id);
      setStamped(stamped);
      const stamps = datum.stamps.length;
      setStamps(stamps);
      const viewed = datum.views.includes(user._id);
      setViewed(viewed);
      const views = datum.views.length;
      setViews(views);
      const triggers = getTriggers(datum);
      setTriggers(triggers);
      setIsModalOpen(true);
    }
  }

  const changeDate = (time) => {
    const date = new Date(time);
    return (date.toDateString());
  }

  const makeread_note = async (noteid, userid) => {
    let res1;
    await makeReadNote(noteid, userid).then(
      setNotes(prevNotes =>
        prevNotes.map(note =>
          note._id === noteid ? { ...note, read: !note.read } : note
        )
      )
    );
    fetchnote();
  }

  return (
    <div className="notification-board">
      <List
        className="demo-loadmore-list"
        loading={initLoading}
        itemLayout={windowWidth > 700 ? "horizontal" : "vertical"}
        // dataSource={note_arr[tab_index]}
        dataSource={notes}
        renderItem={(note) => (
          // <List.Item
          //   actions={
          //     note.read ?
          //       [<EyeOutlined />, <div  className=' read-padding' >read</div>]
          //       :
          //       (
          //         [<EyeInvisibleFilled /> ,<a  onClick={() => { makeread_note(note._id, user._id) }}>unread</a>]
          //       )
          //   }
          // >
          //   <Skeleton avatar title={false} loading={note.loading} active>
          //     <List.Item.Meta
          //       avatar={iconStyle(note.note_type)}
          //       title={titleStyle(note.note_type, note.title, note.feedback_obj, note._id)}
          //       description={textStyle(note.note_type, note.title, note.feedback_obj, note._id,note.text)}
          //     />
          //     <div className='invisible-700' style={{ color: '#575757de' }}>{changeDate(note.updatedAt)}</div>
          //   </Skeleton>
          // </List.Item>

          <List.Item >
              <Skeleton avatar title={false} loading={note.loading} active>
                <List.Item.Meta
                  avatar={iconStyle(note.note_type)}
                  title={titleStyle(note.note_type, note.title, note.feedback_obj, note._id)}
                  description={textStyle(note.note_type, note.title, note.feedback_obj, note._id, note.text)}
                />
              </Skeleton>

              
                <div className="actions">
                  {note.read ? (
                    <>
                      <div style={{ color: '#575757de' }}>{changeDate(note.updatedAt)}</div>
                      <EyeOutlined className='read-panel' />
                      <div className='read-padding'>read</div>
                    </>
                  ) : (
                    <>
                      <div style={{ color: '#575757de' }}>{changeDate(note.updatedAt)}</div>
                      <EyeInvisibleFilled className='read-panel' />
                      <a onClick={() => { makeread_note(note._id, user._id) }}>unread</a>
                    </>
                  )}
                </div>
              
          </List.Item>
        )}
      />
      <Modal
        style={{ top: 70 }}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={null} // Hide the footer buttons
      >
        {
          <DatumView
            data={datum}
            stampedProp={stamped}
            stampsProp={stamps}
            viewedProp={viewed}
            viewsProp={views}
          />}
      </Modal>
    </div>
  );
};

export default NotificationBoard;