import { useDispatch, useSelector } from 'react-redux'
import './DatumView.css'

import React, { useEffect, useState } from 'react'
import { FiBox } from 'react-icons/fi'
import { VscReferences } from "react-icons/vsc";
import { LiaAcquisitionsIncorporated } from "react-icons/lia";
import { VscSourceControl } from "react-icons/vsc";
import { MdOutlineAssignmentTurnedIn, MdOutlineReportGmailerrorred } from 'react-icons/md'
import { GiTriggerHurt } from "react-icons/gi";
import { TbCirclesRelation } from "react-icons/tb";
import { GiStamper } from 'react-icons/gi'
import { BsEye } from 'react-icons/bs'
import { TbCompass } from 'react-icons/tb'
import { BiHash, BiPen } from 'react-icons/bi'
import { stampDatum, viewDatum } from '../../../../actions/DatumAction'
import { VscTypeHierarchy } from 'react-icons/vsc'
import Reference from '../../CreateDatum/AddReferenceModal/Reference/Reference'
import { MdOutlineNoAdultContent } from "react-icons/md";
import { fetchModalUser } from '../../../../actions/ModalAction'
import { Tabs } from '@mantine/core'
import { triggerItem } from '../../../../actions/ItemAction'
import { HiArrowsRightLeft } from 'react-icons/hi2'
import Relation from '../../CreateDatum/AddRelationModal/Relation/Relation'
import { DemoIds } from '../../../../Data/DemoIds';
import { generateRelationIcon } from '../../../../tools/generateRelationIcon'
import { Button } from 'react-scroll'
import { RiDeleteBin5Line, RiFeedbackFill, RiFeedbackLine } from "react-icons/ri";
import { TiEdit } from "react-icons/ti";
import { MdOutlineUnpublished } from "react-icons/md";
import { VscPreview } from "react-icons/vsc";
import { TbRubberStampOff } from "react-icons/tb";
import { submitDatum_update } from '../../../../actions/DatumAction'
import DraftModal from './DraftModal';
import { closeModalAction } from '../../../../actions/ModalAction'
import { datumEditModal_Open, datumReload } from '../../../../actions/datumeditAction'
import { enText } from '../../../../text/en'
import { Tooltip, Badge, Collapse, Card, Popconfirm } from 'antd';
import { save_datumfeedback, fetch_datumfeedback } from '../../../../actions/feedbackAction';
import { MdArrowForwardIos } from 'react-icons/md';
import { req_unstamp } from '../../../../api/DatumManipulation';
import { update_user_info } from '../../../../actions/UserAction';
import { VscNote } from "react-icons/vsc";


const DatumView = ({
    data,
    stampedProp,
    setStampedProp,
    stampsProp,
    setStampsProp,
    viewedProp,
    setViewedProp,
    viewsProp,
    setViewsProp,
    triggersProp,
    setTriggersProp,
    datumtype,
    setMainContent
}) => {

    const { user } = useSelector((state) => state.authReducer.authData);
    const count = useSelector((state) => state.feedbackReducer.count);
    const flag = useSelector((state) => state.feedbackReducer.flag);
    const userImgPath = process.env.REACT_APP_PUBLIC_FOLDER + data.authorImage;
    const dispatch = useDispatch();
    const [stamped, setStamped] = useState(stampedProp);
    const [stamps, setStamps] = useState(stampsProp);
    const [viewed, setViewed] = useState(viewedProp);
    const [views, setViews] = useState(viewsProp);
    const [triggers, setTriggers] = useState(triggersProp);

    const theme_arr = useSelector((state) => state.themeReducer.theme);

    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const toggleModal = () => {
    //     setIsModalOpen(!isModalOpen);
    // };


    ///////////////////feedback///////////////////////////////////////////////
    const FeedbackLabel = () => (
        <span className="flex FlexRow items-center w-full justify-between">
            <RiFeedbackFill className="mr-2 h-full font-lg" />
            <span>
                You can provide feedback on this data.
            </span>
        </span>
    );

    const feedback_drop = () => {
        return (
            <span>
                {
                    // key === 'error' &&
                    <span className={`mark-buttons FlexRow datum-feedback-item ${flag.error ? 'datum-feedback-click' : ''}`} onClick={() => { btn_feedback('error') }}>

                        <div id="btn_error" className={`flex FlexRow items-center  justify-between ${flag.error ? 'button-marked' : 'button-unmarked'}`} >
                            <Tooltip placement="left" title={`Error: ${enText.datumFeedback.error}`} >
                                <MdOutlineReportGmailerrorred className=" h-full font-lg" />
                            </Tooltip>
                        </div>
                        <span id="count_error">
                            <Badge count={count.error} color="#d551ca">                   </Badge>
                        </span>
                        Error: {enText.datumFeedback['error']}
                    </span>
                }
                {
                    // key === 'relation' &&
                    <span className={`mark-buttons FlexRow datum-feedback-item ${flag.relation ? 'datum-feedback-click' : ''}`} onClick={() => { btn_feedback('relation') }}>

                        <div id="btn_relation" className={`flex items-center justify-between ${flag.relation ? 'button-marked' : 'button-unmarked'}`} >
                            <Tooltip placement="left" title={`Relation: ${enText.datumFeedback.relation}`} >
                                <TbCirclesRelation className=" h-full  font-lg" />
                            </Tooltip>
                        </div>
                        <span id="count_relation">
                            <Badge count={count.relation} color="#d551ca">                   </Badge>
                        </span>
                        Relation: {enText.datumFeedback['relation']}
                    </span>
                }
                {
                    // key === 'reference' &&
                    <span className={`mark-buttons FlexRow datum-feedback-item ${flag.reference ? 'datum-feedback-click' : ''}`} onClick={() => { btn_feedback('reference') }}>

                        <div id="btn_reference" className={`flex items-center justify-between ${flag.reference ? 'button-marked' : 'button-unmarked'}`}>
                            <Tooltip placement="left" title={`Reference: ${enText.datumFeedback.reference}`}>
                                <VscReferences className="h-full font-lg" />
                            </Tooltip>
                        </div>
                        <span id="count_reference">
                            <Badge count={count.reference} color="#d551ca">                   </Badge>
                        </span>
                        Reference: {enText.datumFeedback['reference']}
                    </span>
                }
                {
                    // key === 'incorrect' &&
                    <span className={`mark-buttons FlexRow datum-feedback-item ${flag.incorrect ? 'datum-feedback-click' : ''}`} onClick={() => { btn_feedback('incorrect') }}>

                        <div id="btn_incorrect" className={`flex items-center  justify-between ${flag.incorrect ? 'button-marked' : 'button-unmarked'}`} >
                            <Tooltip placement="left" title={`Incorrect: ${enText.datumFeedback.incorrect}`} >
                                <LiaAcquisitionsIncorporated className=" h-full font-lg" />
                            </Tooltip>
                        </div>
                        <span id="count_incorrect">
                            <Badge count={count.incorrect} color="#d551ca">                   </Badge>
                        </span>
                        Incorrect: {enText.datumFeedback['incorrect']}
                    </span>
                }
                {
                    // key === 'trigger' &&
                    <span className={`mark-buttons FlexRow datum-feedback-item ${flag.trigger ? 'datum-feedback-click' : ''}`} onClick={() => { btn_feedback('trigger') }}>

                        <div id="btn_trigger" className={`flex items-center  justify-between ${flag.trigger ? 'button-marked' : 'button-unmarked'}`} >
                            <Tooltip placement="left" title={`Trigger: ${enText.datumFeedback.trigger}`} >
                                <GiTriggerHurt className=" h-full font-lg" />
                            </Tooltip>
                        </div>
                        <span id="count_trigger">
                            <Badge count={count.trigger} color="#d551ca">                   </Badge>
                        </span>
                        Trigger: {enText.datumFeedback['trigger']}
                    </span>
                }
                {
                    // key === 'source' &&
                    <span className={`mark-buttons FlexRow datum-feedback-item ${flag.source ? 'datum-feedback-click' : ''}`} onClick={() => { btn_feedback('source') }}>

                        <div id="btn_source" className={`flex items-center  justify-between ${flag.source ? 'button-marked' : 'button-unmarked'}`} >
                            <Tooltip placement="left" title={`Source: ${enText.datumFeedback.source}`} >
                                <VscSourceControl className=" h-full font-lg" />
                            </Tooltip>
                        </div>
                        <span id="count_source">
                            <Badge count={count.source} color="#d551ca">                   </Badge>
                        </span>
                        Source: {enText.datumFeedback['source']}
                    </span>
                }
                {
                    // key === 'inappropriate' &&
                    <span className={`mark-buttons FlexRow datum-feedback-item ${flag.inappropriate ? 'datum-feedback-click' : ''}`} onClick={() => { btn_feedback('inappropriate') }}>
                        <div id="btn_inappropriate" className={`flex items-center  justify-between ${flag.inappropriate ? 'button-marked' : 'button-unmarked'}`} >
                            <Tooltip placement="left" title={`Inappropriate: ${enText.datumFeedback.inappropriate}`} >
                                <MdOutlineNoAdultContent className=" h-full font-lg" />
                            </Tooltip>
                        </div>
                        <span id="count_inappropriate">
                            <Badge count={count.inappropriate} color="#d551ca">                   </Badge>
                        </span>
                        Inappropriate: {enText.datumFeedback['inappropriate']}
                    </span>
                }
            </span>
        );
    }
    /////////////////////////////////////////////////////////////////////

    const init = async () => {
        dispatch(fetch_datumfeedback(data._id, user._id));
        if (user.userType !== 1 && !document.getElementById("feedback_wrapper").classList.contains("Profile-card-collapsed")) {
            handleFeedbackCollapse();
        }
    }
    useEffect(() => {
        init()

    }, [])

    const datumContainerClass = () => {
        var classText = 'Datum';
        if (stamped) { classText += ' stampedDatum' }
        if (viewed) { classText += ' viewedDatum' }
        return classText;
    }

    const handleStamp = () => {
    
        setStamped && setStamped((prev) => !prev)
        setStampedProp && setStampedProp((prev) => !prev)
        if (stamped) {
            setStamps((prev) => prev - 1);
            setStampsProp && setStampsProp((prev) => prev - 1);
            for (let i = 0; i < data.references.length; i++) {
                if (document.getElementById("triggered_value_" + i)) {
                    console.log(document.getElementById("triggered_value_" + i));
                    document.getElementById("triggered_value_" + i).style.display = "none";
                    console.log(document.getElementById("triggered_value_" + i));
                }
            }
            setTriggers(0);
            setTriggersProp && setTriggersProp(0);
            // setTriggerDetails(null);
            // reset initial details object / state 
        } else {
            setStamps((prev) => prev + 1);
            setStampsProp && setStampsProp((prev) => prev + 1);
        }
        console.log(data._id);
        console.log(user._id);
        dispatch(stampDatum(data._id, user._id));
        handleView();
    }

    const handleView = () => {
        console.log("Inside handleView")
        if (viewed == false) {
            setViews((prev) => prev + 1);
            setViewsProp && setViewsProp((prev) => prev + 1);
            dispatch(viewDatum(data._id, user._id));
        }
        setViewed(true);
        setViewedProp && setViewedProp(true);
        // document.getElementById("datum_container_" + id).classList.add("viewedDatum");
        document.getElementById("datum_modal_view_button").disabled = true;
    }

    const createdDate = () => {
        const date = new Date(data.createdAt);
        return (date.toDateString());
    }

    const handleTrigger = (id) => {

        const el = document.getElementById("dv_dropdown_" + id);

        const stampIndex = user.stamps.findIndex((stamp) => stamp.datumId === data._id);

        console.log("Start Decode Block");
        console.log("Stamps Array:");
        console.log(user.stamps);
        console.log("Triggers Array:");
        console.log(user.stamps[stampIndex].triggers);
        if (user.stamps[stampIndex].triggers.findIndex((trig) => trig.item === data.references[id]._id) === -1) {
            document.getElementById("open_trigger_" + id).classList.toggle("conflictIcon");
            setTriggers((prev) => prev + 1);
            setTriggersProp && setTriggersProp((prev) => prev + 1);
        }
        dispatch(triggerItem(user._id, data._id, el.value, data.references[id]._id))
        const container = document.getElementById("triggered_value_" + id);
        container.innerHTML = "<div class='dv-triggered-value'>" + user.compass[el.value].value + "</div>";
    }

    const generateValueList = (triggerId) => {
        var output = '<select id="dv_dropdown_' + triggerId + '" class="dv-value-dropdown">' +
            '<option value="" selected disabled hidden>Choose the value triggered by this reference.</option>';
        for (let i = 0; i < user.compass.length; i++) {
            const newString = '<option value="' + i + '">' + user.compass[i].value + '</option>';
            output += newString;
        }
        output += '</select>'
        return output;
    }

    const toggleSetTrigger = (triggerId) => {

        const trigValContainer = document.getElementById("triggered_value_" + triggerId);

        // Original:
        if (trigValContainer.style.display === "none") {
            trigValContainer.style.display = "block";

            // - I'm almost certain this is horribly vulnerable to injection
            trigValContainer.innerHTML = generateValueList(triggerId);
            document.getElementById("dv_dropdown_" + triggerId).addEventListener("change", () => handleTrigger(triggerId));
        } else {
            if (!document.getElementById("dv_dropdown_" + triggerId)) {
                document.getElementById("open_trigger_" + triggerId).classList.toggle("conflictIcon");
                dispatch(triggerItem(user._id, data._id, null, data.references[triggerId]._id));
                // need to mirror what's going on (and currently isn't working) above with adding to trigger count and styling compass
                setTriggers((prev) => prev - 1);
                setTriggersProp && setTriggersProp((prev) => prev - 1);
            }
            trigValContainer.style.display = "none";
        }
    }

    const loadGroupTriggers = () => {
        const stampIndex = user.stamps.findIndex((stamp) => stamp.datumId === data._id);
        if (stampIndex > -1) {
            if (user.stamps[stampIndex].triggers) {
                const triggers = user.stamps[stampIndex].triggers
                for (let t = 0; t < triggers.length; t++) {
                    for (let r = 0; r < data.references.length; r++) {
                        // Check if the reference item is present in the triggers array
                        if (triggers[t].item === data.references[r]._id) {
                            const trigValContainer = document.getElementById("triggered_value_" + r);
                            trigValContainer.style.display = "block";
                            trigValContainer.innerHTML = "<div class='dv-triggered-value'>" + user.compass[triggers[t].value].value + "</div>";
                            const triggerIcon = document.getElementById("open_trigger_" + r);
                            if (triggerIcon) {
                                triggerIcon.classList.toggle("conflictIcon");
                            }
                        }
                    }
                }
            }
        }
    }

    const loadGeneralTriggers = () => {
        // Got the groups.triggers array loaded!  Now let's put it to work
        for (let r = 0; r < data.references.length; r++) {
            let markup = "<div class='dv-references'>";
            let count = 0;
            console.log("data: ", data);
            for (let g = 0; g < data.stampDetails.length; g++) {
                if (data.stampDetails[g].triggers) {
                    for (let t = 0; t < data.stampDetails[g].triggers.length; t++) {
                        if (data.stampDetails[g].triggers[t].item === data.references[r]._id) {
                            console.log("Inside triggered id = reference id");
                            markup += "<div class='FlexRow dv-reference'><div class='dv-group-img-container'><img src='" + process.env.REACT_APP_PUBLIC_FOLDER + data.stampDetails[g].groupImage + "'/></div>" +
                                "<div class='dv-triggered-value'>" + data.stampDetails[g].triggers[t].valueText + "</div></div>";
                            count++;
                        }
                    }
                }
            }
            markup += "</div>";
            if (count > 0) {
                const trigValContainer = document.getElementById("triggered_value_" + r);
                trigValContainer.style.display = "block";
                trigValContainer.innerHTML = markup;
            }
        }
    }

    // This variable setup was necessary for useEffect development re-rendering
    var triggersLoaded = false;
    useEffect(() => {

        // console.log("Inside UseEffect")
        // setTriggerDetails(initialTriggerDetails())
        const loadTriggersByUserType = () => {
            switch (user.userType) {
                case 0:
                    loadGeneralTriggers();
                    break;
                case 1:
                    break;
                case 2:
                    console.log("Inside Case 2");
                    loadGroupTriggers();
                    break;
            }
            triggersLoaded = true;
        }
        if (triggersLoaded === false) {
            loadTriggersByUserType();
        }
        // return () => loadTriggersByUserType();
    }, []);

    const loadUser = () => {
        const logDetails = {
            userId: user._id,
            username: user.username,
            parent_datumId: data._id,
            parent_datumText: data.datumText
        }
        dispatch(fetchModalUser(data.authorId, logDetails))
    }
    const btn_edit = () => {

        dispatch(datumEditModal_Open());
        dispatch(closeModalAction());
    }
    const btn_unpublish = () => {
        let pubdata = { ...data }; // Create a copy of data

        // Now you can safely add or modify properties
        pubdata = { ...pubdata, pubstate: 'unpublish' }; // Update pubdata with pubstate
        // console.log('edit datum:', pubdata)
        dispatch(submitDatum_update(pubdata));
        retrun_main()
        dispatch(closeModalAction());
    }
    const btn_delete = () => {
        let pubdata = { ...data }; // Create a copy of data

        // Now you can safely add or modify properties
        pubdata = { ...pubdata, pubstate: 'delete' }; // Update pubdata with pubstate
        // console.log('edit datum:', pubdata)
        dispatch(submitDatum_update(pubdata));
        retrun_main()
        dispatch(closeModalAction());
    }
    const btn_unstamp = async () => {
        //drop your code here umstamp datum
        // if(user.userType == 1){
        ////(here user.userType is always 1 )///////////////
        let update_uer_info = user;
        if (update_uer_info.request_unstamp && update_uer_info.request_unstamp.includes(data._id)) {
            // update_uer_info.request_unstamp.pull(data._id);
            update_uer_info.request_unstamp = update_uer_info.request_unstamp.filter(id => id !== data._id);
        } else {
            update_uer_info.request_unstamp.push(data._id);
        }
        // console.log("req_datum id:", data._id);
        await req_unstamp(data._id, user._id).then(
            dispatch(update_user_info(update_uer_info)),
        )
        // }else if(user.userType == 2){        }
        // dispatch(closeModalAction());
    }
    const isVisible_unstamp = () => {
        if (user.request_unstamp && user.request_unstamp.includes(data._id)) {
            if (user.userType !== 0) {
                console.log('length:', data.stamps.length)
                return (
                    <>
                        <TbRubberStampOff className='button-marked'
                        //  onClick={btn_unstamp}
                        />
                        <Badge count={data.stamps.length} offset={[-20, 0]}>
                        </Badge>
                    </>
                );
            }
        } else {
            return user.userType !== 0 && <TbRubberStampOff className='button-unmarked' onClick={btn_unstamp} />;
        }
        return '';
    }
    const btn_view = () => {
        dispatch(closeModalAction());
    }

    const prepareSourceMarkup = (source, index) => {
        // console.log("Preparing Source Markup with index: " + index);
        return (
            <div className='cd-asm-container FlexRow'>
                <a href={source.link.startsWith('http://') || source.link.startsWith('https://') ? source.link : `https://${source.link}`} 
                target="_blank"
                rel="noopener noreferrer">
                    <div className="cd-source-info">
                        <div className='cd-asm-text'>{source.text}</div>
                        <div className='cd-asm-link'>{source.link}</div>
                        <div className='cd-asm-description'>{source.description}</div>
                    </div>
                </a>
            </div>
        )
    }


    const retrun_main = () => {
        // setMainContent({ current: "c_datumview", previous: "dataFeed" })
        dispatch(datumReload(true));
    }


    const add_remove_css = (flag, feedtype) => {
        if (flag) {
            document.getElementById(`btn_${feedtype}`).classList.remove("button-marked");
            document.getElementById(`btn_${feedtype}`).classList.add("button-unmarked");
        } else {
            document.getElementById(`btn_${feedtype}`).classList.add("button-marked");
            document.getElementById(`btn_${feedtype}`).classList.remove("button-unmarked");
        }
    }

    const btn_feedback = (feedtype) => {
        dispatch(save_datumfeedback(feedtype, data._id, user._id));
        console.log('data index:', data._id, ', userid:', user._id);
    }

    const handleFeedbackCollapse = () => {
        document.getElementById("feedback_wrapper").classList.toggle("Profile-card-collapsed");
        document.getElementById("compass_expand_icon").classList.toggle("profile-contracted");
    }


    return (
        <div
            className={datumContainerClass()}
        >

            <div className="datumBody">
                <div className="generalBar ">
                    {/* <AiOutlineBarChart size="1rem"/> */}
                    {data.datumText !== '' && <span className='datumText'>
                        {data.datumText}
                    </span>}
                    {(data.datumText === '' || data.datumText === null) && <span className='dl-relations FlexColumn'>
                        {data.relations.map((r) => {
                            return (
                                <div className="FlexRow dl-relationRow">
                                    <span className="dl-endIcon FlexRow">
                                        {generateRelationIcon(r.relation)}
                                    </span>
                                    <div className='dl-vl'></div>
                                    <span className="dl-relation">
                                        <Relation
                                            relation={r}
                                            references={data.references}
                                        />
                                    </span>
                                </div>
                            )
                        })}

                    </span>}
                </div>
                <div className="generalBar attributes">
                    <FiBox />
                    <span className="referenced">{data.references.length}</span>
                    <HiArrowsRightLeft />
                    <span className="relations">{data.relations.length}</span>
                    <MdOutlineAssignmentTurnedIn />
                    <span className="sources">{data.sources.length}</span>
                    <GiStamper style={stamped ? { color: 'var(--primaryColor)' } : { color: 'gray' }} />
                    <span className="stamps">
                        {stamps}
                    </span>
                    <BsEye style={viewed ? { color: 'var(--primaryColor)' } : { color: 'gray' }} />
                    <span className="views">
                        {views}
                    </span>
                    {user.userType !== 1
                        ? <>
                            <TbCompass className={triggers > 0 ? 'conflictIcon' : ''} />
                            <span className="conflictCount">{triggers ? triggers : "0"}</span>
                        </>
                        : ""
                    }
                </div>
            </div>
            <hr />



            <Tabs radius="md" defaultValue="references">
                <Tabs.List grow>
                    <Tabs.Tab value="references" icon={<VscTypeHierarchy />}></Tabs.Tab>
                    <Tabs.Tab value="relations" icon={<HiArrowsRightLeft />}></Tabs.Tab>
                    <Tabs.Tab value="sources" icon={<MdOutlineAssignmentTurnedIn />}></Tabs.Tab>
                    <Tabs.Tab value="author" icon={<BiPen size="1rem" />}></Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="references" pt="xs">
                    <div className='FlexRow dv-references-container'>
                        <div id='dv_references' className='dv-references'>
                            {data.references.map((reference, _id) => {
                                // console.log(reference)
                                // console.log("id: " + _id)
                                const parentDatumObj = {
                                    parent_datumId: data._id,
                                    parent_datumText: data.datumText
                                }
                                if (reference != null) {

                                    return (
                                        <>
                                            <div className='FlexRow dv-reference' key={_id}>

                                                <Reference
                                                    reference={reference}
                                                    id={_id}
                                                    location="datumView"
                                                    parentDatumObj={parentDatumObj}
                                                />
                                                {user.userType === 2 && stamped === true
                                                    ? <TbCompass
                                                        onClick={() => toggleSetTrigger(_id)}
                                                        id={"open_trigger_" + _id}
                                                    /> :
                                                    ""
                                                }
                                            </div>
                                            <div
                                                className='dv-triggered-value-container'
                                                style={{ display: "none" }}
                                                id={"triggered_value_" + _id}
                                            ></div>
                                        </>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </Tabs.Panel>

                <Tabs.Panel value="relations" pt="xs">
                    <div className='FlexRow dv-relations-container'>
                        <div id='dv_relations' className='dv-relations'>
                            {data.relations.length > 0 ? data.relations.map((relation, _id) => {
                                return (
                                    <div className='FlexRow dv-relation' key={_id}>
                                        <Relation
                                            relation={relation}
                                            id={_id}
                                            references={data.references}
                                        />
                                    </div>
                                )
                            })
                                : "This datum does not have any relations."
                            }

                        </div>
                    </div>
                </Tabs.Panel>

                <Tabs.Panel value="sources" pt="xs">
                    <div className="dv-sources-container">
                        {data.sources.length == 0 ? "This datum does not have any sources attached." :
                            data.sources.map((source, index) => {
                                // console.log(source)
                                return (prepareSourceMarkup(source, index))
                            })}
                    </div>
                </Tabs.Panel>

                <Tabs.Panel value="author" pt="xs">
                    <div className="bottom-bar FlexRow">
                        <div className="datumDetails">
                            <div className="generalBar authorBar" onClick={loadUser}>
                                <BiPen />
                                <img src={userImgPath} alt="" className='authorBarImg' />
                                @{data.authorName}
                                <span className="timestamp">{createdDate()}</span>
                            </div>
                            {(data.themes.length > 0) &&
                                <div className="generalBar themesBar">
                                    <BiHash />
                                    <span className="themes">
                                        {data.themes.map((trend, id) => {
                                            if (theme_arr && theme_arr[trend]) {
                                                return (
                                                    <span className='theme'>
                                                        <a href='#'>
                                                            {theme_arr[trend]}&nbsp;&nbsp;
                                                        </a>
                                                    </span>
                                                )
                                            } else if (data?.themes?.[id]?.theme) {
                                                return (
                                                    <span className='theme'>
                                                        <a href='#'>
                                                            {data.themes[id].theme}&nbsp;&nbsp;
                                                        </a>
                                                    </span>
                                                )
                                            }
                                        })}

                                    </span>

                                </div>}
                            <span className="datumAuthor">
                                {/* @ID:{data.authorName} */}
                                @ ID: {data._id}
                            </span>

                        </div>

                    </div>
                    <div className='datum-feedback'>
                        {
                            // user.userType !== 1 && <Collapse ghost items={items} />
                            user.userType !== 1 &&
                            <div className="AppGeneralCard">
                                <div id="feedback_wrapper" className="Compass-wrapper dropdown-header">
                                    <div className={`generalBar feedback-tool-header dropdown-bar  header-tip`} onClick={handleFeedbackCollapse} >
                                        &nbsp;
                                        <div style={{ padding: '4px' }} className={`flex feedback-icon FlexRow items-center  justify-between ${Object.values(flag).some(value => value)
                                            ?
                                            'button-marked'
                                            :
                                            'button-unmarked'
                                            }`}>
                                            {/* <RiFeedbackLine className="h-full font-md"  style={{ height:'23px', width: '23px' }} /> */}
                                            <VscNote className="h-full font-md" style={{ height: '23px', width: '23px' }} />
                                        </div>
                                        &nbsp;&nbsp;
                                        <span className='pointText'>
                                            {
                                                'Leave feedback for this datum'
                                            }
                                        </span>
                                        <span id="compass_expand_icon" className="profile-expand-icon"><MdArrowForwardIos /></span>
                                    </div>
                                    <div className="compassData">
                                        {feedback_drop()}
                                    </div>

                                </div>

                            </div>
                        }
                        {
                            user.userType === 1 &&
                            <>
                                {
                                    count.error > 0 &&
                                    <Badge.Ribbon text={count.error} color="purple" >
                                        <Card title="" size="small" className='df-bg'>
                                            <span className={`mark-buttons FlexRow datum-feedback-item-view`} >
                                                <div id="btn_error" className={`flex FlexRow items-center justify-between button-unmarked`} >
                                                    <Tooltip placement="left" title={`Error: ${enText.datumFeedback.error}`} >
                                                        <MdOutlineReportGmailerrorred className="h-full font-lg" />
                                                    </Tooltip>
                                                </div>
                                                Error: {enText.datumFeedback['error']}
                                            </span>
                                        </Card>
                                    </Badge.Ribbon>
                                }
                                {
                                    count.relation > 0 &&
                                    <Badge.Ribbon text={count.relation} color="purple" >
                                        <Card title="" size="small" className='df-bg'>
                                            <span className={`mark-buttons FlexRow datum-feedback-item-view`} >
                                                <div id="btn_error" className={`flex FlexRow items-center justify-between button-unmarked`} >
                                                    <Tooltip placement="left" title={`Relation: ${enText.datumFeedback.relation}`} >
                                                        <TbCirclesRelation className=" h-full font-lg" />
                                                    </Tooltip>
                                                </div>
                                                Relation: {enText.datumFeedback['relation']}
                                            </span>
                                        </Card>
                                    </Badge.Ribbon>
                                }
                                {
                                    count.reference > 0 &&
                                    <Badge.Ribbon text={count.reference} color="purple" >
                                        <Card title="" size="small" className='df-bg'>
                                            <span className={`mark-buttons FlexRow datum-feedback-item-view`} >
                                                <div id="btn_error" className={`flex FlexRow items-center justify-between button-unmarked`} >
                                                    <Tooltip placement="left" title={`Reference: ${enText.datumFeedback.reference}`} >
                                                        <VscReferences className=" h-full font-lg" />
                                                    </Tooltip>
                                                </div>
                                                Reference: {enText.datumFeedback['reference']}
                                            </span>
                                        </Card>
                                    </Badge.Ribbon>
                                }
                                {
                                    count.incorrect > 0 &&
                                    <Badge.Ribbon text={count.incorrect} color="purple" >
                                        <Card title="" size="small" className='df-bg'>
                                            <span className={`mark-buttons FlexRow datum-feedback-item-view`} >
                                                <div id="btn_error" className={`flex FlexRow items-center justify-between button-unmarked`} >
                                                    <Tooltip placement="left" title={`Incorrect: ${enText.datumFeedback.incorrect}`} >
                                                        <LiaAcquisitionsIncorporated className=" h-full font-lg" />
                                                    </Tooltip>
                                                </div>
                                                Incorrect: {enText.datumFeedback['incorrect']}
                                            </span>
                                        </Card>
                                    </Badge.Ribbon>
                                }
                                {
                                    count.trigger > 0 &&
                                    <Badge.Ribbon text={count.trigger} color="purple" >
                                        <Card title="" size="small" className='df-bg'>
                                            <span className={`mark-buttons FlexRow datum-feedback-item-view`} >
                                                <div id="btn_error" className={`flex FlexRow items-center justify-between button-unmarked`} >
                                                    <Tooltip placement="left" title={`Trigger: ${enText.datumFeedback.trigger}`} >
                                                        <GiTriggerHurt className=" h-full font-lg" />
                                                    </Tooltip>
                                                </div>
                                                Trigger: {enText.datumFeedback['trigger']}
                                            </span>
                                        </Card>
                                    </Badge.Ribbon>
                                }
                                {
                                    count.source > 0 &&
                                    <Badge.Ribbon text={count.source} color="purple" >
                                        <Card title="" size="small" className='df-bg'>
                                            <span className={`mark-buttons FlexRow datum-feedback-item-view`} >
                                                <div id="btn_error" className={`flex FlexRow items-center justify-between button-unmarked`} >
                                                    <Tooltip placement="left" title={`Source: ${enText.datumFeedback.source}`} >
                                                        <VscSourceControl className=" h-full font-lg" />
                                                    </Tooltip>
                                                </div>
                                                Source: {enText.datumFeedback['source']}
                                            </span>
                                        </Card>
                                    </Badge.Ribbon>
                                }
                                {
                                    count.inappropriate > 0 &&
                                    <Badge.Ribbon text={count.inappropriate} color="purple" >
                                        <Card title="" size="small" className='df-bg'>
                                            <span className={`mark-buttons FlexRow datum-feedback-item-view`} >
                                                <div id="btn_error" className={`flex FlexRow items-center  justify-between button-unmarked`} >
                                                    <Tooltip placement="left" title={`Inappropriate: ${enText.datumFeedback.inappropriate}`} >
                                                        <MdOutlineNoAdultContent className=" h-full font-lg" />
                                                    </Tooltip>
                                                </div>
                                                Inappropriate: {enText.datumFeedback['inappropriate']}
                                            </span>
                                        </Card>
                                    </Badge.Ribbon>
                                }
                            </>
                        }
                    </div>
                </Tabs.Panel>
            </Tabs>

            <hr />
            <div className="dv-bottom-bar FlexRow">

                {user.userType === 2
                    ? <div className="mark-buttons FlexRow">
                        <GiStamper id={"datum_modal_stamp_button"} className={stamped ? 'button-marked' : 'button-unmarked'} onClick={handleStamp} />
                        <BsEye id={"datum_modal_view_button"} className={viewed ? 'button-marked' : 'button-unmarked'} onClick={handleView} />
                    </div>
                    :
                    <>
                        <div className='mark-buttons FlexRow'>
                            {user.userType !== 2 && user._id === data.authorId &&
                                // datumtype === 'pubdatum' &&
                                <>
                                    {/* <button className='item' onClick={btn_view}><VscPreview />View</button> */}
                                    {data.stamps.length === 0 ?
                                        <>
                                            <TiEdit className='button-unmarked' onClick={btn_edit} />
                                            <MdOutlineUnpublished className='button-unmarked' onClick={btn_unpublish} />
                                        </>
                                        :
                                        isVisible_unstamp()
                                    }
                                    {data.stamps.length === 0 &&
                                        <Popconfirm
                                            title="Delete the Datum"
                                            description="Are you sure to delete this Datum?"
                                            okText="Yes"
                                            cancelText="No"
                                            onConfirm={btn_delete} // Handle the confirmation
                                        //  onCancel={}   // Handle the cancellation
                                        >
                                            {/* <Button danger>Delete</Button> */}
                                            <RiDeleteBin5Line className='button-unmarked' />
                                        </Popconfirm>
                                    }
                                </>
                            }
                        </div>
                    </>
                }
                <span style={{ width: 30 + 'em' }}></span>

            </div>
            {/* {isModalOpen && <DraftModal setMainContent={setMainContent} onClose={toggleModal} datum_i={data} ceFlag='2' btn_flag='public' datum_state={datumtype} />} */}
        </div>

    )
}

export default DatumView