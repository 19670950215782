import './AddReferenceModal.css'
import React, { useState } from 'react'
import { LiaTimesSolid } from 'react-icons/lia'
import { fetchItemsAutocomplete } from '../../../../api/ItemManipulation'
import Reference from './Reference/Reference'

const AddReferenceModal = ({ setOpenARM, dataARM, setDataARM }) => {

  const [displayReferences, setDisplayReferences] = useState(null);

  const clearARM = () => {
    document.getElementById("itemSearchARM").value = null;
    setDisplayReferences(null);
  }

  const autocomplete = async () => {
    // CATCH SERVER ERRORS HERE!!!!

    // I think at some point I'll want to eliminate fetching the entire links and adoptions arrays,
    // both for privacy and efficiency.  Instead, I can just return a length value for links and 
    // a length and index number for user (if applicable) for adoptions
    const inputEl = document.getElementById("itemSearchARM");
    const value = inputEl.value;
    // console.log(value);
    if (value.length > 2) {

      try {
        const res = await fetchItemsAutocomplete(value);
        // console.log(res);

        const data = res.data;
        // console.log(data);

        data && setDisplayReferences(
          data.map((reference, id) => {
            for (var i = 0; i < dataARM.length; i++) {
              if (dataARM[i]._id == reference._id) {
                return null;
              }
            }
            if (reference.toString() != 'null') {

              return (
                <Reference
                  reference={reference}
                  id={id}
                  key={id}
                  dataARM={dataARM}
                  setDataARM={setDataARM}
                  clearARM={clearARM}
                  loaded={false}
                  location="arm"
                />
              )
            }
          })
        )
      } catch (error) {
        const contentEl = document.getElementById("arm_content");
        contentEl.innerText = "A server error has occurred.  Please refresh the page.  If the error persists, please try again later."
        contentEl.classList.add("validationError")
      }
    } else {
      setDisplayReferences(null);
    }
  }

  return (

    <div className="AddReferenceModal FlexColumn">

      <div className="arm-header FlexRow">
        <span className="arm-header-text">Link Item</span>
        <span className='arm-x' onClick={() => setOpenARM(false)}>
          <LiaTimesSolid />
        </span>
      </div>
      <div className="Search arm-search">

        <input id="itemSearchARM" onChange={autocomplete} name="itemSearchARM" type="text" placeholder="Find an item..." />

      </div>
      <div id="arm_content" className="arm-content">{displayReferences}</div>
    </div>

  )
}

export default AddReferenceModal