import * as DataFeedApi from '../api/DataFeedRequest'

export const getDataFeed = (id, filters)=> async(dispatch)=> {
    // console.log("Inside getDataFeed")
    dispatch({type: "DATA_FEED_START"})
    try {
        // console.log("Inside DataFeedAction try block")
        const { data } = await DataFeedApi.getDataFeed(id, filters);
        dispatch({type: "DATA_FEED_SUCCESS", data: data});
        
    } catch (error) {
        dispatch({type: "DATA_FEED_FAIL"})
        console.log(error);
    }
}