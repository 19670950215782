import "./MainBar.css";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineBarChart } from "react-icons/ai";
import { VscTypeHierarchy, VscTools } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { generateUserTypeIconOnly } from "../../../tools/userTypeConversion";
import { BiEditAlt, BiFilterAlt } from "react-icons/bi";
import { TbCategory2 } from "react-icons/tb";


const MainBar = ({ mainContent, setMainContent, userFeedType, userFeedText, userType }) => {
  const { loading: dataFeedLoading } = useSelector((state) => state.dataFeedReducer);
  const { loading: userFeedLoading } = useSelector((state) => state.userFeedReducer);
  const { loading: itemFeedLoading } = useSelector((state) => state.itemFeedReducer);
  // const { loading: datumViewLoading } = useSelector((state) => state.datumViewReducer);
  const { loading: categoryFeedLoading } = useSelector((state) => state.categoryFeedReducer);

  useEffect(() => {
    const e1 = document.getElementById(mainContent.current);
    
    if (mainContent.previous !== mainContent.current) {
      const e2 = document.getElementById(mainContent.previous);
      e2.classList.remove("activeCard");
      mainContent.previous = mainContent.current;
    }
    e1.classList.add("activeCard");
  }, [mainContent]);

  const handleMainFilter = () => {
    console.log(mainContent.current);
    document.getElementById(mainContent.current + "_filter_bar").classList.toggle("General-hidden");
  };

  return (
    <div className="MainBar">
      <div
        id="itemFeed"
        className="mb-div activeCard"
        onClick={() => setMainContent({ current: "itemFeed", previous: mainContent.previous })}
      >
        {itemFeedLoading ? (
          <div className={itemFeedLoading ? "General-loading-circle" : ""}></div>
        ) : (
          <div className="mb-icon-container FlexRow">
            <VscTypeHierarchy />
          </div>
        )}
        <span>Browse Items</span>
      </div>
      <div
        id="categoryBrowse"
        className="mb-div"
        onClick={() =>
          setMainContent({ current: "categoryBrowse", previous: mainContent.previous })
        }
      >
        {categoryFeedLoading ? (
          <div className={categoryFeedLoading ? "General-loading-circle" : ""}></div>
        ) : (
          <div className="mb-icon-container FlexRow">
            <TbCategory2 />
          </div>
        )}
        <span>Browse By Category</span>
      </div>
      <div
        id="dataFeed"
        className="mb-div"
        onClick={() => setMainContent({ current: "dataFeed", previous: mainContent.previous })}
      >
        {dataFeedLoading ? (
          <div className={dataFeedLoading ? "General-loading-circle" : ""}></div>
        ) : (
          <div className="mb-icon-container FlexRow">
            <AiOutlineBarChart />
          </div>
        )}
        <span>Browse Data</span>
      </div>
      <div
        id="userFeed"
        className="mb-div"
        onClick={() => setMainContent({ current: "userFeed", previous: mainContent.previous })}
      >
        {userFeedLoading ? (
          <div className={userFeedLoading ? "General-loading-circle" : ""}></div>
        ) : (
          <div className="mb-icon-container FlexRow">{generateUserTypeIconOnly(userFeedType)}</div>
        )}
        <span>{`Find ${userFeedText}s`}</span>
      </div>
      {userType === 1 && (
        <div className="mb-create-container FlexRow">
          <div
            id="c_datumview"
            className="mb-div"
            onClick={() =>
              setMainContent({ current: "c_datumview", previous: mainContent.previous })
            }
          >
            <div className="mb-icon-container FlexRow">
              <BiEditAlt />
            </div>
            <span>Draft Data</span>
          </div>
          <div
            id="c_item"
            className="mb-div"
            onClick={() => setMainContent({ current: "c_item", previous: mainContent.previous })}
          >
            <VscTypeHierarchy />
            <span>Create Item</span>
          </div>
          <div
            id="c_datum"
            className="mb-div"
            onClick={() => setMainContent({ current: "c_datum", previous: mainContent.previous })}
          >
            <AiOutlineBarChart />
            <span>Create Datum</span>
          </div>
          <div className="mb-div ">
            <Link
              to={"/tool"}
              className="flex items-center gap-2"
              style={{ fontFamily: "Arial", fontSize: "13px" }}
            >
              <VscTools />
              <span> Tools </span>
            </Link>
          </div>
        </div>
      )}
      <BiFilterAlt
        className={mainContent.current.slice(0, 2) === "c_" ? "General-hidden" : "mb-filter-icon"}
        onClick={handleMainFilter}
      />
    </div>
  );
};
export default MainBar;
