const dataFeedReducer = (
    state = {dataFeed: [], loading: false, error: false}, 
    action
) => {
    switch(action.type) {
        case "DATA_FEED_START":
            return {...state, loading: true, error: false}
        case "DATA_FEED_SUCCESS":
            // console.log(action.data);
            return {...state, dataFeed: action.data, loading: false, error: false}
        case "DATA_FEED_FAIL":
            return {...state, loading: false, error: true}
        case "STAMP_DATUM":
            // What if the datum isn't in feed?  What if it was navigated to through the modal?
            const datumIndex = state.dataFeed.findIndex((datum)=>datum._id===action.data.datumId);
            if (datumIndex !== -1) {
                // Fires only if datum is present in the feed
                const userIndex = state.dataFeed[datumIndex].stamps.findIndex((user)=>user===action.data.userId);
                if (userIndex === -1) {
                    return {...state, dataFeed: [...state.dataFeed.map((datum, i) => i === datumIndex ? {...datum, stamps: [...state.dataFeed[i].stamps, action.data.userId]} : datum )] }
                } else {
                    return {...state, dataFeed: [...state.dataFeed.map((datum, i) => i === datumIndex ? {...datum, stamps: [...state.dataFeed[i].stamps.filter((id)=>id!==action.data.userId)]} : datum )]}
                }
            }
            /* falls through */
        case "VIEW_DATUM":
            const datumViewIndex = state.dataFeed.findIndex((datum)=>datum._id===action.data.datumId);
            // const userIndex = state.dataFeed[datumIndex].views.findIndex((user)=>user===action.data.userId);
            return {...state, dataFeed: [...state.dataFeed.map((datum, i) => i === datumViewIndex ? {...datum, views: [...state.dataFeed[i].views, action.data.userId]} : datum )] }
            
        case "LOG_OUT":
            return {dataFeed: null, loading: false, error: false}

        default: 
            return state
    }
}

export default dataFeedReducer