import { useEffect, useState } from "react";
import { getDatumRelation } from "../../api/formRequest";
import { TbCategory2 } from "react-icons/tb";
import { IoHammerOutline } from "react-icons/io5";
import { MdOutlineDataObject, MdOutlineSell } from "react-icons/md";
import { TbReplace } from "react-icons/tb";
import { GiStamper } from "react-icons/gi";
import { BsTrash, BsEye } from "react-icons/bs";
import { RELATION } from "../../const";
import './DatumView.css'
export default function DatumView({ productId, secondId, datumId, type, onRemove, onDetail }) {
  const [data, setData] = useState(null);
  const serverPublic = process.env.REACT_APP_PUBLIC_FOLDER;
  const [funcView, setFuncView] = useState(false)
  useEffect(() => {
    // if (productId == "" || secondId == "" || datumId == "") return;
    const getData = async () => {
      const temp = await getDatumRelation(productId, secondId, datumId);
      setData(temp.data);
    };

    // console.log(productId, secondId, datumId);

    if(productId != '-1' && secondId != "-1")
    {
      getData();
    }
  }, [productId, secondId, datumId]);
  const RelationIcon = () => {
    if (type == RELATION.CATEGORY) {
      return <TbCategory2 className="text-middle text-orange-500" />;
    } else if (type == RELATION.MADEBY) {
      return <IoHammerOutline className="text-middle text-orange-500" />;
    } else if (type == RELATION.SOLDBY) {
      return <MdOutlineSell className="text-middle text-orange-500" />;
    } else {
      return <TbReplace className="text-middle text-orange-500" />;
    }
  };
  const ImageShow = () => {
    if(!data) return;
    let r_image1, r_image2, r_relation;
    if (type == RELATION.CATEGORY) {
      r_relation = <TbCategory2 className="text-middle text-orange-500" />;
      r_image1 = data.references[0].image;
      r_image2 = data.references[1].image;
    } else if (type == RELATION.MADEBY) {
      r_relation = <IoHammerOutline className="text-middle text-orange-500" />;
      r_image1 = data.references[1].image;
      r_image2 = data.references[0].image;
    } else if (type == RELATION.SOLDBY) {
      r_relation = <MdOutlineSell className="text-middle text-orange-500" />;
      r_image1 = data.references[1].image;
      r_image2 = data.references[0].image;
    } else {
      r_relation = <TbReplace className="text-middle text-orange-500" />;
      r_image1 = data.references[0].image;
      r_image2 = data.references[1].image;
    }
    return (
      <>
        <img className="w-4 h-4 bg-cover" src={serverPublic + r_image1} />
        <div className="flex flex-col text-center items-center text-small">
          {/* <RelationIcon /> */}
          {r_relation}
          {type}
        </div>
        <img className="w-4 h-4 bg-cover" src={serverPublic + r_image2} />
      </>
    )
  };
  return (
    <>
      {data != null ? (
        <div className="tool-datumView-container" style={{ "position": "relative" }} onClick={() => setFuncView(!funcView)}>
          <div className={`relation-item-delete ${funcView ? "relation-item-show-delete" : ""}`} >
            <BsTrash size={30} onClick={() => onRemove()} />
          </div>
          <div className={`relation-item-view ${funcView ? "relation-item-show-view" : ""}`}>
            <BsEye size={30} onClick={() => onDetail()} />
          </div>
          <div className="tool-datumView-relation">
            <ImageShow />
            {/* <img className="w-4 h-4 bg-cover" src={serverPublic + data.references[0].image} />
            <div className="flex flex-col text-center items-center text-small">
              <RelationIcon />
              {type}
            </div>
            <img className="w-4 h-4 bg-cover" src={serverPublic + data.references[1].image} /> */}
          </div>
          <div className="tool-datumView-stat">
            <div className="flex gap-1 items-center">
              <GiStamper />
              <span>:&nbsp;{data&&data.stamps.length}</span>
            </div>
          </div>
          <div className="tool-datumView-stat">
            <div className="flex gap-1 items-center">
              <MdOutlineDataObject />
              <span>:&nbsp;{data._id}</span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
