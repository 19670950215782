import * as AuthApi from '../api/AuthRequest'
import { logVisitorConnectionDetails } from '../tools/visitorConnection'

export const logIn = (formData) => async(dispatch) => {
    
    dispatch({type: "AUTH_START"})
    try {
        const {data} = await AuthApi.logIn(formData)
        logVisitorConnectionDetails({activity: "login_connection", userId: data.user._id, username: data.user.username})
        dispatch({type: "AUTH_SUCCESS", data: data})
    } catch (error) {
        console.log(error);
        dispatch({type: "AUTH_FAIL", status: error})
    }
}

export const signUp = (formData) => async(dispatch) => {
    
    dispatch({type: "AUTH_START"})
    try {
        const {data} = await AuthApi.signUp(formData)
        logVisitorConnectionDetails({activity: "login_connection", userId: data.user._id, username: data.user.username})
        dispatch({type: "AUTH_SUCCESS", data: data})
    } catch (error) {
        console.log(error)
        dispatch({type: "AUTH_FAIL", status: error})
    }
}

export const updatePass = (formData) => async(dispatch) => {
    
    dispatch({type: "AUTH_UPDATE_PASS_START"})
    try {
        const {data} = await AuthApi.updatePass(formData)
        dispatch({type: "AUTH_UPDATE_PASS_SUCCESS", data: data})
    } catch (error) {
        console.log(error)
        console.log(error.response.status);
        dispatch({type: "AUTH_UPDATE_PASS_FAIL", status: error.response.status})
    }
}

export const refreshAuthData = (userData) => async(dispatch) => {
    
    dispatch({type: "AUTH_REFRESH_START"})
    try {
        console.log("Inside try")
        const {data} = await AuthApi.refreshAuthData(userData)
        console.log("Past api call")
        dispatch({type: "AUTH_REFRESH_SUCCESS", data: data})
        console.log("Past success")
    } catch (error) {
        console.log("Error")
        
        console.log(error)
        // console.log(error.response.status);
        dispatch({type: "AUTH_REFRESH_FAIL", status: error.response.status})
    }
}

export const addUserSheet = (sheetData) => async(dispatch) => {
    dispatch({type: "ON_SHEET_ADD", data: sheetData})
}

export const clearAuthError = () => async(dispatch) => {
    
    dispatch({type: "AUTH_CLEAR_ERROR"})
}

export const logOut = () => async(dispatch) => {
    dispatch({type: "LOG_OUT"})
}