import { useDispatch, useSelector } from 'react-redux';
import './ItemFeed.css'
import React, { useEffect, useState } from 'react'
import Reference from '../CreateDatum/AddReferenceModal/Reference/Reference';
import { getItemFeed } from '../../../actions/ItemAction';

const ItemFeed = ({ sortOrder, user: propUser }) => {

    const dispatch = useDispatch();
    const { user: reduxUser } = useSelector((state) => state.authReducer.authData);
    const { loading: authDataLoading } = useSelector((state) => state.authReducer);
    const { itemFeed } = useSelector((state) => state.itemFeedReducer);
    const user = propUser || reduxUser;

    const filtersObj = {
        sort: sortOrder

    }
    const [filters, setFilters] = useState(filtersObj);


    useEffect(() => {
        if (!authDataLoading) {
            dispatch(getItemFeed(user._id, null));
        }

    }, [authDataLoading])

    const handleChange = (e) => {
        // console.log(document.getElementById("subscribed").checked);
        setFilters({ ...filters, [e.target.name]: e.target.value })
    }

    const handleChecked = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.checked })
    }

    const handleSubmitFilters = (e) => {
        e.preventDefault();
        dispatch(getItemFeed(user._id, JSON.stringify(filters)))
    }
    const sortedItems = () => {
        if (!Array.isArray(itemFeed)) return [];  // Check if itemFeed is empty or undefined
    
        return [...itemFeed].sort((a, b) => {
            // Ensure a and b are valid objects and have 'links' property (even if it's an empty array)
            const aLinksLength = a && Array.isArray(a.links) ? a.links.length : 0;
            const bLinksLength = b && Array.isArray(b.links) ? b.links.length : 0;
    
            // Sort by number of links if the sortOrder is 'mostReferenced'
            if (sortOrder === 'mostReferenced') {
                return bLinksLength - aLinksLength;  // Sort by length of links array, descending
            } 
            // Sort by 'createdAt' if the sortOrder is 'recentlyAuthored'
            else if (sortOrder === 'recentlyAuthored') {
                // Ensure createdAt is a valid date
                const aDate = a && a.createdAt ? new Date(a.createdAt) : new Date(0);  // Fallback to epoch if no createdAt
                const bDate = b && b.createdAt ? new Date(b.createdAt) : new Date(0);  // Fallback to epoch if no createdAt
                return bDate - aDate;  // Sort by createdAt, descending
            }
            
            return 0;  // Default: no sorting
        });
    };
    


    return (
        <>
            
            <div className="General-filter-bar General-hidden" id="itemFeed_filter_bar">
                {/* {These all came from UserFeed filters - change as necessary for this component} */}
                {/* 1&2: FILTER By keyword */}
                {/* 1&2: FILTER By username */}
                {/* 1: SORT DEFAULT by Most Data authored */}
                {/* 1: SORT by most data stamped */}
                {/* 1: SORT by most items authored */}
                {/* 1: FILTER Unsubscribed (checkbox? checked by default?) */}
                {/* 2: Most stamped by default*/}
                {/* 2: By value */}

                {/* 2: FILTER Unfollowed (checkbox? checked by default?) */}
                <form action="" className='FlexColumn General-filter-form' onSubmit={handleSubmitFilters}>
                    <span className='FlexRow General-filter-form'>
                        <span className='General-filter-key'>Username:</span>
                        <input type="text" placeholder="Filter by user name..." name="username" className='General-input' onChange={handleChange} />
                    </span>
                    <span className='FlexRow General-filter-form'>
                        <span className='General-filter-key'>Keyword:</span>
                        <input type="text" placeholder="Filter by keyword..." name="keyword" className='General-input' onChange={handleChange} />
                    </span>

                    <div className='FlexColumn General-filter-form'>
                        <span className='FlexRow General-filter-form'>
                            <span className='General-filter-key'>Value:</span>
                            <div className="gvl-dropdown-container" id="gvl_dropdown_container"></div>
                        </span>
                    </div>

                    {user.userType !== 0
                        ? <div className='FlexColumn General-filter-form'>
                            <span className='FlexRow General-filter-form'>
                                <span className='General-filter-key'>Include Subscribed:</span>
                                <input id="subscribed" name="subscribed" type="checkbox" onClick={handleChecked} />
                            </span>
                        </div>
                        : <div className='FlexColumn General-filter-form'>
                            <span className='FlexRow General-filter-form'>
                                <span className='General-filter-key'>Include Followed:</span>
                                <input id="followed" name="followed" type="checkbox" onClick={handleChecked} />
                            </span>
                        </div>
                    }

                    <button className="button signupButton" type="submit" >
                        Apply Filters
                    </button>
                </form>
            </div>
            <div className="ItemFeed">
                {sortedItems().length > 0 ? (
                    sortedItems().map((item, _id) => (
                        <Reference reference={item} key={_id} location="itemList" />
                    ))
                ) : (
                    "No items message"
                )}
            </div>
        </>

    )
}

export default ItemFeed