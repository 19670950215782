import { useDispatch, useSelector } from "react-redux"
import { getDatum } from "../../../../actions/DatumAction"
import { useEffect, useState } from "react"
import './Datumdraft.css'
import { Col, Divider, Row, Space, Empty } from 'antd';
import DatumList from "../Datum/DatumList"
import { datumReload } from "../../../../actions/datumeditAction";

const Datumdraft = ({ datumstate, setMainContent, btn_flag }) => {
    const { user } = useSelector((state) => state.authReducer.authData);
    const datumData = useSelector((state) => state.datumViewReducer.datumData)


    // const [firstflag, setFirstflag] = useState('0')
    const [selectItem, setSelectItem] = useState(null)
    const [selectDatum, setSelectDatum] = useState(null)
    const dispatch = useDispatch()

    const isReload = useSelector((state) => state.datumeditReducer.isReload);


    useEffect(() => {
        console.log('isReload:', isReload);
        if(isReload == true){
          dispatch(getDatum(user._id, datumstate));
          dispatch(datumReload(false));
        }
      },[isReload])

    const filtersObj = {
        group: null,
        authorName: null,
        keyword: null,
        subscribed: false,
        followed: false,
        theme: null,
        reference: null,
        // datumstatus: null,
    }
    const [filters, setFilters] = useState(filtersObj);

    const data_arr = datumData.data
    // console.log('datumstate:',datumstate)
    useEffect(() => {
        setSelectItem(null)
        setSelectDatum(null)
        // dispatch(getDatum(user._id, "draft"))
        dispatch(getDatum(user._id, datumstate))
    }, [datumstate])



    const item_click = (item, index) => {
        setSelectItem(index)
        setSelectDatum(item)
    }

    return (
        <>
            <div class="container">
                <div style={{ height: '100%', width: '100%' }}>
                    {/* <div style={{ flex: '0 0 40%', overflow: 'auto'}}> */}
                    {data_arr && data_arr.length > 0
                        ? data_arr.map((datum, id) => {
                            return (
                                <>
                                    <div className="space-align-container">
                                        <div className="space-align-block" style={{ padding: '5px' }}>
                                            {/* <Space align="center" > */}
                                            <DatumList setMainContent={setMainContent} data={datum} id={datum._id} key={datum._id} datum_state='draft' />
                                            {/* </Space> */}
                                        </div>
                                    </div>
                                </>
                            );
                        })
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }

                </div>

            </div>
        </>
    )

}
export default Datumdraft