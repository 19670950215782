import React, { useState } from "react";
import "./Reference.css";
import { BsTrash, BsEye } from "react-icons/bs";
import { ImLink } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { fetchModalItem } from "../../../../../actions/ModalAction";
import { generateItemTypeIcon } from "../../../../../tools/itemTypeConversion";

// This component is used in DataView and CreateDatum

const Reference = ({
  reference,
  id,
  location,
  dataARM,
  setDataARM,
  clearARM,
  dataARelM,
  setDataARelM,
  loaded,
  setDisplayReferences,
  setSelectedReference,
  parentDatumObj,
  searchString,
  onViewDatum,
  btn_flag,
}) => {
  const { user } = useSelector((state) => state.authReducer.authData);
  const serverPublic = process.env.REACT_APP_PUBLIC_FOLDER;
  const dispatch = useDispatch();
  const [isDelete, setIsDelete] = useState(false);
  const [bUserType, setBUserType] = useState(
    reference && reference.itemReference && reference.itemReference.includes("reference;")
  );
  if (reference == null) return <></>;
  const idMod = () => {
    var output = "";
    if (loaded) {
      output += "l_";
    }
    output += reference._id;
    return output;
  };
  const refId = idMod();
  var adoptionIndex;
  const isInAdoptions = (() => {
    // This is throwing an error when viewing data in the modal for some reason.
    // I'm not sure if this is a good fix or not
    if (reference.adoptions) {
      adoptionIndex = reference.adoptions.indexOf(user._id);
      // console.log("Index: " + adoptionIndex);
    }
    return (
      <div className="arm-link-item-adoption FlexRow">
        <div className="arm-link-item-adoption-index">{adoptionIndex + 1}</div>
        <div className="arm-link-item-userImage">
          <img
            src={serverPublic + user.userImage}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "fallback-image-url";
            }}
          />
        </div>
      </div>
    );
  })();

  const handleClick = () => {
    switch (location) {
      case "datumView":
      case "itemSearch":
      case "itemList":
        loadItem();
        break;
      case "formSelect":
        toggleFormDeleteButton();
        break;
      case "fromReference":
        if (setDisplayReferences) setDisplayReferences();
        break;
      case "arm":
        // if(btn_flag == 'publish'){
        if (loaded) {
          toggleDeleteButton();
        } else {
          selectItem();
        }
        // }
        break;
      case "dataFeedFilter":
        if (loaded) {
          toggleDeleteButton();
        } else {
          setDisplayReferences(
            <span className="FlexRow General-filter-selected">
              <Reference
                reference={reference}
                id={id}
                key={id}
                location="dataFeedFilter"
                loaded={true}
                setDisplayReferences={setDisplayReferences}
              />
            </span>
          );
        }
        break;
    }
  };

  const selectItem = () => {
    setDataARM(() => {
      return [...dataARM, reference];
    });
    clearARM();
  };
  const toggleFormDeleteButton = () => {
    // document.getElementById(`arm_delete_${refId}`).classList.toggle("arm-link-item-show-delete");
    /*     const eleId = `arm_delete_${refId}${id}`
    console.log(eleId)
    const element = document.getElementById(eleId)
    console.log(element)
    document.getElementById(`arm_delete_${refId}${id}`).classList.toggle("arm-link-item-show-delete"); */
    setIsDelete(!isDelete);
  };

  const toggleDeleteButton = () => {
    btn_flag != "publish" &&
      document.getElementById("arm_delete_" + refId).classList.toggle("arm-link-item-show-delete");
  };

  const removeReference = () => {
    switch (location) {
      case "formSelect":
        setSelectedReference();
        break;
      case "arm":
        setDataARelM(() => {
          return [...dataARelM.filter((rel) => dataARM[id]._id !== rel.ref1 && dataARM[id]._id !== rel.ref2)];
        });
        setDataARM(() => {
          return [...dataARM.slice(0, id), ...dataARM.slice(id + 1)];
        });
        break;
      case "dataFeedFilter":
        setDisplayReferences(null);
        break;
    }
  };

  const loadItem = () => {
    var logObject = {};
    if (location === "datumView") {
      logObject.location = "datum";
      logObject = { ...logObject, ...parentDatumObj };
    }
    if (location === "itemSearch") {
      logObject.location = "search";
      logObject.search = searchString;
    }
    dispatch(fetchModalItem(reference._id, user._id, logObject));
  };

  const onClickDetail = () => {
    if ( location == "formSelect" && onViewDatum) {
      onViewDatum()
    }
  }
  return (
    <div
      id={"arm_reference_" + refId}
      className={
        location === "datumView"
          ? "Reference FlexRow normal-reference"
          : adoptionIndex > -1
            ? "Reference FlexRow green-reference"
            : "Reference FlexRow"
      }
      style={{ borderColor: bUserType ? "orange" : "", borderWidth: 1, borderStyle: "solid" }}
      onClick={handleClick}
    >
      <div
          id={"arm_delete_" + refId + `${location === "formSelect" ? id : ""}`}
          className={`arm-link-item-delete ${isDelete ? "arm-link-item-show-delete" : ""}`}
        >
          <BsTrash size={30} onClick={removeReference} />
        </div>
      {location == "formSelect" && reference?._id && (
        <div
          className={`arm-link-item-view ${isDelete ? "arm-link-item-show-view" : ""}`}
        >
          <BsEye size={30} onClick={() => onClickDetail()} />
        </div>
      )}
      <div className="arm-link-item-imageContainer">
        <img src={serverPublic + reference.image} />
      </div>
      <div className="arm-link-item-infoContainer FlexColumn">
        <div className="arm-link-item-nameBar FlexRow">
          <div className="arm-link-item-itemName">{reference.itemName}</div>
          <div className="arm-link-item-itemIcon">{generateItemTypeIcon(reference.itemType)}</div>
        </div>
        <div className="arm-link-item-itemLinks FlexRow">
          <ImLink />
          {/* As with isInAdoptions above, reference.links was throwing an error for being undefined
                This seems to have worked */}
          {reference.links && reference.links.length}
          {adoptionIndex > -1 ? isInAdoptions : ""}
        </div>
      </div>
    </div>
    // Do I want anything else displayed in the DatumView version?  Any other stats?
  );
};

export default Reference;
