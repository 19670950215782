import axios from "axios"

const API = axios.create({baseURL: process.env.REACT_APP_API_BASE_URL})

export const stampDatum = (id, userId) => API.put(`datum/${id}/stamp`, {userId: userId});
export const viewDatum = (id, userId) => API.put(`datum/${id}/view`, {userId: userId});
export const getDatum = (userId, state) => API.get(`datum/getDatum/${userId}/${state}`);
export const dbUpdate = (up) => API.get(`datum/dbupdate/${up}`);
export const getDatum_One = (datumid) => API.get(`datum/${datumid}`,{datumid:datumid});
// export const getItemByID = (id) => API.get(`datum/getItemById/${id}`);
export const unstamp_datum = (datumID) => API.put(`datum/unstamp_datum/${datumID}`,{datumID:datumID});
export const req_unstamp = (datum_id, user_id) => API.put(`datum/req_unstamp/${datum_id}/${user_id}`);
