import React, { useEffect, useState } from 'react'
import './Compass.css'
import { TbCompass } from 'react-icons/tb'
import { useSelector } from 'react-redux'
import Value from './Value/Value'
import { IoSettingsOutline } from 'react-icons/io5'
import { MdArrowForwardIos } from 'react-icons/md'

const Compass = ( { profileContent, setProfileContent }) => {

    const {user} = useSelector((state) => state.authReducer.authData);

    const handleCompassCardCollapse = () => {
        document.getElementById("compass_data").classList.toggle("General-hidden");
        document.getElementById("compass_expand_icon").classList.toggle("profile-contracted");
      }

    useEffect(()=>{
        if (!document.getElementById("compass_data").classList.contains("General-hidden")) {
            handleCompassCardCollapse();
        }
    }, profileContent)

  return (
    <div className="AppGeneralCard">
        <div id="compass_wrapper" className="Compass-wrapper">
            <div className="generalBar">
                <TbCompass/>
                <span className='pointText'>
                    My Compass
                </span>
                <span id="compass_expand_icon" className="profile-expand-icon"><MdArrowForwardIos onClick={handleCompassCardCollapse} /></span>
                
                {user.userType !== 1 && user.isAdmin && <IoSettingsOutline className='compass-settings-icon' onClick={()=>setProfileContent("compassSettings")}/>}
            </div>
            <div id="compass_data" className="compassData">
                {user.compass.length > 0 
                    ?   user.compass.map((value, id) => {
                            return <Value data={value} location="myCompass" key={id} /> 
                        })
                    :   (user.userType === 2 
                        ? <div>
                            You don't have any values set.  
                            Click below to set your Compass.
                        </div>
                        : <div>
                            You don't have any values set.  
                            Follow a Group to start adding values.
                        </div>
                        )
                }
            </div>
            {user.userType === 2 
                ?   <>
                        <hr/>
                        <div className="AppGeneralEditLine" onClick={()=>setProfileContent("setCompass")}>
                            Set Compass
                        </div> 
                    </>
                : ""
            }
        </div>

    </div>
  )
}

export default Compass