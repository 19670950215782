import React, { useEffect, useState } from 'react';
import './DataFeed.css';
import { useDispatch, useSelector } from 'react-redux';
import { getDataFeed } from '../../../actions/DataFeedAction';
import DatumList from './Datum/DatumList';
import { fetchThemesAutocomplete } from '../../../api/ThemeManipulation';
import { AiOutlineBarChart } from 'react-icons/ai';
import { LiaTimesSolid } from 'react-icons/lia';
import DraftModal from '../../../components/Main/Feed/Datum/DraftModal';
import { datumReload } from '../../../actions/datumeditAction';

const DataFeed = ({ setMainContent, sortOrder, user: propUser }) => {
  const dispatch = useDispatch();
  const { user: reduxUser } = useSelector((state) => state.authReducer.authData);
  const { loading: authDataLoading } = useSelector((state) => state.authReducer);
  const { dataFeed, loading: dataFeedLoading } = useSelector((state) => state.dataFeedReducer);
  const [displayThemes, setDisplayThemes] = useState(null);
  const isReload = useSelector((state) => state.datumeditReducer.isReload);
  const { modalData } = useSelector((state) => state.modalReducer);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isOpen = useSelector((state) => state.datumeditReducer.isOpen);
  const user = propUser || reduxUser;

  useEffect(() => {
    if (isOpen === true) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [isOpen]);

  const filtersObj = {
    group: null,
    authorName: null,
    keyword: null,
    subscribed: false,
    followed: false,
    theme: null,
    reference: null,
    includeStamped: false,
    includeViewed: false,
    includeUnsubscribedAuthors: false,
    subscribedAuthorsOnly: true,
    sortBy: sortOrder,
  };
  
  const [filters, setFilters] = useState(filtersObj);

  // Fetch data based on sortOrder (either 'stamped' or 'recent')
  useEffect(() => {
    dispatch(getDataFeed(user._id, JSON.stringify({ ...filters, sortOrder })));
  }, [sortOrder, filters, user._id, dispatch]);

  useEffect(() => {
    if (isReload === true) {
      dispatch(getDataFeed(user._id, null));
      dispatch(datumReload(false));
    }
  }, [isReload]);

  useEffect(() => {
    if (!authDataLoading) {
      dispatch(getDataFeed(user._id, null));
    }
  }, [authDataLoading]);

  const themeAutocomplete = async () => {
    const inputEl = document.getElementById('themeSearch');
    const value = inputEl.value;
    if (value.length > 2) {
      const res = await fetchThemesAutocomplete(value);
      const data = res.data;
      setDisplayThemes(
        data?.map((theme) => (
          <div className='atm-result' onClick={() => selectTheme(theme)}>
            <div className='atm-result-theme'>{theme.theme}</div>
            <div className='atm-result-stats'>
              <AiOutlineBarChart />
              {theme.data.length}
            </div>
          </div>
        ))
      );
    } else {
      setDisplayThemes(null);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const selectTheme = (theme) => {
    setDisplayThemes(
      <span className='FlexRow'>
        <div className='df-selected-theme'>
          <div className='atm-result-theme'>{theme.theme}</div>
          <div className='atm-result-stats'>
            <AiOutlineBarChart />
            {theme.data.length}
          </div>
        </div>
        <LiaTimesSolid onClick={removeTheme} />
      </span>
    );
    document.getElementById('themeSearch').value = '';
    setFilters({ ...filters, theme: theme._id });
  };

  const removeTheme = () => {
    setDisplayThemes(null);
    setFilters({ ...filters, theme: null });
  };

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: checked,
    }));
  };

  const handleSubmitFilters = (e) => {
    e.preventDefault();
    dispatch(getDataFeed(user._id, JSON.stringify(filters)));
  };

  // Sorting logic based on sortOrder
  const sortedDataFeed = () => {
    if (!dataFeed) return [];
  
    // Filter out any invalid or null entries
    const validDataFeed = dataFeed.filter((item) => item && item.stamps);
  
    // Perform sorting based on the sortOrder
    if (sortOrder === 'stamped') {
      return [...validDataFeed].sort((a, b) => b.stamps.length - a.stamps.length);
    } else if (sortOrder === 'recentlyAuthored') {
      return [...validDataFeed].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }
    
    return validDataFeed;
  };

  return (
    <>
      <div className='General-filter-bar General-hidden' id='dataFeed_filter_bar'>
        <form action='' className='FlexColumn General-filter-form' onSubmit={handleSubmitFilters}>
          <span className='FlexRow General-filter-form'>
            <span className='General-filter-key'>Keyword:</span>
            <input
              type='text'
              placeholder='Filter by keyword...'
              name='keyword'
              className='General-input'
              onChange={handleChange}
            />
          </span>
          <div className='FlexColumn General-filter-form'>
            <span className='FlexRow General-filter-form'>
              <span className='General-filter-key'>Theme:</span>
              <input
                id='themeSearch'
                onChange={themeAutocomplete}
                name='themeSearch'
                type='text'
                placeholder='Filter by theme...'
              />
            </span>
            <div className='df-themes-container'>{displayThemes}</div>
            {user.userType === 2 ?
            <>
              <span className='FlexRow General-filter-form'>
              <span className='General-filter-key'>Author:</span>
                <input
                  type='text'
                  placeholder='Filter by author...'
                  name='authorName'
                  className='General-input'
                  onChange={handleChange}
                />
              </span>
              <span className="FlexRow General-filter-form">
              <label className="small-label">
                <input
                  type="checkbox"
                  name="includeStamped"
                  checked={filters.includeStamped}
                  onChange={handleCheckboxChange}
                />
                Include Stamped Data
              </label>
            </span>
            <span className="FlexRow General-filter-form">
              <label className="small-label">
                <input
                  type="checkbox"
                  name="includeViewed"
                  checked={filters.includeViewed}
                  onChange={handleCheckboxChange}
                />
                Include Viewed Data
              </label>
            </span>
            <span className="FlexRow General-filter-form">
              <label className="small-label">
                <input
                  type="checkbox"
                  name="includeUnsubscribedAuthors"
                  checked={filters.includeUnsubscribedAuthors}
                  onChange={handleCheckboxChange}
                />
                Include Unsubscribed Authors
              </label>
            </span>
            </>
          : "" }  
          </div>
          <button className='button signupButton' type='submit'>
            Apply Filters
          </button>
        </form>
      </div>

      <div className='DataFeed'>
        {sortedDataFeed().length > 0 ? (
          sortedDataFeed().map((datum) => (
            <DatumList data={datum} id={datum._id} key={datum._id} setMainContent={setMainContent} datum_state='pubdatum' />
          ))
        ) : (
          <div className='UserFeed'>
            {dataFeedLoading
              ? 'Loading...'
              : 'No data match your filter criteria. Try following more groups or removing some filters.'}
          </div>
        )}
      </div>

      {isOpen && modalData && (
        <DraftModal
          setMainContent={setMainContent}
          onClose={toggleModal}
          openFlag={isOpen}
          datum_i={modalData.data}
          ceFlag='2'
          btn_flag='public'
          datum_state='pubdatum'
        />
      )}
    </>
  );
};

export default DataFeed;
