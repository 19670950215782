import React, { useEffect, useState } from 'react'
import './DatumList.css'
import { FiBox } from 'react-icons/fi'
import { TbCompass } from 'react-icons/tb'
import { BiHash, BiPen } from 'react-icons/bi'
import { AiOutlineBarChart } from 'react-icons/ai'
import { GiStamper } from 'react-icons/gi'
import { MdOutlineAssignmentTurnedIn } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { BsEye } from 'react-icons/bs'
import { loadModal } from '../../actions/ModalAction'
import { HiArrowsRightLeft } from 'react-icons/hi2'
import { generateRelationIcon } from '../../tools/generateRelationIcon'
import Relation from '../../components/Main/CreateDatum/AddRelationModal/Relation/Relation'
import { getItemDetail } from '../../api/ItemRequest'

const DatumList = ({ data, id }) => {

    // data.stamps.length > 0 &&
    const { user } = useSelector((state) => state.authReducer.authData)
    const isOpen = useSelector((state) => state.datumeditReducer.isOpen)
    const [stamped, setStamped] = useState(data.stamps.includes(user._id))
    const [stamps, setStamps] = useState(data.stamps.length)
    const [viewed, setViewed] = useState(data.views.includes(user._id))
    const [views, setViews] = useState(data.views.length)

    const [imageName, setImageName] = useState([]);

    const theme_arr = useSelector((state) => state.themeReducer.theme);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            let res_img = [];
            try {
                for (const ref_id of data.references) {
                    const response = await getItemDetail(ref_id);
                    res_img.push(response.data);
                }
            } catch (error) {
                console.error("Error fetching item details:", error);
            }
            setImageName(res_img);
            setLoading(false); // Set loading to false after fetching
        };
        fetchData();
    }, [data.references]);

    useEffect(() => {
        if (isOpen == true) {

        }
        else {
            setIsModalOpen(false)
        }
    }, [isOpen])

    const userImgPath = process.env.REACT_APP_PUBLIC_FOLDER + data.authorImage;


    const datumContainerClass = () => {
        var classText = 'Datum';
        if (data.itemTriggered) { classText += ' triggeredDatum' }
        if (viewed) { classText += ' viewedDatum' }
        if (stamped) { classText += ' stampedDatum' }
        if (user.userType === 0) { classText += ' viewedDatum' }

        return classText;
    }

    const createdDate = () => {
        const date = new Date(data.createdAt);
        return (date.toDateString());
    }

    const followedGroupsBar = () => {
        return (
            <div className="bottom-bar FlexRow">
                <div className="dl-followed-groups">
                    {data.stampDetails && data.stampDetails.map((stamp, id) => {
                        return <img key={id} src={process.env.REACT_APP_PUBLIC_FOLDER + stamp.groupImage} />
                    })}
                </div>
            </div>
        )
    }
    const authorThemeBar = () => {
        return (
            <div className="bottom-bar FlexRow">
                <div className="datumDetails">
                    <div className="generalBar authorBar">

                        <span className="datumAuthor">
                            @{data.authorName}
                        </span>
                        <span className="timestamp">{createdDate()}</span>
                    </div>

                    {(data.themes.length > 0) && <div className="generalBar themesBar">
                        <BiHash />
                        <span className="themes">
                            {data.themes.map((theme, _id) => {
                                if (theme_arr && theme_arr[theme]) {
                                    return (
                                        <span className='theme'>
                                            <a href='#'>
                                                {theme_arr[theme]}&nbsp;&nbsp;
                                            </a>
                                        </span>
                                    )
                                } else if (data?.themes?.[_id]?.theme) {
                                    return (
                                        <span className='theme'>
                                            <a href='#'>
                                                {data.themes[_id].theme}&nbsp;&nbsp;
                                            </a>
                                        </span>
                                    )
                                }
                            })}
                        </span>
                    </div>}
                </div>
            </div>
        )
    }



    return (
        <>
            <div
                id={"datum_container_" + id}
                className={datumContainerClass()}
            >
                <div className="datumBody">
                    <div className="generalBar">
                        {/* <AiOutlineBarChart size="1rem"/> */}
                        {data.datumText !== '' && data.datumText !== null && <span className='datumText'>
                            {data.datumText}
                        </span>}

                        {(data.datumText === '' || data.datumText === null) && <span className='dl-relations FlexColumn'>
                            {data.relations.map((r) => {
                                return (
                                    <div className="FlexRow dl-relationRow">
                                        <span className="dl-endIcon FlexRow">
                                            {generateRelationIcon(r.relation)}
                                        </span>
                                        <div className='dl-vl'></div>
                                        <span className="dl-relation">
                                            <Relation
                                                relation={r}
                                                // references={data.references}
                                                references={loading ? [] : imageName} // Pass empty array while loading
                                            />
                                        </span>
                                    </div>
                                )
                            })}
                        </span>}
                    </div>
                    <div className="generalBar attributes" >
                        <FiBox />
                        <span className="referenced">{data.references.length}</span>
                        <HiArrowsRightLeft />
                        <span className="relations">{data.relations.length}</span>
                        <MdOutlineAssignmentTurnedIn />
                        <span className="sources">{data.sources.length}</span>
                        <GiStamper style={stamped ? { color: 'var(--primaryColor)' } : { color: 'gray' }} />
                        <span className="stamps">
                            {stamps}
                        </span>
                        <BsEye style={viewed ? { color: 'var(--primaryColor)' } : { color: 'gray' }} />
                        <span className="views">
                            {views}
                        </span>
                    </div>
                </div>
                <hr />
                {user.userType === 0
                    ? followedGroupsBar()
                    : authorThemeBar()
                }

            </div>
        </>
    )
}

export default DatumList