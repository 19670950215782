import './App.css'
import Home from './pages/home/Home'
import Profile from './pages/Profile/Profile'
import Auth from './pages/Auth/Auth'
import CreateNote from './pages/CreateNote/CreateNote'
import ViewNote from './pages/CreateNote/ViewNote'
import ViewAllNote from './pages/CreateNote/ViewAllNote'
import Tool from './pages/Tool/Tool'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { MantineProvider } from '@mantine/core'
import { Element } from 'react-scroll'
import Landing from './pages/Landing/Landing'
import Logs from './pages/Logs/Logs'

function App() {
  const user = useSelector((state) => state.authReducer.authData?.user);

  return (
    <div className="App">
      <Element id="anchor_top" name="anchor_top"></Element>

      <Routes>
        {/* Home and Landing Route */}
        <Route 
          path="/" 
          element={user ? <Navigate to="home" /> : <Navigate to="landing" />} 
        />
        <Route 
          path="/landing" 
          element={<Landing />} 
        />
        
        {/* Auth Routes for SignUp, Login, ForgotPassword */}
        <Route 
          path="/auth" 
          element={user ? <Navigate to="/home" /> : <Navigate to="/auth/login" />} 
        />
        <Route 
          path="/auth/login" 
          element={user ? <Navigate to="/home" /> : <Auth />} 
        />
        <Route 
          path="/auth/signup" 
          element={user ? <Navigate to="/home" /> : <Auth />} 
        />
        <Route 
          path="/auth/forgot-password" 
          element={user ? <Navigate to="/home" /> : <Auth />} 
        />

        {/* Home Route */}
        <Route 
          path="/home" 
          element={user ? <Home /> : <Navigate to="/auth" />} 
        />
        
        {/* Notes Routes */}
        <Route 
          path="/createnote" 
          element={user && user.isAdmin ? <CreateNote /> : <Auth />} 
        />
        <Route 
          path="/viewnote" 
          element={user ? <ViewAllNote /> : <Auth />} 
        />

        {/* Profile Route */}
        <Route 
          path="/profile/:id" 
          element={user ? <Profile /> : <Navigate to="/landing" />} 
        />

        {/* Logs Route (Admin only) */}
        <Route 
          path="/logs" 
          element={user && user.isAdmin ? <Logs /> : <Navigate to="/home" />} 
        />

        {/* Tool Route */}
        <Route 
          path="/tool" 
          element={user ? <Tool /> : <Navigate to="/auth" />} 
        />
      </Routes>

    </div>
  )
}

export default App
