import './AddThemeModal.css'
import React, { useState } from 'react'
import { LiaTimesSolid } from 'react-icons/lia'
import { fetchThemesAutocomplete } from '../../../../api/ThemeManipulation'
import { BsUpload } from 'react-icons/bs'
import { AiOutlineBarChart } from 'react-icons/ai'
import { onAddFormTheme } from "../../../../actions/formAction";
import { useDispatch } from "react-redux";


const AddThemeModal = ( { setOpenATM, dataATM, setDataATM }) => {

  const dispatch = useDispatch();

  const [displayThemes, setDisplayThemes] = useState(null);

  const clearATM = () => {
    document.getElementById("themeSearch").value = null;
    setDisplayThemes(null);
  }

  const autocomplete = async(e) => {
    const inputEl = document.getElementById("themeSearch");
    // const value = inputEl.value;
    // console.log(e.target.value)
    const value = e.target.value;
    console.log(value);
    if (value.length > 2) {

      const res = await fetchThemesAutocomplete(value);
      const data = res.data;
      console.log(data);
          
      // ------- demo error here!
      data && setDisplayThemes(
        data.map((theme, _id) => {
          console.log("Theme Inspected: " + theme.theme);
          console.log("Current dataATM:");
          console.log(dataATM);
          for (var i = 0; i < dataATM.length; i++) {
            if (dataATM[i]._id == theme._id) {
              return null;
            } 
          }
          return (
            <div className='atm-result' onClick={()=>selectTheme(theme)}>
                <div className="atm-result-theme">
                    {theme.theme}
                </div>
                <div className="atm-result-stats">
                    <AiOutlineBarChart/>
                    {theme.data.length}
                </div>
            </div>
          )
        })
      )
    } else {
      setDisplayThemes(null);
    }
  }

  const selectTheme = (theme) => {
    dispatch(onAddFormTheme());
    setDataATM(() => {
        console.log(dataATM);
        return [...dataATM, theme]
    })
    clearATM();
  }   

  const addTheme = (e) => {
    console.log("Add theme")
    e.preventDefault();
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const newTheme = {};
    newTheme.theme = document.getElementById("themeSearch").value;
    var alertEl = document.getElementById("cd_atm2_alert");
    console.log(alertEl)
    if (newTheme.theme.length > 30) {
      console.log("Inside if")
      alertEl.classList.remove("General-hidden");
      alertEl.innerText = "Your Theme must not be longer than 30 characters."
    } else if (newTheme.theme.length < 3) {
      alertEl.classList.remove("General-hidden");
      alertEl.innerText = "Your Theme must be at least 3 characters."
    } else if (format.test(newTheme.theme)) {
      console.log("Inside else if")
      alertEl.classList.remove("General-hidden");
      alertEl.innerText = "Your Theme cannot contain any spaces or special characters."
    } else {
      dispatch(onAddFormTheme());
      selectTheme(newTheme);
      // Need to validate for already existing themes (I would prefer on the frontent, but backend could work)
      document.getElementById("cd_atm2_alert").classList.add("General-hidden");
    }
  }


  return (
    
        <div className="AddThemeModal FlexColumn">
            
            <div className="atm-header FlexRow">
                <span className="atm-header-text">Add Theme</span>
                <span className='atm-x' onClick={() => setOpenATM(false)}>
                  <LiaTimesSolid/>
                </span>
            </div>
            
            <div className='FlexRow Search atm-search-bar'>
                <input id="themeSearch" onChange={autocomplete} name="themeSearch" type="text" placeholder="Find or create a theme..." />
                <button className="button atm-submit-icon" onClick={addTheme}>
                    <BsUpload />
                </button> 
            </div>

            <div id="cd_atm2_alert" className='General-hidden General-validationError'></div>
        
            <div id="atm_content" className="atm-content">{displayThemes}</div>
        </div>

  )
}

export default AddThemeModal