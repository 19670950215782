import React, {useState} from 'react'
import './Header.css'
import Logo from '../../img/appiconcrop3.png'
import { BiMenuAltRight, BiUser } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { logOut } from '../../actions/AuthAction'
import { generateUserTypeIcon } from '../../tools/userTypeConversion'
import { RiProfileLine } from 'react-icons/ri'
import { AiOutlineBarChart } from 'react-icons/ai'
import { BsSearch } from 'react-icons/bs'
import { FiMoreHorizontal } from 'react-icons/fi'
import { IoHomeOutline } from 'react-icons/io5'
import { PiNotepad } from 'react-icons/pi'
import Notification from '../Header/Notification/Notification'
import NotificationBell from './Notification/NotificationBoard.'
import { AiOutlineNotification } from "react-icons/ai";
const Header = ( {location} ) => {

  const [menuOpened, setMenuOpened] = useState(false);
  const dispatch = useDispatch();

  const handleMenuOpened = () => {
    setMenuOpened(!menuOpened);
  }

  const user = useSelector((state)=>state.authReducer.authData?.user);

  const handleLogOut = () => {
    dispatch(logOut());
  }

  const getMenuStyles = (menuOpened) => {
    if (location === "landing") {
      if (document.documentElement.clientWidth <= 1000) {
        return {
          right: !menuOpened && "-100%"}
      }
    } else {
      if (document.documentElement.clientWidth <= 700) {
        return {
          right: !menuOpened && "-100%"}
      }
    }
  }

  const scrollToAnchor = (location) => {
      const anchor = document.querySelector('#' + location);
      anchor.scrollIntoView({behavior: 'smooth', block: 'start'}) 
  }
  
  return (
    <div className={location === "landing" ? "LandingHeader" : "Header"}>
      <div className="h-wrapper">
      <Link style={{textDecoration: "none", color: "inherit"}} to={'/landing'}><img src={Logo} alt="" className='headerLogo' /></Link>
        {location === "home" &&
          <div className='h-nav-icons'>
            <BsSearch size={30} onClick={()=>scrollToAnchor("anchor_top")} />
            <RiProfileLine size={30} onClick={()=>scrollToAnchor("anchor_profile")} />
            <BiUser size={30} onClick={()=>scrollToAnchor("anchor_users")} />
            <AiOutlineBarChart size={30} onClick={()=>scrollToAnchor("anchor_browse")} />
            <FiMoreHorizontal size={30}/>
          </div>
        }
        {location === "landing" &&
          <div className='h-landing-nav'>
            <div className='clickable' onClick={()=>scrollToAnchor("anchor_about")}>What is NS?</div>
            <div className='clickable' onClick={()=>scrollToAnchor("anchor_stats")}>State of the Project</div>
            <div className='clickable' onClick={()=>scrollToAnchor("anchor_involved")}>Get Involved</div>
            <div className='clickable' onClick={()=>scrollToAnchor("anchor_resources")}>Resources</div>
            <div className='clickable' onClick={()=>scrollToAnchor("anchor_connect")}>Connect</div>
          </div>
        }
        <div className={location === "landing" ? "h-landingMenu" : "h-menu"} style={getMenuStyles(menuOpened)}>
          {user 
            ? <>
                <Link style={{textDecoration: "none", color: "inherit"}} to={'/home'}><span className='FlexRow h-page-span'><IoHomeOutline /> Home</span></Link>
                
                <Link style={{textDecoration: "none", color: "inherit"}} to={`/profile/${user._id}`}><span className='FlexRow h-page-span'><RiProfileLine/> Profile</span></Link>
                
                {user.isAdmin && <Link style={{textDecoration: "none", color: "inherit"}} to={`/logs`}><span className='FlexRow h-page-span h-adminButton'><PiNotepad /> Logs</span></Link>}
                
                <Notification />
                {/* <NotificationBell/> */}

                <span className='FlexRow h-name'>
                  {generateUserTypeIcon(user.userType)}
                  {user.username}
                </span>
                
                <button className='button logout-button' onClick={handleLogOut}>
                  Log Out
                </button>  
              </>
            : <Link style={{textDecoration: "none", color: "inherit"}} to={'/auth'}>
                <button className='button logout-button'>
                  Sign Up/In
                </button> 
              </Link>
          }
        </div>
        <div className={location === "landing" ? "menu-icon-landing" : "menu-icon-default"} onClick={handleMenuOpened}>
          <BiMenuAltRight size={30}/>
        </div>
      </div>
    </div>
  )
}

export default Header