import { useDispatch, useSelector } from 'react-redux';
import './SuggestedItem.css';

import React from 'react'
import { fetchModalItem } from '../../../../actions/ModalAction';
import { generateItemTypeIcon } from '../../../../tools/itemTypeConversion';
import { TbCompass } from 'react-icons/tb';

const SuggestedItem = ( {item, parentItemObj } ) => {
    const {user} = useSelector((state)=>state.authReducer.authData);
    const dispatch = useDispatch();
    
    const loadItem = () => {
        var logObject = {
            location: "suggestion",
            ...parentItemObj
        };
        dispatch(fetchModalItem(item.itemId, user._id, logObject));
    }
    
  return (
    <div className="RelationalItem FlexColumn">
        
            <div 
                id={"relational_item_" + item.itemId} 
                className={ item.triggers > 0 
                                ? "ri-item-container FlexRow ri-triggered" 
                                : "ri-item-container FlexRow ri-normal"
                } 
                onClick={loadItem}
            >
                <div className="arm-link-item-imageContainer">
                    <img src={process.env.REACT_APP_PUBLIC_FOLDER + item.image} alt={item.itemName}/>
                </div>
                <div className='arm-link-item-infoContainer FlexColumn'>
                    <div className="arm-link-item-nameBar FlexRow">
                        <div className="arm-link-item-itemName">{item.itemName}</div>
                        <div className='arm-link-item-itemIcon'>{generateItemTypeIcon(item.itemType)}</div>
                    </div>
                    <div className='ri-stats-bar'>
                        <span className='ri-stat FlexRow'>
                            <span>
                                #
                            </span>
                            {item.relatedData.length}
                        </span>
                        <span className='ri-stat FlexRow'>
                            <span>
                                #
                            </span>
                            ?
                        </span>
                        <span className="ri-stat FlexRow">
                            {item.triggers > 0 && <><TbCompass />{item.triggers}</>}
                        </span>
                    </div>

                </div>
            </div>
    </div>
  )
}

export default SuggestedItem