const datumReducer = (
    state = { datumData: null, loading: false, error: false, status: null },
    action
) => {
    switch (action.type) {
        case "DATUM_UPLOAD_START":
            return { ...state, loading: true, error: false };
        case "DATUM_UPLOAD_SUCCESS":
            return { ...state, loading: false, error: false, status: action.payload.status };
        case "DATUM_CLEAR_STATUS":
            return { ...state, loading: false, error: false, status: null };
        case "DATUM_UPLOAD_FAIL":
            return { ...state, loading: false, error: true, status: action.status };
        case "DATUM_UPDATE_START":
            return { ...state, loading: true, error: false };
        case "DATUM_UPDATE_SUCCESS":
            return { ...state, loading: false, error: false, status: action.payload.status };
        case "DATUM_DELETE_START":
            return { ...state, loading: true, error: false };
        case "DATUM_DELETE_SUCCESS":
            return { ...state, loading: false, error: false, status: action.payload.status };
        case "LOG_OUT":
            return { datumData: null, loading: false, error: false, status: null }
        default:
            return state
    }
};

export default datumReducer;